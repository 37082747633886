import { debounce, getDevice, getDomObserver, getSelector, htmlToElement, insertNode } from './utils';
import { $getSiteId } from './data';
import { $getAndRenderRecommendations } from './recommendation';
import constants from './constants';

const CSS_ID = 'obv-shopify-editor-css';
const SHOPIFY_EDITOR_CSS = /*html*/`<style id="${CSS_ID}">
.obv-badge-section-wrapper {
  position: relative;
}
.obv-badge-section {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: 2px;
  z-index: 100001;
  align-items: center;

  color: #222;
  font-size: 1.3rem;
}
.obv-status-badge {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  border: 5px solid transparent;
}
.obv-status-badge.obv-status-live {
  background: #007f5f;
  border-color: #aee9d1;
}
.obv-status-badge.obv-status-staging {
  background: #bbb;
  border-color: #d8d8d8;
}
.obv-edit-badge {

}
.obv-edit-badge a {
  text-decoration: none;
  border-radius: 8px;
  padding: 3px 6px;

  background: transparent;
  border: 1px solid transparent;
  opacity: 0.7;
  transition: opacity 0.3s ease, border 0.3s ease, background 0.3s ease;
}
.obv-edit-badge a:hover {
  opacity: 1;
  background: #ddd;
  border: 1px solid #ddd;
}
</style>`;



function injectCss() {
  let existingCss = document.querySelector(`#${CSS_ID}`);
  if (existingCss) return;

  let css = htmlToElement(SHOPIFY_EDITOR_CSS);
  document.head.appendChild(css);
}

async function $getEditLink(rec) {
  const siteId = await $getSiteId();
  let url = `${constants.PROD_API_URL}/applet/hic-plus/online/recommend-edit?site-xid=${siteId}&external=${rec.external}&pl=${rec.place}`;
  return url;
}

function getStatusLabel(rec) {
  let status = rec.status;
  if (status === 'production') {
    return 'Live';
  } else {
    return 'Paused';
  }
}

function getStatusClass(rec) {
  let status = rec.status;
  if (status === 'production') {
    return 'obv-status-live';
  } else {
    return 'obv-status-staging';
  }
}

// Any time a widget is removed, be sure to call this
// or else the mutationobserver will redraw it
function removeCallback(external) {
  if (external || external === null) {
    const domObserver = getDomObserver();
    const removeCallbackFn = domObserver && domObserver.removeCallback;
    if (removeCallbackFn) {
      removeCallbackFn(external);
    }
  }
}

function queryRemoveAll(selector) {
  const nodes = document.querySelectorAll(selector);
  if (nodes && nodes.length) {
    for (let node of nodes) {
      if (node.parentNode) node.parentNode.removeChild(node);
    }
  }
}

function clearAll() {
  removeCallback(null);
  // abortRecs();
  queryRemoveAll('[id^="hc-psz-"]');
  queryRemoveAll('[id^="hc-psz-custom-css-"]');
  queryRemoveAll('[id^="hc-psz-generated-css-"]');
  queryRemoveAll('.hc-psz-popup-wrap');
  queryRemoveAll('.hc-psz-popup-cover');
}

let tracking = false;
let device;

const debouncedResize =
  debounce(async (ev) => {
    let newDevice = getDevice();
    if (newDevice !== device) {
      device = newDevice;
      // console.log('resize occurred, rerendering');
      clearAll();
      await $getAndRenderRecommendations(true);
    }
  }, 200);

function trackResize() {
  if (!tracking) {
    tracking = true;
    window.addEventListener('resize', debouncedResize);
  }
}

export async function $addShopifyEditorEnhancements(rec) {
  let recNode = document.querySelector(getSelector('hc-psz', { rec }));
  if (recNode) {
    device = getDevice();
    injectCss();
    trackResize();
    const href = await $getEditLink(rec);

    let editBadge = htmlToElement(/*html*/`
      <div class="obv-badge-section-wrapper">
        <div class="obv-badge-section">
          <div class="obv-edit-badge" title="Edit in Obviyo App">
            <a href="${href}" target="_blank" rel="noreferrer"> &#9998; </a>
          </div>
          <div class="obv-status-badge ${getStatusClass(rec)}" title=${getStatusLabel(rec)}>
            <span></span>
          </div>
        </div>
      </div>
    `);

    insertNode(editBadge, recNode, null, 'prepend');
  }
}