import Logger from './logger';
import {
  $wait, $waitFor, $waitForWindowObject, applyCss, getDevice, getHandle, getIdFromGid, getPath,
  getProductIdFromGid, getRecommendationNode, getRecommendationNodeId, htmlToElement, insertNode, isMakingImpression,
  loadCss, loadScript
} from './utils';
import {
  $getCurrentPage, $getObviyoApi, getCartItems, $getCartItems, getCurrentPage, getCurrentCategoryId, getCurrentCategoryName,
  getCurrentProductId, getCurrentProductHandle, getCurrentProductCategoryIds, $getSiteId
} from './data';
import { getAppProxyUrl } from './utils-app-proxy';
import { getPreviewInfo } from './preview';
import { $getDynamicRecData, renderRecommendation } from './recommendation';
import { $getMostRecentCartItems } from './history';
import { $listItemResolver } from './widgets/list';

const logger = new Logger();

// superfluous to keep printing out the fact that we are using a custom function to override the same built-in
// function so we keep track of the messages and only print out 1 of the same function related to each recommendation
let loggingHistoryMap = {};

export function getJsObject(text) {
  const obj = Function('"use strict";' + text)();
  return obj;
}

let globalJsObj;
async function $legacyGetGlobalJsObject() {
  if (globalJsObj) {
    return globalJsObj;
  }
  const data = await $getDynamicRecData();
  if (data && data.global && data.global.js) {
    let logMessage = 'found global js, retrieving global object';
    if (!loggingHistoryMap[logMessage]) {
      logger.debug(logMessage);
      loggingHistoryMap[logMessage] = 1;
    }
    globalJsObj = getJsObject(data.global.js);
    // try {
    //   globalJsObj = getJsObject(data.global.js);
    // } catch (err) {
    //   logger.error('problem retrieving global js object', err);
    //   $sendError('gse', err);
    // }
  }

  if (globalJsObj && typeof globalJsObj.resolver === 'function') {
    let logMessage = 'global custom resolver function found';
    if (!loggingHistoryMap[logMessage]) {
      logger.debug(logMessage);
      loggingHistoryMap[logMessage] = 1;
    }
  }

  return globalJsObj;
}

export async function $getGlobalJsObject() {
  const obvApi = await $getObviyoApi();

  if (obvApi) {
    return await obvApi.$getGlobalJsObject();
  } else {
    return await $legacyGetGlobalJsObject();
  }
}

// keep a cache of js objects so we don't have to keep re-evaling incoming code
let jsObjectMap = {};
function getLocalJsObject(rec) {
  if (rec.external in jsObjectMap) {
    return jsObjectMap[rec.external];
  }

  const jsObj = getJsObject(rec.js);
  jsObjectMap[rec.external] = jsObj;
  return jsObj;
}

export function clearLocalJsObjectMap() {
  jsObjectMap = {};
}

/**
 * Predefined Location Definitions (LocationDef)
 * Only selector is real-time/dynamic, the rest of the fields are for the editor/on creation
 * Provide place to limit to a certain page in the editor
 */

// section based locations: before first section, after nth-section
const NUM_SECTIONS = 10;
let sectionLocations = [];
for (let i = 1; i <= NUM_SECTIONS; i++) {
  sectionLocations.push({
    name: `obv-after-section-${i}`,
    label: `After Section ${i}`,
    desc: `After Section ${i}`,
    selector: `#MainContent >:nth-child(${i}), #MainContent > :last-child`,
    wrapperDivClasses: 'page-width',
  });
}

// insert defaults to after
let defaultLocations = [
  {
    name: 'obv-home-top',
    label: 'Below First Section',
    desc: 'Below the Hero or below the first child of the main content area',
    place: 'H',
    wrapperDivClasses: 'page-width',
    // selector for 2.0 shopify standard based themes
    selector: '#MainContent >:first-child'
  },
  // TODO: add a featured product location
  {
    name: 'obv-home-bottom',
    label: 'Below Last Section',
    desc: 'Below the last child of the main content area',
    place: 'H',
    wrapperDivClasses: 'page-width',
    selector: '#MainContent >:last-child'
  },
  {
    name: 'obv-cat-top',
    label: 'Below First Section',
    desc: 'Below the Collection Header or below the first child of the main content area',
    place: 'CAT',
    wrapperDivClasses: 'page-width',
    // selector for 2.0 shopify standard based themes
    selector: '#MainContent >:first-child'
  },
  {
    name: 'obv-cat-bottom',
    label: 'Below Last Section',
    desc: 'Below the Collection list or below the last child of the main content area',
    place: 'CAT',
    wrapperDivClasses: 'page-width',
    selector: '#MainContent >:last-child'
  },
  {
    name: 'obv-prod-top',
    label: 'Top',
    desc: 'Below the Product area or after the first child of the main content area',
    place: 'PDP',
    wrapperDivClasses: 'page-width',
    selector: '#MainContent >:first-child'
  },
  {
    name: 'obv-prod-bottom',
    label: 'Bottom',
    desc: 'Below the last child of the main content area',
    place: 'PDP',
    wrapperDivClasses: 'page-width',
    selector: '#MainContent >:last-child'
  },
  {
    name: 'obv-cart-bottom',
    label: 'Bottom',
    desc: 'Below the last child of the main content area',
    place: 'CRT',
    wrapperDivClasses: 'page-width',
    selector: '#MainContent >:last-child'
  },
  {
    name: 'obv-checkout-summary',
    label: 'Below Summary',
    desc: 'Below the Summary section',
    place: 'CO',
    selector: '.order-summary__section--total-lines'
  },
  {
    name: 'obv-checkout-step-footer',
    label: 'Below Checkout Step Footer',
    desc: 'Below the Checkout Step footer section',
    selector: '.step__footer'
  },
  {
    name: 'obv-confirmation-summary',
    label: 'Below Summary',
    desc: 'Below the summary section',
    place: 'CNF',
    selector: '.order-summary__section--total-lines'
  },
  {
    name: 'obv-confirmation-step-footer',
    label: 'Below Confirmation Step Footer',
    desc: 'Below the Confirmation Step footer section',
    selector: '.step__footer'
  },
  {
    name: 'obv-footer',
    label: 'Footer',
    desc: 'Above the footer',
    place: 'SITEWIDE',
    selector: '#shopify-section-footer, #shopify-section-static-footer, .footer__wrapper, footer.footer, #footer',
    insert: 'before',
    wrapperDivClasses: 'page-width',
  },
  {
    name: 'obv-before-section-1',
    label: 'Before Section 1',
    desc: 'Before Section 1',
    selector: '#MainContent >:first-child',
    insert: 'before',
    wrapperDivClasses: 'page-width',
  },
  ...sectionLocations
];

let finalLocations;

export async function $getLocations(ctx) {

  const SANDBOX_MODE = ctx && ctx._SANDBOX_MODE;

  if (!SANDBOX_MODE && finalLocations) {
    return finalLocations;
  }

  let globalJsObj = ctx && ctx.globalJsObject;
  if (!SANDBOX_MODE && !globalJsObj) {
    globalJsObj = await $getGlobalJsObject();
  }

  let globalLocations = globalJsObj && globalJsObj.locations;
  let locations = [];

  for (let defaultLocation of defaultLocations) {
    let overridden = false;
    if (globalLocations) {
      let globalLocation = globalLocations.find((loc) => defaultLocation.name === loc.name);
      if (globalLocation) {
        overridden = true;
        logger.debug('overriding default location with custom user location', globalLocation);
        locations.push(Object.assign({}, defaultLocation, globalLocation));
      }
    }

    if (!overridden) {
      locations.push(Object.assign({}, defaultLocation));
    }
  }

  if (globalLocations) {
    for (let globalLocation of globalLocations) {
      let exists = locations.find((location) => location.name === globalLocation.name);
      if (!exists) {
        locations.push(Object.assign({}, globalLocation));
      }
    }
  }

  finalLocations = locations;

  return locations;
}

export async function $getLocation(name) {
  const locations = await $getLocations();

  if (!locations || !(locations instanceof Array)) return;

  return locations.find(location => location.name === name);
}

// common services provided to user-defined functions
export async function $getBaseCtx() {
  const globalJsObj = await $getGlobalJsObject();

  let baseCtx = {
    applyCss,
    getDevice,
    getCurrentPage,
    $getCurrentPage,
    getAppProxyUrl,
    getHandle,
    getPath,
    getPreviewInfo,
    getCurrentCategoryId,
    getCurrentCategoryName,
    getCurrentProductId,
    getCurrentProductHandle,
    getCurrentProductCategoryIds,
    getIdFromGid,
    getProductIdFromGid,
    getCartItems,
    $getCartItems,
    $getMostRecentCartItems,
    $listItemResolver,
    getRecommendationNode,
    getRecommendationNodeId,
    htmlToElement,
    insertNode,
    isMakingImpression,
    loadCss,
    loadScript,
    renderRecommendation,
    $getSiteId,
    $wait,
    $waitFor,
    $waitForWindowObject,
    global: globalJsObj
  };

  return baseCtx;
}

export async function $getCtxWith(obj) {
  let baseCtx = await $getBaseCtx();
  return Object.assign({}, baseCtx, obj || {});
}

// runs the appropriate function between 3: default/actual function, local recommendation function, and global function
// the fallback currently works this way
// 1. local custom function
// 2. global custom function
// 3. default function
export async function $applyCustomFn({
  $fn, fnParams, refName, rec, additionalCtx
}) {

  const globalJsObj = await $getGlobalJsObject();
  let jsObj;

  additionalCtx = additionalCtx || {};
  if (rec) {
    jsObj = getLocalJsObject(rec);
    additionalCtx.rec = rec;
  }

  fnParams = fnParams || [];
  additionalCtx.$default = async () => { return $fn.apply(null, fnParams) };
  let ctx = await $getCtxWith(additionalCtx);
  let ret;
  if (jsObj && typeof jsObj[refName] === 'function') {
    let localLogMessage = `custom local ${refName} function found, executing`;
    if (!loggingHistoryMap[rec.external + localLogMessage]) {
      logger.debug(localLogMessage);
      loggingHistoryMap[rec.external + localLogMessage] = 1;
    }
    ret = await jsObj[refName](ctx);
  } else if (globalJsObj && typeof globalJsObj[refName] === 'function') {
    let globalLogMessage = `custom global ${refName} function found, executing`;
    if (!loggingHistoryMap[globalLogMessage]) {
      logger.debug(globalLogMessage);
      loggingHistoryMap[globalLogMessage] = 1;
    }
    ret = await globalJsObj[refName](ctx);
  } else {
    ret = await additionalCtx.$default();
  }
  return ret;
}