import constants from './constants';
import Logger from './logger';
import { addOnRatedListener } from './widgets/ratings';
import { $applyCustomFn } from './customJs';
import {
  $getObviyoApi, $getTagServices, $getVisitorIds, $getSiteId, $getHicSessionId, $getSegmentData,
  $getCartItems, getStoredCartItems, deleteStoredCartItems, getCurrentCategoryId, $getCurrentPage,
  getCurrentProductId, getTransactionLineItems, isObviyoHyperEnabled, setStoredCartItems
} from './data';
import {
  convertObjectToUrlParams, getDomObserver, getIdFromGid, getRecommendationNodeId,
  isEmptyObject, isMakingImpression, isVisibleInWindow
} from './utils';
import { $addInteractionEvent } from './history';
import { getPreviewInfo } from './preview';

const logger = new Logger();
const preview = getPreviewInfo();
let domObserver = getDomObserver();

async function $triggerPreSendPersonalizeData(params) {

  return $applyCustomFn({
    $fn: async () => { return params },
    refName: 'preSendPersonalizeData',
    additionalCtx: {
      params
    }
  });
}

async function $sendPersonalizeData(rec, type, itemId, itemPosition, itemData) {
  const obvApi = await $getObviyoApi();
  if (obvApi) {
    let data = {
      event: 'recommendation',
      id: rec.external,
      qualifiedName: rec.qualifiedName,
      action: type
    };
    if (itemId) {
      data.itemId = itemId;
    }
    if (itemPosition !== undefined) {
      data.itemPosition = itemPosition;
    }

    if (itemData) {
      data.itemData = itemData;
    }

    data = await $triggerPreSendPersonalizeData(data);
    obvApi.sendEvent(data);

    // normally we will exit on first grab to avoid duplication of data collection
    // but if we are in a test scenario we'll go ahead and send both
    if (!window[constants.OBV_ANALYTICS_TEST_FLAG]) {
      return;
    }
  }
  
  const tagServices = await $getTagServices();
  if (tagServices) {
    let data = {
      qn: rec.qualifiedName,
      ex: rec.external,
      a: type
    };
    if (itemId) {
      data.id = itemId;
    }
    if (itemPosition !== undefined) {
      data.p = itemPosition;
    }

    data = await $triggerPreSendPersonalizeData(data);
    tagServices.sendPersonalizeData(data);
  }
}

function setupImpressionForWidget(rec, itemData) {
  let observerId = 'widget-imp-' + rec.external;
  let sent = false;
  function checkAndSendEvent() {
    // send to isMakingImpression querySelector result since we want only first hc-psz-items (in the case of some widgets there may be more than one
    // and doing this with only a css selector would be otherwise tricky and not recommended)
    if (!sent && isMakingImpression(document.querySelector(`#${getRecommendationNodeId(rec)} .hc-psz-items`))) {
      window.removeEventListener('scroll', checkAndSendEvent, true);
      window.removeEventListener('resize', checkAndSendEvent);
      domObserver.removeCallback(observerId);
      rec.logger.debug(`sending widget impression event for: ${rec.qualifiedName}`);
      $sendPersonalizeData(rec, 'wv', null, null, itemData);
      sent = true;
    }
  }
  domObserver.setCallback(observerId, checkAndSendEvent);
  window.addEventListener('scroll', checkAndSendEvent, true);
  window.addEventListener('resize', checkAndSendEvent);
  // call once initially just in case we are already over the target
  checkAndSendEvent();
}

function setupImpressionForItem(rec, item, index, itemData) {
  const pid = getIdFromGid(item.id);
  let observerId = 'prod-imp-' + rec.external + pid;
  let sent = false;

  function checkAndSendEvent() {
    if (!sent && isVisibleInWindow(document.querySelector(`#${getRecommendationNodeId(rec)} [data-pid="${pid}"]`))) {
      window.removeEventListener('scroll', checkAndSendEvent, true);
      window.removeEventListener('resize', checkAndSendEvent);
      removeWidgetUpdatedEventListener(checkAndSendEvent);
      domObserver.removeCallback(observerId);
      rec.logger.debug(`sending item impression event for rec: ${rec.qualifiedName} product: ${pid} position: ${index}`);
      $sendPersonalizeData(rec, 'view', pid, index, itemData);
      sent = true;
    }
  }

  domObserver.setCallback(observerId, checkAndSendEvent);
  window.addEventListener('scroll', checkAndSendEvent, true);
  window.addEventListener('resize', checkAndSendEvent);
  addWidgetUpdatedEventListener(checkAndSendEvent);
  // call once initially just in case we are already over the target
  checkAndSendEvent();
}

function setupImpressionsForIndividualItems(rec, itemData) {
  // assumes that item slot has a data-pid corresponding to individual item id
  for (const [i, item] of itemData.items.entries()) {
    setupImpressionForItem(rec, item, i, itemData);
  }
}

export function setupImpressionsForRecommendation(rec, itemData) {
  setupImpressionForWidget(rec, itemData);
  setupImpressionsForIndividualItems(rec, itemData);
}

let widgetUpdatedEventListeners = [];

export function addWidgetUpdatedEventListener(listener) {
  widgetUpdatedEventListeners.push(listener);
}

export function removeWidgetUpdatedEventListener(listener) {
  const index = widgetUpdatedEventListeners.findIndex((savedListener) => savedListener === listener);
  if (index > -1) {
    widgetUpdatedEventListeners.splice(index, 1);
  }
}

export function triggerWidgetUpdatedEvent() {
  for (let i = widgetUpdatedEventListeners.length - 1; i >= 0; i--) {
    widgetUpdatedEventListeners[i]();
  }
}

export async function $sendDebugInfo(data) {
  logger.debug('sending debug info', data);

  const obvApi = await $getObviyoApi();
  if (obvApi) {
    obvApi.sendEvent({
      event: 'debug',
      data: data
    });

    // normally we will exit on first grab to avoid duplication of data collection
    // but if we are in a test scenario we'll go ahead and send both
    if (!window[constants.OBV_ANALYTICS_TEST_FLAG]) {
      return;
    }
  }
  const tagServices = await $getTagServices();
  if (tagServices) {
    tagServices.sendDebugInfo(data);
  }
}

export async function $sendError(name, err, external) {
  let payload = {
    n: name,
    m: (err && err.message) || '-'
  };
  if (external) {
    payload.e = external;
  }

  $sendDebugInfo(payload);
}

export function getHcid(rec, item, index, type) {
  return `psz-hcp:${rec.qualifiedName}:${getIdFromGid(item.id)}:${index}:${type}:${rec.external}`;
}



export async function $getCommonParams() {
  let params = {};
  params.ts = Date.now();

  params.siteId = await $getSiteId();
  const visitorIds = await $getVisitorIds();
  if (visitorIds.hic) {
    params.vid = visitorIds.hic;
  }
  if (visitorIds.platform) {
    params.eid = visitorIds.platform;
  }
  const hicSessionId = await $getHicSessionId();
  if (hicSessionId) {
    params.sid = hicSessionId;
  }

  const segs = await $getSegmentData();

  if (!isEmptyObject(segs)) {
    params.segs = JSON.stringify(segs);
  }

  // determine if we are in a debug view so that we don't count
  // in general reporting
  if (logger.getDebugMode() || preview.isPreviewMode) {
    params.qa = true;
  }
  return params;
}

export async function $sendClickEvent(pid, rid) {
  let params = await $getCommonParams();
  params.type = constants.CLICKED_EVENT;
  if (rid) {
    params.rid = rid;
  }
  params.item = pid;

  let paramString = convertObjectToUrlParams(params);
  logger.debug('sending clicked event', paramString);
  navigator.sendBeacon(`${constants.EVENT_URL}?${paramString}`);
}



export async function $handleProductAddedToCartEvents() {
  let existingStoredItems = getStoredCartItems();
  if (!existingStoredItems) {
    existingStoredItems = {};
  }
  const cartItems = await $getCartItems();
  let newlyAddedItems = [];
  let newStoredItems = {};
  if (cartItems && cartItems.length) {
    for (const item of cartItems) {
      if (!existingStoredItems[item.id] || existingStoredItems[item.id] < item.qt) {
        newlyAddedItems.push(item.id);
      }
      newStoredItems[item.id] = item.qt;
    }
  }

  for (const item of newlyAddedItems) {
    let params = await $getCommonParams();
    params.type = constants.ADDED_TO_CART_EVENT;
    params.item = item;
    $addInteractionEvent(params);
    let paramString = convertObjectToUrlParams(params);
    logger.debug('sending cart added event', paramString);
    navigator.sendBeacon(`${constants.EVENT_URL}?${paramString}`);
  }

  if (Object.keys(newStoredItems).length) {
    setStoredCartItems(newStoredItems);
  } else {
    deleteStoredCartItems();
  }
}

async function $handleCategoryViewedEvent() {
  const currentCatId = getCurrentCategoryId();
  if (currentCatId) {
    let params = await $getCommonParams();
    params.type = constants.CATEGORY_VIEWED_EVENT;
    params.item = currentCatId;
    $addInteractionEvent(params);
  }
}

async function $handleProductViewedEvents(currentPage) {
  if (currentPage === 'pdp') {
    const currentProductId = getCurrentProductId();
    // in this case we are on product page
    if (currentProductId) {
      let params = await $getCommonParams();
      params.type = constants.VIEWED_EVENT;
      params.item = currentProductId;
      $addInteractionEvent(params);
      let paramString = convertObjectToUrlParams(params);
      logger.debug('sending viewed event', paramString);
      navigator.sendBeacon(`${constants.EVENT_URL}?${paramString}`);
    }
  }
}

function handleProductRatedEvents(currentPage) {
  if (currentPage === 'pdp') {
    // for now don't worry about rec, just do our best for now
    addOnRatedListener(null, async () => {
      const currentProductId = getCurrentProductId();
      if (currentProductId) {
        let params = await $getCommonParams();
        params.type = constants.RATED_EVENT;
        params.item = currentProductId;

        $addInteractionEvent(params);
        let paramString = convertObjectToUrlParams(params);
        logger.debug('sending rated event', paramString);
        navigator.sendBeacon(`${constants.EVENT_URL}?${paramString}`);
      }
    });
  }
}

async function $handleProductPurchasedEvents(currentPage) {
  if (currentPage === 'confirm') {
    const li = getTransactionLineItems();
    if (!li) {
      return;
    }
    for (const item of li) {
      if (item.product_id || item.id) {
        let params = await $getCommonParams();
        params.type = constants.PURCHASED_EVENT;
        params.item = item.product_id || item.id;
        $addInteractionEvent(params);
        let paramString = convertObjectToUrlParams(params);
        logger.debug('sending purchased event', paramString);
        navigator.sendBeacon(`${constants.EVENT_URL}?${paramString}`);
      }
    }
  }
}


export async function $reportEvents() {
  try {
    // hyper will automatically handle all put event reporting so we shouldn't worry about that here
    if (isObviyoHyperEnabled()) return;

    const currentPage = await $getCurrentPage();

    handleProductRatedEvents(currentPage);
    // TODO: at some point we may want to wrap the individual functions here in try catches so that one of them
    // doesn't cause all to fail
    await Promise.all([
      $handleProductViewedEvents(currentPage),
      $handleCategoryViewedEvent(),
      $handleProductPurchasedEvents(currentPage),
      $handleProductAddedToCartEvents()
    ]);
  } catch (err) {
    logger.error('problem reporting events', err);
    $sendError('re', err);
  }
}