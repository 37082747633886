import constants from './constants';
import { $wait, $waitFor, getPath, getDayOfWeek, getShopifyRootPath, getTimeOfDay, isEmptyObject } from './utils';
import { getPreviewInfo } from './preview';

export async function $getTagServices() {
  // if we're running import mode this should already be on the window
  if (window._HIPRO_TAG_SVC) {
    return window._HIPRO_TAG_SVC;
  }

  const script = document.querySelector(constants.H30_SCRIPT_SELECTOR);
  // _h30_analytics set by dynamic tag
  if (!script && window._h30_analytics !== true) {
    return;
  }
  await $waitFor(() => window._HIPRO_TAG_SVC);
  return window._HIPRO_TAG_SVC;
}

export function isObviyoHyperEnabled() {
  return window._obv_hyper === true;
}

function isObviyoApiEnabled() {
  if (window.obviyo && window.obviyo.api) return true;

  if (window._obv_tag === true) return true;

  return false;
}

export async function $getObviyoApi() {
  if (!isObviyoApiEnabled()) return;

  // if we're running import mode this should already be on the window
  if (window.obviyo && window.obviyo.api) {
    return window.obviyo.api;
  }

  await $waitFor(() => window.obviyo && window.obviyo.api);
  return window.obviyo.api;
}

// legacy, only kept b/c some recommendations are using it in custom code
// TODO: check fully to see if we can remove altogether
export function getCurrentPage() {
  const SEARCH_PATH = '/search';
  const CART_PATH = '/cart';

  let path = getPath();
  if (path && path[path.length - 1] === '/') {
    path = path.substr(0, path.length - 1);
  }
  let rootPath = getShopifyRootPath();
  if (rootPath && rootPath[rootPath.length - 1] === '/') {
    rootPath = rootPath.substr(0, rootPath.length - 1);
  }

  let currentPage = 'content';

  const hicData = getHicDataObject();
  if (hicData && hicData.page && hicData.page.kind === '404') {
    currentPage = '404';
  } else if (path === '' || path === rootPath) {
    currentPage = 'home';
  } else if (path.indexOf('/products/') > -1) {
    currentPage = 'pdp';
  } else if (path.indexOf('/collections/') > -1) {
    currentPage = 'category';
  } else if (path === SEARCH_PATH || path === rootPath + SEARCH_PATH) {
    currentPage = 'search';
  } else if (path === CART_PATH || path === rootPath + CART_PATH) {
    currentPage = 'cart';
  } else if (path.indexOf('/checkouts/') > -1) {
    if (path.indexOf('/thank_you') > -1 || path.indexOf('/thank-you') > -1) {
      currentPage = 'confirm';
    } else {
      currentPage = 'checkout';
    }
  } else if (path.indexOf('/account') > -1) {
    currentPage = 'account';
  } else if (path.indexOf('/orders/') > -1) {
    const preview = getPreviewInfo();
    // allow the normal order status pages to be treated as confirm pages for preview mode
    if (preview.isPreviewMode) {
      currentPage = 'confirm';
    } else {
      currentPage = 'account';
    }
  }

  return currentPage;
}

function getHicDataObject() {
  return window.__hic && window.__hic.data;
}

function getShopifyCheckoutObject() {
  return window.Shopify && window.Shopify.checkout;
}



export function getSiteIdFromScriptTag() {
  let script = document.querySelector(constants.H30_SCRIPT_SELECTOR);
  if (!script || !script.src) {
    script = document.querySelector(constants.OBV_LOADER_SCRIPT_SELECTOR);
  }
  if (script && script.src) {
    const scriptParts = script.src.split('/');
    const siteIdParts = scriptParts[scriptParts.length - 1].split('?');
    return siteIdParts[0];
  }

  return '';
}

export async function $getSiteId() {
  const dataObj = getHicDataObject();
  let siteId = dataObj && dataObj.siteId;
  if (!siteId) {
    // try falling back to the tag in the page, just might need to wait for it to come in
    siteId = getSiteIdFromScriptTag();
    while (!siteId) {
      await $wait(200);
      siteId = getSiteIdFromScriptTag();
    }
  }
  return siteId;
}

export function getMyShopifyDomain() {
  return window.Shopify && window.Shopify.shop;
}

export function getStoredCartItems() {
  const storedStr = localStorage.getItem(constants.STORED_ITEMS_KEY);
  let storedItems;
  if (storedStr) {
    storedItems = JSON.parse(storedStr);
  }
  return storedItems;
}

export function setStoredCartItems(items) {
  localStorage.setItem(constants.STORED_ITEMS_KEY, JSON.stringify(items));
}

export function deleteStoredCartItems() {
  localStorage.removeItem(constants.STORED_ITEMS_KEY);
}

// legacy function, left to support existing recommendations that are relying on it
export function getCartItems() {
  const currentPage = getCurrentPage();
  const dataObj = getHicDataObject();
  let cartItems;
  if (dataObj) {
    if (currentPage === 'checkout') {
      cartItems = dataObj.tr && dataObj.tr.li;
    } else {
      cartItems = dataObj.cart && dataObj.cart.li;
    }
  }

  return cartItems;
}

export async function $getCartItems() {
  const currentPage = await $getCurrentPage();
  const dataObj = getHicDataObject();
  let cartItems;
  if (dataObj) {
    if (currentPage === 'checkout') {
      cartItems = dataObj.tr && dataObj.tr.li;
    } else {
      cartItems = dataObj.cart && dataObj.cart.li;
    }
  }

  return cartItems;
}

export function getCurrentCategoryId() {
  const dataObj = getHicDataObject();
  let cat;

  if (dataObj) {
    cat = dataObj.page && dataObj.page.catId;
  }

  return cat;
}

export function getCurrentCategoryName() {
  const dataObj = getHicDataObject();
  let cat;

  if (dataObj) {
    cat = dataObj.page && dataObj.page.cat;
  }

  return cat;
}

export function getCurrentProductId() {
  const dataObj = getHicDataObject();
  return dataObj && dataObj.product && dataObj.product.id;
}

export function getCurrentProductHandle() {
  const dataObj = getHicDataObject();
  return dataObj && dataObj.product && dataObj.product.handle;
}

export function getCurrentProductName() {
  const dataObj = getHicDataObject();
  return dataObj && dataObj.product && dataObj.product.nm;
}

export function getCurrentProductCategoryIds() {
  const currentPage = getCurrentPage();
  const dataObj = getHicDataObject();
  let cats = [];
  // explicitly restrict to pdp for now
  if (dataObj && currentPage === 'pdp') {
    if (dataObj.product && dataObj.product.catIds && dataObj.product.catIds.length) {
      cats = dataObj.product.catIds.slice(0);
    }
  }

  return cats;
}

export function getMoneyFormat() {
  // TODO: will need to think about how to do this if we need to run recs on pages where we don't have a dataobject on page
  const dataObj = getHicDataObject();
  return dataObj && dataObj.moneyFormat;
}

export function getProductBrand() {
  const dataObj = getHicDataObject();
  return dataObj && dataObj.product && dataObj.product.brand;
}

export function getVisitorIdFromPlatform() {
  const dataObj = getHicDataObject();
  let visitorId = dataObj && dataObj.user && dataObj.user.id;
  if (!visitorId) {
    // need to also check checkout object for confirmation page on non-plus
    const checkoutObj = getShopifyCheckoutObject();
    visitorId = checkoutObj && checkoutObj.customer_id;
  }
  return visitorId;
}

export function getTransactionLineItems() {
  const dataObj = getHicDataObject();
  let li = dataObj && dataObj.tr && dataObj.tr.li;
  if (!li) {
    // need to also check checkout object for confirmation page on non-plus
    const checkoutObj = getShopifyCheckoutObject();
    li = checkoutObj && checkoutObj.line_items;
  }
  return li;
}

export async function $getHicVisitorId() {
  const obvApi = await $getObviyoApi();
  if (obvApi) {
    return obvApi.getVisitorId();
  }

  const tagServices = await $getTagServices();
  if (tagServices) {
    const info = tagServices && tagServices.getInfo();
    return info && info.visitor && info.visitor.id;
  }
}

function normalizePageForEditor(page) {

  // allow the normal order status pages to be treated as confirm pages for preview mode
  if (page === 'account') {
    const preview = getPreviewInfo();
    if (preview.isPreviewMode) {
      let path = getPath();
      if (path.indexOf('/orders/') > -1) {
        return 'confirm';
      }
    }
  }

  return page;
}

export async function $getCurrentPage() {
  const obvApi = await $getObviyoApi();
  if (obvApi) {
    let currentPage = await obvApi.$getCurrentPage();
    return normalizePageForEditor(currentPage && currentPage.className);
  }

  const tagServices = await $getTagServices();
  if (tagServices) {
    const info = tagServices && tagServices.getInfo();
    await $waitFor(() => info && info.page && !isEmptyObject(info.page));
    return normalizePageForEditor(info && info.page && info.page.cn);
  }
}

export async function $getHicSessionId() {
  const obvApi = await $getObviyoApi();
  if (obvApi) {
    return obvApi.getSessionId();
  }

  // for legacy purposes just use 3.0 session id
  const tagServices = await $getTagServices();
  if (tagServices) {
    const info = tagServices && tagServices.getInfo();
    return info && info.session && info.session.id;
  }
}

async function $getEdgeData() {
  const obvApi = await $getObviyoApi();
  if (obvApi) {
    return obvApi.$getEdgeData();
  }

  const tagServices = await $getTagServices();
  if (tagServices) {
    const info = tagServices && tagServices.getInfo();
    await $waitFor(() => info && info.session && !isEmptyObject(info.session.esi));
    return info.session && info.session.esi;
  }
}

export async function $getFormFactor() {
  const edgeData = await $getEdgeData();
  return edgeData && edgeData.device && edgeData.device.form_factor;
}

export async function $getCountry() {
  const edgeData = await $getEdgeData();
  return edgeData && edgeData.geo && edgeData.geo.country;
}

export async function $getRegion() {
  const edgeData = await $getEdgeData();
  return edgeData && edgeData.geo && edgeData.geo.region;
}

export async function $getDMA() {
  const edgeData = await $getEdgeData();
  return edgeData && edgeData.geo && edgeData.geo.metro;
}

export async function $getTrafficSource() {
  const obvApi = await $getObviyoApi();
  if (obvApi) {
    let attributionData = await obvApi.$getAttributionData();
    return attributionData && attributionData.source;
  }

  const tagServices = await $getTagServices();
  if (tagServices) {
    const info = tagServices && tagServices.getInfo();
    return info && info.session && info.session.attributionData && info.session.attributionData.source;
  }
}

export async function $isNewVisitor() {
  const obvApi = await $getObviyoApi();
  if (obvApi) {
    return obvApi.isNewVisitor();
  }

  const tagServices = await $getTagServices();
  if (tagServices) {
    const info = tagServices && tagServices.getInfo();
    return info && info.session && info.session.isNew;
  }
}

export async function $getArrivalPageClass() {
  const obvApi = await $getObviyoApi();
  if (obvApi) {
    let arrivalPageClass = obvApi.getArrivalPageClass();
    if (!arrivalPageClass) {
      arrivalPageClass = await $getCurrentPage();
    }

    return arrivalPageClass;
  }

  let arrivalPageClass = '';
  const tagServices = await $getTagServices();
  if (tagServices) {
    const info = tagServices && tagServices.getInfo();
    if (info && info.session && info.session.created) {
      arrivalPageClass = await $getCurrentPage();
    } else {
      arrivalPageClass = info && info.session && info.session.arrivalPageClass;
    }
    return arrivalPageClass;
  }
}

export async function $getSegmentData() {
  let segs = {};

  const formFactor = await $getFormFactor();
  if (formFactor) {
    segs.ff = formFactor;
  }

  const country = await $getCountry();
  if (country) {
    segs.co = country;
  }

  const trafficSource = await $getTrafficSource();
  if (trafficSource) {
    // as = attribution_source
    segs.as = trafficSource;
  }

  const arrivalPageClass = await $getArrivalPageClass();
  if (arrivalPageClass) {
    segs.apc = arrivalPageClass;
  }

  segs.tod = getTimeOfDay();
  segs.dow = getDayOfWeek();

  return segs;
}

export async function $getVisitorIds() {
  let ids = {};
  ids.platform = getVisitorIdFromPlatform();
  ids.hic = await $getHicVisitorId();
  return ids;
}
