export function shopifyCart() {

    let $get = async () => {

        const response = await fetch('/cart.js', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const responseJson = await response.json();
        return responseJson;

    };

    let $update = async (formData) => {

        let jsonFormData = JSON.stringify(formData);
        const response = await fetch('/cart/update.js', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: jsonFormData
        });

        const responseJson = await response.json();
        return responseJson;

    };

    let $change = async (formData) => {

        let jsonFormData = JSON.stringify(formData);
        const response = await fetch('/cart/change.js', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: jsonFormData
        });

        const responseJson = await response.json();
        return responseJson;

    };

    let $add = async (formData) => {

        let jsonFormData = JSON.stringify(formData);
        const response = await fetch('/cart/add.js', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: jsonFormData
        });

        const responseJson = await response.json();
        return responseJson;

    };

    return {
        $get,
        $update,
        $change,
        $add,
    };

}
