
function getIntersectionObserver(targetNode, cb, options) {
  // https://caniuse.com/intersectionobserver
  // uc browser for android doesn't have isIntersecting option
  const HAS_IO = typeof IntersectionObserver === 'function'
    && typeof IntersectionObserverEntry === 'function'
    && 'isIntersecting' in IntersectionObserverEntry.prototype;

  const { threshold, root, rootMargin } = options;

  if (!HAS_IO) cb({ isIntersecting: true });

  const observer = new IntersectionObserver(entries => {
    const result = cb(entries[0]);
    if (result === false) {
      observer.disconnect();
    }
  }, { threshold, root, rootMargin });
  observer.observe(targetNode);

  return observer;
}

const THRESHOLD_X = 800;
const THRESHOLD_Y = 800;

export async function $waitForIntersection(targetNode, options) {
  return new Promise(resolve => {
    getIntersectionObserver(targetNode, io => {
      if (io.isIntersecting) {
        resolve();
        return false;
      }
    }, options || {
      rootMargin: `${THRESHOLD_Y}px ${THRESHOLD_X}px ${THRESHOLD_Y}px ${THRESHOLD_X}px`
    });
  });

}