/**
 * Render native Shopify product card using app-proxy
 */

import {
  $waitFor, getClosest, getHandle, htmlToElement, inEditor
} from '../../utils';

import {
  $loadAssets, getAppProxyUrl
} from '../../utils-app-proxy';

import {
  $waitForIntersection
} from '../../intersectionObserver';


let loggingHistoryMap = {};
function logOnce(rec, message) {
  if (!loggingHistoryMap[rec.external + message]) {
    rec.logger.debug(message);
    loggingHistoryMap[rec.external + message] = 1;
  }
}

const IN_EDITOR = inEditor();

async function $fetchAndRenderProductCard(ctx, url) {
  await $waitFor(() => {
    return !!ctx.dom.item();
  });

  let itemNode = ctx.dom.item();

  if (ctx.rec.lazyRender !== false) {
    const THRESHOLD_Y = 800;
    let options = {};

    const root = getClosest(itemNode, '.hc-psz-viewport-root');

    if (root) {
      options.root = root;
    }

    let thresholdX = 800;
    if (ctx.rec.lazyRender === 'visible') {
      thresholdX = 0;
    } else if (root) {
      const boundingRect = root.getBoundingClientRect();
      thresholdX = boundingRect.width;
    }

    options.rootMargin = `${THRESHOLD_Y}px ${thresholdX}px ${THRESHOLD_Y}px ${thresholdX}px`;

    // editor redraws this, so this itemNode is no longer valid, and won't be detected
    if (!IN_EDITOR) {
      await $waitForIntersection(itemNode, options);
    }
  }

  /* can't get shopify app proxy to set max-age headers
   * therefore, force local caching, current for one minute
   * example: https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
   */
  let response = await fetch(url, { cache: 'force-cache' });
  const date = response.headers.get("date");
  const dt = date ? new Date(date).getTime() : 0;
  if (dt < (Date.now() - 60 * 1000)) {
    response = await fetch(url, { cache: 'reload' });
  }

  let text = await response.text();

  // editor redraws this, so this itemNode is no longer valid
  // if redrawing multiple times, the item may replaced
  if (IN_EDITOR) {
    itemNode = ctx.dom.item();
    if (!itemNode) return;
  }

  itemNode.innerHTML = '';
  itemNode.appendChild(htmlToElement(text));

  let rec = ctx.rec;
  let item = ctx.item;

  let nativeCard = rec.nativeCard || {};

  if (nativeCard.postRenderCard) {
    await nativeCard.postRenderCard({ rec, item });
  }
}

export async function $getShopifyProductCard(ctx) {
  let rec = ctx.rec;
  let item = ctx.item;

  let nativeCard = rec.nativeCard || {};

  logOnce(rec, 'rendering native card');

  if (nativeCard.alternateAssetsLiquid) {
    logOnce(rec, `loading custom asset liquid ${nativeCard.alternateAssetsLiquid}`);
    $loadAssets('custom-liquid', [{ name: 'liquid', value: nativeCard.alternateAssetsLiquid }]);
  } else {
    if (rec.showProductQuickAdd) {
      $loadAssets('quick-add-assets');
    }
    $loadAssets('product-card-css');
  }

  const defaultSettingsParamNameMap = {
    product: 'card_product',
    secondaryImage: 'show_secondary_image',
    vendor: 'show_vendor',
    rating: 'show_rating',
    quickAdd: 'show_quick_add'
  };

  if (nativeCard.alternateParamNames) {
    logOnce(rec, `using custom param names: ${JSON.stringify(nativeCard.alternateParamNames)}`);
  }
  let settingsParamNameMap = Object.assign({}, defaultSettingsParamNameMap, nativeCard.alternateParamNames);


  let settings = [
    { name: settingsParamNameMap.product, value: `all_products["${getHandle(item.onlineStoreUrl)}"]` },
  ];

  function addSettingIfDefined(setting) {
    if (typeof setting.value !== 'undefined') {
      settings.push(setting);
    }
  }

  addSettingIfDefined({ name: settingsParamNameMap.secondaryImage, value: rec.showProductSecondaryImage });
  addSettingIfDefined({ name: settingsParamNameMap.vendor, value: rec.showProductVendor });
  addSettingIfDefined({ name: settingsParamNameMap.rating, value: rec.showProductRating });
  addSettingIfDefined({ name: settingsParamNameMap.quickAdd, value: rec.showProductQuickAdd });

  function getParamsString(settings) {
    let paramsArray = [];
    if (!settings) {
      return;
    }

    for (let setting of settings) {
      paramsArray.push(`${setting.name}: ${setting.value}`);
    }

    return JSON.stringify(paramsArray);
  }

  if (nativeCard.additionalParams) {
    logOnce(rec, `using additional custom params: ${JSON.stringify(nativeCard.additionalParams)}`);
    settings = settings.concat(nativeCard.additionalParams);
  }

  // This is useful for creating params that are item dependent
  if (nativeCard.additionalParamsFn) {
    let additionalSettings = await nativeCard.additionalParamsFn({ rec, item, getHandle });
    if (additionalSettings && additionalSettings.length) {
      settings = settings.concat(additionalSettings);
    }
  }

  let paramString = getParamsString(settings);

  let params = [{ name: 'params', value: paramString }];

  // allow redefinition of liquid snippet function name
  if (nativeCard.alternateSnippetName) {
    logOnce(rec, `using custom snippet name: ${nativeCard.alternateSnippetName}`);
    params.push({ name: 'func_name', value: nativeCard.alternateSnippetName });
  }

  let url = getAppProxyUrl('product-card', params);

  $fetchAndRenderProductCard(ctx, url);

  return await ctx.$parser('{itemSkeleton}');
}

