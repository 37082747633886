import constants from './constants';

/*
  * Console logging and debugging.
  * DO NOT USE console.log!!!
  *
  * info: replaces console.log.  will always print.
  * debug: only prints if _hc_psz_debug is set
  * error: special case to print stack, also only prints if _hc_psz_debug is set
  */
export default function Logger(context) {

  let _prefixMap = {
    info: 'PSZ-INFO:',
    debug: 'PSZ-DEBUG:',
    error: 'PSZ-ERROR:'
  };

  // helper
  function _noop() { }

  function getPrefix(key) {
    return (_prefixMap[key] || '') + context;
  }

  function bindLogger(key) {
    let prefix = getPrefix(key);
    let canBind = (typeof window.console.log === 'function' && window.console.log.bind);
    return canBind ? window.console.log.bind(window.console, prefix) : _noop;
  }

  // default - silent
  const log = {
    info: _noop,
    debug: _noop,
    qa: _noop,
    error: _noop,
    getDebugMode: function () {
      return !!(window.localStorage && window.localStorage.getItem(constants.PSZ_DEBUG_KEY));
    }
  };

  // if console exists, we can proceed
  if (window.console && window.console.log) {

    context = context ? ' ' + context : '';

    /**
     * Bind console to logger functions: info,debug
     * Handle error separately
     */
    log.info = bindLogger('info');
    if (log.getDebugMode()) {
      log.debug = bindLogger('debug');
    }

    // special case for error
    if (
      (log.getDebugMode() || window._obvShowJestErrors)
      && window.console.error && typeof window.console.error === 'function' && window.console.error.bind
    ) {
      log.error = window.console.error.bind(window.console, getPrefix('error'));
    }
  }

  return log;
}