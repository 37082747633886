import { $getItemRatingsHtml } from '../ratings';
import { addSizeToUrl, arrayFrom, createElement, formatMoney, getDomObserver, getIdFromGid, getSelector, hasOnlyDefaultVariant, htmlToElement, replace, replaceAll, setItemShow, $waitFor } from '../../utils';
import { renderIcon } from '../../icons';
import { $getObviyoApi, $getTagServices, $getCurrentPage, getMoneyFormat } from '../../data';
import { getHcid, $sendDebugInfo } from '../../reporting';
import constants from '../../constants';
import { shopifyCart } from '../cart';
import { getMinicart } from '../minicart';
import hcPszLazy from '../../../lazysizes/hcpszlazysizes';
import { $applyCustomFn } from '../../customJs';
import { $listItemResolver } from '../list';
import { $getShopifyProductCard } from './shopifyProductCard';

let lazyConfig = {
  lazyClass: "hc-psz-lazy-load",
  loadingClass: "hc-psz-item-img-loading",
  loadedClass: "hc-psz-item-img-loaded",
  srcsetAttr: "data-hc-psz-srcset",
  sizesAttr: "data-hc-psz-sizes",
};

Object.keys(lazyConfig).forEach(function (key) {
  hcPszLazy.cfg[key] = lazyConfig[key];
});

let lazyEvents = function () {

  let callbacks = {};

  let addListener = (eventName) => {
    document.addEventListener(eventName, function (e) {
      // improve so that image doesn't always have to be nested down two levels
      let item = e.target.parentElement.parentElement;
      let hcid = item.getAttribute('data-hcid');
      if (hcid !== null) {
        let cb = callbacks[eventName][hcid];
        if (typeof cb === 'function') cb();
      }
    });
  };

  let onEvent = (eventName, hcid, cb) => {
    if (typeof callbacks[eventName] === 'undefined') {
      callbacks[eventName] = {};
      addListener(eventName);
    }
    callbacks[eventName][hcid] = cb;
  };

  return {
    onBeforeUnveil: (hcid, cb) => onEvent('hcpszlazybeforeunveil', hcid, cb),
    onLoaded: (hcid, cb) => onEvent('hcpszlazyloaded', hcid, cb),
  };

}();

const OPTION_NAME_SUBSTITUTION_KEY = '{option}';
const ERROR_SELECT_OPTION = 'hc-psz-error-select-option';
const ENCODED_OPEN_CURLY = '#ocurly#';
const ENCODEDED_CLOSED_CURLY = '#ccurly#';
let domObserver = getDomObserver();

export async function $createItem(args) {

  let rec = args.rec;
  let widget = args.widget;
  let className = args.className;
  let page = await $getCurrentPage();

  let item = args.item;
  let itemIndex = args.index;
  let itemHcid = getHcid(rec, item, itemIndex, 'link');
  let itemPid = getIdFromGid(item.id);
  let itemVariants = args.item.variants;
  let itemVariantsToShow = (rec.hideOutOfStockVariants) ? itemVariants.filter(v => v.availableForSale) : itemVariants;
  let itemVariant = null;
  let itemPrice = null;
  let itemComparePrice = null;
  let addToCartButtonState;

  let selectors = {
    psz: 'hc-psz',
    item: 'hc-psz-item',
    itemSaleBadge: 'hc-psz-item-sale-badge',
    itemImgWrap: 'hc-psz-item-img-wrap',
    itemImg: 'hc-psz-item-img',
    itemImgSpinner: 'hc-psz-icon-spinner',
    itemPrices: 'hc-psz-item-prices',
    itemAddToCartSelect: 'hc-psz-item-add-to-cart-select',
    itemAddToCartButton: 'hc-psz-item-add-to-cart-btn',
  };

  let dom = Object.assign({}, selectors);
  dom.items = () => { return document.querySelectorAll(`${selectors.psz} .hc-psz-item`) };
  Object.keys(selectors).forEach(function (key) {
    let className = selectors[key];
    let selector = getSelector(className, { rec: rec, product: item, index: itemIndex });
    selectors[key] = selector;
    dom[key] = () => { return document.querySelector(selector) };
  });

  let resolverMap = {
    'item': () => { return getItemHtml() },
    'itemImage': (src, coefficient) => { return getItemImageHtml(src, coefficient) },
    'itemAttrsWrap': () => { return getItemAttrsWrapHtml() },
    'itemAddToCart': () => { return getItemAddToCartHtml() },
    'itemAddToCartButton': () => { return getItemAddToCartButtonHtml() },
    'itemAddToCartButtonText': () => { return getItemAddToCartButtonText() },
    'itemAddToCartSelect': () => { return getItemAddToCartSelectHtml() },
    'itemAddToCartSelectOptions': () => { return getItemAddToCartSelectOptionsHtml() },
    'itemTitle': () => { return getItemTitleHtml() },
    'itemTitleText': () => { return getItemTitleText() },
    'itemAltTitle': () => { return getItemAltTitleHtml() },
    'itemAltTitleText': () => { return getItemAltTitleText() },
    'itemVendor': () => { return getItemVendorHtml() },
    'itemVendorText': () => { return getItemVendorText() },
    'itemVariantTitle': () => { return getItemVariantTitleHtml() },
    'itemVariantTitleText': () => { return getItemVariantTitleText() },
    'itemSaleBadge': () => { return getItemSaleBadgeHtml() },
    'itemSalePercent': () => { return getItemSalePercent() },
    'itemSaleTotal': () => { return getItemSaleTotal() },
    'itemRatings': async () => { return await $getItemRatingsHtml(rec, item) },
    'itemPrices': () => { return getItemPricesHtml() },
    'itemComparePrice': () => { return getItemComparePriceHtml() },
    'itemComparePriceText': () => { return getItemComparePriceText() },
    'itemComparePriceValue': () => { return getItemComparePrice() },
    'itemPrice': () => { return getItemPriceHtml() },
    'itemPriceText': () => { return getItemPriceText() },
    'itemPriceValue': () => { return getItemPrice() },
    'itemVariantOptionsTitle': () => { return getItemVariantOptionsTitle() },
    'itemSkeleton': () => { return getItemSkeleton() }
  };

  function defaultResolver(field) {
    let resolverFn = resolverMap[field];
    if (typeof resolverFn === 'function') {
      return resolverFn();
    }
    return '';
  }

  let resolverFnMap = {
    'shopify-list': async (field) => { return $listItemResolver({ rec, item, field, $default: () => { return defaultResolver(field) } }) }
  };

  function getResolver(rec) {
    let resolver = resolverFnMap[rec.cardKind];

    if (!resolver) {
      resolver = defaultResolver;
    }

    return resolver;
  }

  // this lets us match template strings of the form
  // { itemTitle } and also do basic subproperty matching like { itemAddToCart.button }
  let templateMatcher = /{ *([^\s{}]*) *}/g;
  let decodeCurlies = false;

  async function $parser(html) {
    let parsed = html;
    let depth = 0;

    let resolver = getResolver(rec);

    let matches = arrayFrom(parsed.matchAll(templateMatcher));

    while (matches.length > 0 && depth < 10) {
      for (let match of matches) {
        let resolved = await $applyCustomFn({
          $fn: resolver,
          fnParams: [match[1]],
          refName: 'resolver',
          rec,
          additionalCtx: {
            field: match[1],
            item,
            itemVariant,
            formatPrice: formatPriceFn,
            itemVariants,
            selectors,
            isSaleItem,
            resolverMap
          }
        });

        if (resolved && resolved.resolved && resolved.terminal) {
          // replace open and closed curlies
          // set flag to do a final sweep and unreplace curlies
          // #ocurl# #ccurl#
          resolved = replaceAll(resolved.resolved, '{', ENCODED_OPEN_CURLY);
          resolved = replaceAll(resolved, '}', ENCODEDED_CLOSED_CURLY);
          decodeCurlies = true;
        } else if (typeof resolved !== 'string') {
          resolved = '';
        }

        parsed = parsed.replace(match[0], resolved);
      }
      matches = arrayFrom(parsed.matchAll(templateMatcher));
      depth++;
    }

    if (decodeCurlies) {
      parsed = replaceAll(parsed, ENCODED_OPEN_CURLY, '{');
      parsed = replaceAll(parsed, ENCODEDED_CLOSED_CURLY, '}');
    }

    return parsed;
  }

  let getItemSkeleton = () => {
    return `<div class="hc-psz-item-skeleton">${renderIcon('spinner').firstChild.outerHTML}</div>`;
  };

  let getItemTitleText = () => item.title;

  let getItemTitleHtml = () => {
    return `<div class="hc-psz-item-title">{itemTitleText}</div>`;
  };

  let getItemAltTitleText = () => {
    if (rec.showProductAltTitle && rec.setProductAltTitleValues) {
      let altTitles = JSON.parse(rec.setProductAltTitleValues);
      let altTitle = altTitles[itemPid];
      if (typeof altTitle === "string") {
        return altTitle;
      }
    }
    return '';
  };

  let getItemAltTitleHtml = () => {
    return `<div class="hc-psz-item-alt-title">{itemAltTitleText}</div>`;
  };

  let getItemVendorText = () => {
    return (item.vendor) ? item.vendor : '';
  };

  let getItemVendorHtml = () => {
    return `<div class="hc-psz-item-vendor">{itemVendorText}</div>`;
  };

  let getItemVariantTitleText = () => {
    return (itemVariant) ? itemVariant.title : '';
  };

  let getItemVariantTitleHtml = () => {
    return (itemVariant) ? `<div class="hc-psz-item-variant-title">{itemVariantTitleText}</div>` : '';
  };

  let getItemVariantOptionsTitle = () => {
    if (hasOnlyDefaultVariant(itemVariants) === false) {
      if (itemVariants.length && !!itemVariants[0].selectedOptions) {
        let selectedOptions = itemVariants[0].selectedOptions;
        if (selectedOptions.length && !!selectedOptions[0].name) {
          return selectedOptions[0].name;
        }
      }

    }
    return '';
  };

  let setVariant = (variantId) => {

    if (!itemVariants || !itemVariants.length) {
      return;
    }

    if (variantId) {
      itemVariant = itemVariants.find(variant => getIdFromGid(variant.id) === variantId);
    } else {

      var i;
      for (i = 0; i < itemVariants.length; i++) {
        let curVariant = itemVariants[i];
        if (parseFloat(curVariant.price) > 0 && curVariant.availableForSale === true) {
          itemVariant = curVariant;
          break;
        }
      }

    }

    if (itemVariant) {
      itemPrice = itemVariant.price;
      itemComparePrice = itemVariant.compareAtPrice;
    }

  };

  let getImageAttrs = (url, sizes, coefficient) => {

    let attrs = {
      [lazyConfig.srcsetAttr]: '',
      [lazyConfig.sizesAttr]: '',
      "alt": item.title
    };

    let imgWrapWidth = 100;
    if (dom.itemImgWrap()) {
      imgWrapWidth = dom.itemImgWrap().offsetWidth;
    }
    let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    sizes.forEach(function (size, i) {
      let comma = (sizes.length === i + 1) ? "" : ",";
      let imgUrl = addSizeToUrl(url, size, coefficient);
      attrs[lazyConfig.srcsetAttr] += `${imgUrl} ${size}w${comma}`;
      let maxWidth = Math.floor((screenWidth / imgWrapWidth) * size);
      attrs[lazyConfig.sizesAttr] += `(max-width: ${maxWidth}px) ${size}px${comma}`;
    });

    return attrs;

  };

  let getItemImageHtml = (incomingSrc, coefficient) => {
    let src = incomingSrc;
    // rec.showProductImageLoadingIcon, rec.showProductImageLoading, rec.setProductImageLoadingSize
    if (!src && item.featuredImage && item.featuredImage.originalSrc) {
      src = item.featuredImage.originalSrc;
    }

    if (src) {
      let imgSrcSize = 50;
      if (rec.setProductImageLoadingSize && typeof rec.setProductImageLoadingSize === 'number') {
        imgSrcSize = rec.setProductImageLoadingSize;
      }
      let imgSrc = addSizeToUrl(src, imgSrcSize, coefficient);

      let imgOpacity = "opacity: 1";
      if (typeof rec.showProductImageLoading === "boolean") {
        imgOpacity = (rec.showProductImageLoading) ? "opacity: 1" : "opacity: 0";
      }

      let img = createElement({
        tag: 'img',
        className: ['hc-psz-item-img'],
        selector: selectors.itemImg,
        attrs: {
          src: imgSrc,
          style: imgOpacity,
        }
      });
      img.getElement().addEventListener("load", async function (event) {

        // wait for img to load
        let itemImg = dom.itemImg();
        if (!itemImg) {
          await $waitFor(() => {
            itemImg = dom.itemImg();
            return !!itemImg;
          }, 5000);
        }

        // calculate breakpoints based on screen size and imgWrapper width
        let imgAttrs = getImageAttrs(src, [50, 75, 100, 150, 200, 250, 300, 350, 400, 500, 600, 800, 1000, 1200, 1500, 2000], coefficient);
        img.setAttributes(imgAttrs);

        if (itemImg) {
          itemImg.classList.add(lazyConfig.lazyClass);
        }

        lazyEvents.onLoaded(itemHcid, function () {
          if (dom.itemImgWrap()) {
            dom.itemImgWrap().classList.remove(lazyConfig.loadingClass);
            dom.itemImgWrap().classList.add(lazyConfig.loadedClass);
          }
          if (dom.itemImg()) {
            dom.itemImg().style.opacity = "1";
          }
          if (dom.itemImgSpinner()) {
            dom.itemImgSpinner().remove();
          }
        });
      }, { once: true });

      let imgWrapAppend = [img];
      if (rec.showProductImageLoadingIcon === true) {
        let loadIcon = renderIcon('spinner');
        imgWrapAppend.push(loadIcon);
      }

      let imgWrap = createElement({
        className: ['hc-psz-item-img-wrap', lazyConfig.loadingClass],
        selector: selectors.itemImgWrap,
        append: imgWrapAppend
      });

      if (rec.imgRatio && rec.imgRatio !== 'auto') {
        let ratios = rec.imgRatio.match(/(\d+)/g);
        if (ratios !== null && ratios.length === 2) {
          let size = { width: parseFloat(ratios[0]), height: parseFloat(ratios[1]) };
          let paddingTop = `${100 * (size.height / size.width)}%`;
          imgWrap.setStyle('paddingTop', paddingTop);
          imgWrap.addClass('hc-psz-img-ratio-fixed');
        }
        if (rec.imgFit === 'cover' || rec.imgFit === 'contain') imgWrap.addClass(`hc-psz-img-fit-${rec.imgFit}`);
      } else {
        imgWrap.addClass('hc-psz-img-ratio-auto');
      }

      let imgWrapEl = imgWrap.getElement();
      if (imgWrapEl) {
        return imgWrapEl.outerHTML;
      }
    }

    return '';

  };

  let formatPriceFn = (price) => {
    let moneyFormat = (rec.hideDecimals) ? "${{amount_no_decimals}}" : getMoneyFormat();
    let formatedMoney = formatMoney(price, moneyFormat);
    return formatedMoney;
  };

  let formatPrice = async (price) => {
    return await $applyCustomFn({
      $fn: formatPriceFn,
      fnParams: [price],
      refName: 'formatPrice',
      rec,
      additionalCtx: {
        price,
        item,
        getMoneyFormat,
        formatMoney,
        selectors
      }
    });
  };

  let isSaleItem = () => {
    return (itemComparePrice && parseFloat(itemComparePrice) > parseFloat(itemPrice)) ? true : false;
  };

  let getItemSalePercent = () => {
    if (isSaleItem()) {
      let totalDiscount = parseFloat(itemComparePrice) - parseFloat(itemPrice);
      let percentDiscount = Math.round((totalDiscount / parseFloat(itemComparePrice)) * 100);
      return percentDiscount.toString() + '%';
    }
    return '0%';
  };

  let getItemSaleTotal = () => {
    if (isSaleItem()) {
      let totalDiscount = (parseFloat(itemComparePrice) - parseFloat(itemPrice)).toFixed(2);
      totalDiscount = formatPrice(totalDiscount);
      return totalDiscount;
    }
    return formatPrice('0');
  };

  let getItemSaleBadgeHtml = () => {
    let badgeText = rec.saleBadgeLabel || `{itemSalePercent}`;
    if (isSaleItem()) {
      return `<div class="hc-psz-item-sale-badge">${badgeText}</div>`;
    }
    return '';
  };

  let getItemComparePriceText = () => { return (isSaleItem()) ? formatPrice(itemComparePrice) : '' };

  let getItemComparePrice = () => { return itemComparePrice };

  let getItemComparePriceHtml = () => {
    if (isSaleItem()) {
      return `<div class="hc-psz-item-compare-price">{itemComparePriceText}</div>`;
    }
    return '';
  };

  let getItemPriceText = () => { return (itemPrice) ? formatPrice(itemPrice) : '' };

  let getItemPrice = () => { return itemPrice };

  let getItemPriceHtml = () => {
    if (itemPrice) {
      return `<div class="hc-psz-item-price">{itemPriceText}</div>`;
    }
    return '';
  };

  let getItemPricesHtml = () => {
    if (itemPrice) {
      return `<div class="hc-psz-item-prices">
      ${rec.showProductComparePrice ? '{itemComparePrice}' : ''}
      ${rec.showProductPrice ? '{itemPrice}' : ''}
      </div>`;
    }
    return '';
  };

  // TODO: add select options template?
  let getItemAddToCartSelectOptionsHtml = () => {
    let variantOptions = '';
    if (itemVariantsToShow.length) {
      if (itemVariantsToShow.length > 1 && rec.displayVariantOptions !== 'never') {
        let defaultSelectOption = 'Select';
        if (typeof rec.addToCartSelectDefault === 'string') {
          defaultSelectOption = rec.addToCartSelectDefault;
        }
        variantOptions += `<option value="selectOption" selected>${defaultSelectOption}</option>`;
      }
      for (let variant of itemVariantsToShow) {
        variantOptions += `<option value="${getIdFromGid(variant.id)}">${variant.title}</option>`;
      }
    }
    return variantOptions;
  };

  let getItemAddToCartSelectHtml = () => {
    let hidden = false;
    if (rec.displayVariantOptions === 'never' || rec.displayVariantOptions === 'dynamic' || hasOnlyDefaultVariant(itemVariants)) {
      hidden = true;
    }

    return `<select class="hc-psz-item-add-to-cart-select ${hidden ? 'hidden' : ''}"> {itemAddToCartSelectOptions} </select>`;
  };

  let getItemAddToCartButtonText = () => {
    let title = rec.addToCartTitle || 'Add';
    if (itemVariant && itemVariant.availableForSale === false || addToCartButtonState === 'soldout') {
      title = rec.outOfStockTitle || 'Sold Out';
    } else if (addToCartButtonState === 'adding') {
      title = rec.addingToCartTitle || 'Adding...';
    } else if (addToCartButtonState === 'added') {
      title = rec.addedToCartTitle || 'Added!';
    } else if (addToCartButtonState !== 'add' && rec.displayVariantOptions === 'dynamic') {
      if (itemVariantsToShow.length && !hasOnlyDefaultVariant(itemVariants)) {
        let selectedOptions = itemVariantsToShow[0].selectedOptions;
        if (selectedOptions && selectedOptions.length > 1) {
          title = rec.variantOptionsAddToCartTitle || 'Options';
        } else if (selectedOptions.length === 1) {
          title = replace(rec.variantOptionAddToCartTitle, OPTION_NAME_SUBSTITUTION_KEY, selectedOptions[0].name) || selectedOptions[0].name;
        }
      }
    }
    return title;

  };

  let getItemAddToCartButtonHtml = () => {
    return `<button type="button" class="hc-psz-item-add-to-cart-btn">{itemAddToCartButtonText}</button>`;
  };

  let getItemAddToCartHtml = () => {
    if (itemVariant && itemVariants.length) {
      return (page === 'checkout' && rec.widget === 'list') ? `{itemAddToCartButton}{itemAddToCartSelect}` : `{itemAddToCartSelect}{itemAddToCartButton}`;
    }
    return '';
  };

  let updateItemAddToCartButton = async (state) => {
    addToCartButtonState = state;
    let addToCartBtn = dom.itemAddToCartButton();
    if (!addToCartBtn) return;
    addToCartBtn.outerHTML = await $parser('{itemAddToCartButton}');
    // Have to regrab since redefining outerHTML wipes out original element reference
    addToCartBtn = dom.itemAddToCartButton();
    if (!addToCartBtn) return;
    // have to reassign click handlers since they get wiped out on outerHTML assignment
    setAddToCartButtonHandlers(addToCartBtn);
    if (itemVariant && itemVariant.availableForSale) {
      addToCartBtn.disabled = (state === 'adding' || state === 'added') ? true : false;
    } else {
      addToCartBtn.disabled = true;
    }
  };

  let updateItemPricing = async () => {
    let itemPricesDiv = dom.itemPrices();
    if (itemPricesDiv && itemVariant) {

      itemPricesDiv.outerHTML = await $parser('{itemPrices}');
      if (dom.itemSaleBadge()) {
        dom.itemSaleBadge().remove();
      }

      if (isSaleItem()) {
        dom.item().classList.add('hc-psz-sale-item');

        if (rec.showProductSaleBadge) {
          let saleBadgeNode = htmlToElement(await $parser('{itemSaleBadge}'));

          dom.item().prepend(saleBadgeNode);
        }
      } else {
        dom.item().classList.remove('hc-psz-sale-item');
      }

    }
  };

  let stopClick = (event) => {
    // prevent any click handlers from h30 or anywhere else from catching us
    // we'll handle reporting ourselves
    event.preventDefault();
    event.stopImmediatePropagation();
  };

  let setAddToCartSelectHandlers = (addToCartSelect) => {
    addToCartSelect.onclick = stopClick;
    addToCartSelect.onchange = (event) => {
      const variantId = addToCartSelect.value;
      if (variantId && variantId !== 'selectOption') {
        dom.item().classList.remove(ERROR_SELECT_OPTION);
        setVariant(variantId);
        updateItemAddToCartButton('add');
        updateItemPricing();
        // TODO: update pricing, image, etc...
      }
    };
  };

  async function $sendClickEvent(rec, item, itemIndex) {
    let dataHcid = getHcid(rec, item, itemIndex, 'ac');

    const obvApi = await $getObviyoApi();
    if (obvApi) {
      obvApi.sendEvent({
        event: 'click',
        tagName: 'div',
        pathFull: '##' + dataHcid,
        pathShort: '##' + dataHcid,
        manual: 1,
        dataset: [{ name: 'data-hcid', value: dataHcid }]
      });

      // normally we will exit on first grab to avoid duplication of data collection
      // but if we are in a test scenario we'll go ahead and send both
      if (!window[constants.OBV_ANALYTICS_TEST_FLAG]) {
        return;
      }
    }

    const tagServices = await $getTagServices();
    if (tagServices) {
      tagServices.sendClickEvent(dataHcid, [{ nm: 'data-hcid', v: dataHcid }]);
    }
  }

  function isHiddenDynamicVariantOption(itemAddToCartSelectNode) {
    return rec.displayVariantOptions === 'dynamic' && itemAddToCartSelectNode.classList.contains('hidden') && !hasOnlyDefaultVariant(itemVariants);
  }

  async function $defaultAddToCartButtonClickHandler(event) {
    stopClick(event);

    if (rec.addToCartRedirect === 'pdp' && item && typeof item.onlineStoreUrl === 'string') {
      window.location = item.onlineStoreUrl;
      return;
    }

    const itemAddToCartSelectNode = dom.itemAddToCartSelect();
    if (!item || !itemVariants || !itemAddToCartSelectNode) return;

    const variantId = itemAddToCartSelectNode.value;
    if (!itemVariant || variantId === "selectOption" || isHiddenDynamicVariantOption(itemAddToCartSelectNode)) {

      if (rec.displayVariantOptions === 'always') {
        dom.item().classList.add(ERROR_SELECT_OPTION);
        return;
      }

      if (rec.displayVariantOptions === 'dynamic') {
        if (itemAddToCartSelectNode.classList.contains('hidden')) {
          updateItemAddToCartButton('add');
          itemAddToCartSelectNode.classList.remove('hidden');
        } else {
          dom.item().classList.add(ERROR_SELECT_OPTION);
        }
        return;
      }

      if (rec.displayVariantOptions === 'never') {
        return;
      }

      return;
    }

    setVariant(variantId);
    updateItemAddToCartButton('adding');


    $sendClickEvent(rec, item, itemIndex);

    let $getProductInfo = async () => {

      const response = await fetch(item.onlineStoreUrl + '.js', {
        headers: { 'Content-Type': 'application/json' },
      });

      return response.json();

    };

    let cart = shopifyCart();
    let minicart = getMinicart(rec);
    let formData = { 'items': [{ 'id': variantId, 'quantity': 1 }] };
    let responseJson = await $applyCustomFn({
      $fn: cart.$add,
      fnParams: [formData],
      refName: 'addToCart',
      rec,
      additionalCtx: {
        getMoneyFormat, $getProductInfo, cart, minicart, params: formData,
        widget, item, itemIndex, itemHcid, itemPid, itemVariants, itemVariantsToShow, itemVariant, itemPrice, itemComparePrice,
        itemVariantId: variantId
      }
    });

    if (responseJson && responseJson.items) {
      updateItemAddToCartButton('added');
      setTimeout(async () => {

        let removeAddedItem = () => {
          dom.item().remove();
          const itemDivs = dom.items();
          if (!itemDivs || !itemDivs.length) {
            // stop observing
            domObserver.setCallbackObserve(rec.external, false);
            dom.psz().remove();
          } else {
            // lets make the next hidden one active if list
            if (rec.widget === 'list' || rec.widget === 'grid') {
              for (let div of itemDivs) {
                if (div.classList.contains(constants.ITEM_HIDE_CLASS)) {
                  setItemShow(div, true);
                  break;
                }
              }
            }
          }
        };

        if (!rec.keepAddedItem) {
          removeAddedItem();
        }

        // here we know we were successful so now we need to refresh anything that we can
        // minicart that I saw for pat mcgrath was under ajaxCart so calling ajaxCart.load does the trick there
        // we'll need to investigate other themes and general best practices here though
        if (page === 'checkout') {

          //handle refresh on checkout page
          const refreshUrl = new URL(window.location.href);
          const params = new URLSearchParams(refreshUrl.search);
          params.append('t', Date.now());
          refreshUrl.search = params;
          const result = await fetch(refreshUrl.toString());
          const text = await result.text();
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, 'text/html');

          const PRODUCTS_SELECTOR = ".order-summary__section.order-summary__section--product-list";
          const TOTALS_SELECTOR = ".order-summary__section.order-summary__section--total-lines";
          const TOGGLE_SELECTOR = ".order-summary-toggle__total-recap.total-recap";
          [PRODUCTS_SELECTOR, TOTALS_SELECTOR, TOGGLE_SELECTOR].forEach(function (selector, i) {
            let newSection = doc.querySelector(selector);
            let oldSection = document.querySelector(selector);
            if (newSection && oldSection && oldSection.innerHTML !== newSection.innerHTML) {
              oldSection.innerHTML = newSection.innerHTML;
            } else {
              $sendDebugInfo({ n: 'errorUpdatingCheckoutWidget' });
              window.location = '/checkout';
            }
          });

        } else {

          let $updateCart = async (redirect) => {

            let update = (redirect) => {
              if (minicart.canUpdate()) {
                minicart.$update();
              } else if (typeof redirect === 'string') {
                window.location = redirect;
              }
            };

            $applyCustomFn({
              $fn: update,
              fnParams: [redirect],
              refName: 'updateCart',
              rec,
              additionalCtx: {
                getMoneyFormat, $getProductInfo, cart, minicart, params: formData,
                widget, item, itemIndex, itemHcid, itemPid, itemVariants, itemVariantsToShow, itemVariant, itemPrice, itemComparePrice,
                itemVariantId: variantId
              }
            });

          };

          if (rec.addToCartRedirect === 'checkout') {
            window.location = '/checkout';
          } else if (rec.addToCartRedirect === 'cart') {
            window.location = '/cart';
          } else if (rec.addToCartRedirect === 'reload') {
            window.location.reload();
          } else if (rec.addToCartRedirect === 'none') {
            $updateCart();
          } else {
            $updateCart('/cart');
          }

        }
      }, 250);

    } else {
      // need to alert message or notify user there was an issue at this point
      // we should be able to tighten things up so there aren't too many issues here
      // i.e. make sure that inventory level is enough for the variant so we can add it to cart etc...
      // for now just adding a simple catch all error
      if (responseJson.message === 'Cart Error' && responseJson.status === 422) {
        updateItemAddToCartButton('soldout');
      } else {
        alert('Item could not be added to cart at this time. Please try again later.');
      }
    }
  }

  let setAddToCartButtonHandlers = (addToCartBtn) => {
    addToCartBtn.onclick = async (event) => {
      $applyCustomFn({
        $fn: $defaultAddToCartButtonClickHandler,
        fnParams: [event],
        refName: 'addToCartButtonClick',
        rec,
        additionalCtx: {
          event, getMoneyFormat, widget, item, itemIndex, itemHcid, itemPid,
          itemVariants, itemVariantsToShow, itemVariant, itemPrice, itemComparePrice
        }
      });
    };
  };

  let getItemAttrsWrapHtml = () => {
    return `<div class="hc-psz-item-attrs-wrap">
    ${rec.showProductVendor ? '{itemVendor}' : ''}
    ${rec.showProductAltTitle && rec.setProductAltTitleValues ? '{itemAltTitle}' : ''}
    ${rec.showProductTitle ? '{itemTitle}' : ''}
    ${rec.showProductVariantTitle ? '{itemVariantTitle}' : ''}
    ${rec.showProductRating ? '{itemRatings}' : ''}
    ${rec.showProductPrice ? '{itemPrices}' : ''}
    </div>`;
  };

  let getClass = () => {
    let classes = ['hc-psz-item', className];
    if (isSaleItem()) {
      classes.push('hc-psz-sale-item');
    }
    if (widget === 'grid' || widget === 'list') {
      let show = (itemIndex < args.show) ? constants.ITEM_SHOW_CLASS : constants.ITEM_HIDE_CLASS;
      classes.push(show);
    }
    return classes.join(' ');
  };

  let getItemHtml = () => {
    return `${rec.showProductSaleBadge ? '{itemSaleBadge}' : ''}
    ${rec.showProductImage ? '{itemImage}' : ''}
    {itemAttrsWrap}
    ${rec.showProductAddToCart ? '{itemAddToCart}' : ''}`;
  };

  let $getItemNode = async () => {
    let parsedHtml = rec.cardKind === 'native' ? await $getShopifyProductCard({ rec, item, itemIndex, $parser, dom }) : await $parser('{item}');
    let itemNode = createElement({
      tag: 'a',
      attrs: {
        'class': getClass(),
        'data-index': itemIndex,
        'data-pid': itemPid,
        'data-hcid': itemHcid,
        'href': rec.showProductLink === false ? 'javascript:void(0);' : item.onlineStoreUrl,
        'style': args.style
      },
      append: [parsedHtml]
    });

    return itemNode;
  };


  // This is completely product based
  let init = () => {
    setVariant();
    rec.on('load', () => {
      let addToCartSelect = dom.itemAddToCartSelect();
      let addToCartButton = dom.itemAddToCartButton();
      if (addToCartSelect) setAddToCartSelectHandlers(addToCartSelect);
      if (addToCartButton) setAddToCartButtonHandlers(addToCartButton);
    });
  };
  init();

  return { $getItemNode };

}
