import { getUrlParam, insertNode, inShopifyEditor, loadCss } from './utils';
import { $getTagServices } from './data';

export const PREVIEW_KEY = '_hc_psz_p';
export const PREVIEW_TEST_MODE_KEY = '_hc_psz_p_t';
export const PREVIEW_LIVE_MODE_KEY = '_hc_psz_p_l';
// valid values: prod, cur
export const PREVIEW_VIS_STATE_KEY = '_hc_psz_vs';
export const PROD_VIS_STATE = 'prod';
export const ACTIVE_VIS_STATE = 'active';
export const CURRENT_VIS_STATE = 'current';

const previewKeys = [PREVIEW_KEY, PREVIEW_TEST_MODE_KEY, PREVIEW_LIVE_MODE_KEY, PREVIEW_VIS_STATE_KEY];

function getStoredPreviewParam(key) {
  let storedPreviewValue = localStorage.getItem(key);
  if (storedPreviewValue === 'true' || storedPreviewValue === 'false') {
    storedPreviewValue = !!storedPreviewValue;
  }
  return storedPreviewValue;
}

function setStoredPreviewParam(key, previewValue) {
  localStorage.setItem(key, previewValue);
}

export function clearStoredPreviewParams() {
  for (const key of previewKeys) {
    localStorage.setItem(key, '');
  }
}

export function getUrlWithoutHicParams() {
  const params = new URLSearchParams(window.location.search);

  let newParams = new URLSearchParams(window.location.search);
  for (const param of params) {
    if (param && param.length && (param[0].indexOf('_hc') > -1 || param[0].indexOf('_obv') > -1 || param[0].indexOf('h30-') > -1)) {
      newParams.delete(param[0]);
    }
  }

  let newUrl = `${window.location.pathname}`;

  let stringParams = newParams.toString();
  if (stringParams) {
    newUrl += '?' + stringParams;
  }
  if (window.location.hash) {
    newUrl += window.location.hash;
  }

  return newUrl;
}

async function $injectPreviewBox() {
  if (document.querySelector('.hc-psz-box-container')) {
    return;
  }

  let previewBox = document.createElement('div');

  previewBox.className = 'hc-psz-box-container';

  previewBox.innerHTML =
    `<div class="hc-psz-box">
      <div class="hc-psz-toolbar">
        <i class="hc-psz-minimize" title="Hide">&minus;</i>
        <i class="hc-psz-close" title="Exit">&times;</i>
      </div>
      <div class ="hc-psz-content">In Preview/QA Mode</div>
   </div>`;

  const targetNode = document.querySelector('body');
  if (targetNode) {

    // targetNode = targetNode.firstElementChild;
    insertNode(previewBox, targetNode, null, 'append');

    let minimizeButton = document.querySelector('.hc-psz-box-container .hc-psz-minimize');
    minimizeButton.onclick = () => {
      let previewBox30 = document.querySelector('#hc-frame-container');
      if (previewBox30) previewBox30.style.display = 'none';
      let previewBox = document.querySelector('.hc-psz-box-container');
      if (previewBox) previewBox.style.display = 'none';
    };

    let closeButton = document.querySelector('.hc-psz-box-container .hc-psz-close');
    closeButton.onclick = async () => {
      clearStoredPreviewParams();
      let tagServices = await $getTagServices();
      if (tagServices) {
        tagServices.clearQAPersistedData();
      }
      let newUrl = getUrlWithoutHicParams();
      window.location = newUrl;
      if (window.location && window.location.href && window.location.href.indexOf('#') > -1) {
        window.location.reload();
      }
    };

  }

}

function getPreviewParams() {
  let params = {};
  for (const key of previewKeys) {
    let param = getUrlParam(key);
    if (typeof param !== 'undefined' && param !== null) {
      if (param === 'true' || param === 'false') {
        param = !!param;
      }
      params[key] = param;
    }
  }
  return params;
}

function getStoredPreviewParams() {
  let params = {};
  for (const key of previewKeys) {
    let param = getStoredPreviewParam(key);
    if (typeof param !== 'undefined' && param !== null) {
      params[key] = param;
    }
  }
  return params;
}

function setStoredPreviewParams(params) {
  for (const [key, val] of Object.entries(params)) {
    setStoredPreviewParam(key, val);
  }
}

function resetStoredPreviewParams(params) {
  clearStoredPreviewParams();
  setStoredPreviewParams(params);
}

export function getPreviewInfo() {
  let previewData = {
    isPreviewMode: false,
    isPreviewTestMode: false,
    isPreviewLiveMode: false,
    visState: ACTIVE_VIS_STATE
  };

  let params = getPreviewParams();
  // reset on new preview key
  if (params[PREVIEW_KEY]) {
    resetStoredPreviewParams(params);
  } else {
    params = getStoredPreviewParams();
  }

  // default to basic preview mode if in shopify editor
  if (params[PREVIEW_KEY] || inShopifyEditor()) {
    previewData.isPreviewMode = true;
    if (typeof params[PREVIEW_KEY] !== 'boolean') {
      previewData.previewExternal = params[PREVIEW_KEY];
    }

    if (params[PREVIEW_TEST_MODE_KEY]) {
      previewData.isPreviewTestMode = true;
    }

    if (params[PREVIEW_LIVE_MODE_KEY]) {
      previewData.isPreviewLiveMode = true;
    }

    if (params[PREVIEW_VIS_STATE_KEY]) {
      previewData.visState = params[PREVIEW_VIS_STATE_KEY];
    }

    if (!inShopifyEditor()) {
      $injectPreviewBox();
    }
  }

  return previewData;
}