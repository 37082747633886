import { arrayFrom, createElement, getPszIds, htmlToElement, setItemShow } from '../utils';
import { $applyCustomFn } from '../customJs';
import { getCurrentCategoryName, getProductBrand, getCurrentProductName } from '../data';
import { $renderGrid } from './grid';
import { $renderList } from './list';
import { $renderSlider } from './slider';
import { $renderHyper } from './hyper';
import { $activateRatings } from './ratings';
import { $getRecsWithContext } from '../recommendation';
import Logger from '../logger';
import { $createItem } from './items/item';
import { $parse as $parseItem } from './items/itemV2';

const logger = new Logger();

export const PSZ_ITEMS_CLASS = 'hc-psz-items';

/**
 * @typedef {Object} WidgetResolverCtx
 * @property {Object} rec
 */

/**
 * @param {string} initialField
 * @param {WidgetResolverCtx} ctx
 */
export async function $applyWidgetResolver(initialField, ctx) {

  let { rec, itemData } = ctx;

  let resolverMap = {
    'title': () => { return getTitleHtml() },
    'titleText': () => { return getTitleText() },
    'category': () => { return getCategoryText() },
    'collection': () => { return getCategoryText() },
    'productTitleText': () => { return getProductTitleText() },
    'brand': () => { return getProductBrandText() }
  };

  function resolver(field) {
    let resolverFn = resolverMap[field];
    if (typeof resolverFn === 'function') {
      return resolverFn();
    }
    return '';
  }

  // this lets us match template strings of the form
  // { itemTitle } and also do basic subproperty matching like { itemAddToCart.button }
  let templateMatcher = /{ *([^\s{}]*) *}/g;

  async function $parser(html) {

    let parsed = html;
    let depth = 0;

    let matches = arrayFrom(parsed.matchAll(templateMatcher));

    while (matches.length > 0 && depth < 10) {
      for (let match of matches) {
        let resolved = await $applyCustomFn({
          $fn: resolver,
          fnParams: [match[1]],
          refName: 'resolver',
          rec,
          additionalCtx: {
            field: match[1],
            item: {},   // to avoid current code crashing (fix later DLHR)
            resolverMap
          }
        });

        if (typeof resolved !== 'string') {
          resolved = '';
        }

        parsed = parsed.replace(match[0], resolved);
      }
      matches = arrayFrom(parsed.matchAll(templateMatcher));
      depth++;
    }
    return parsed;
  }

  let getTitleText = () => rec.title;

  let getTitleHtml = () => `<div class="hc-psz-title">{titleText}</div>`;

  let getCategoryText = () => {
    // if we have a dynamic category then we should use it
    // TODO: perhaps we should be explicit here or fallback to this if there is an explicit category provided that might act
    // as an override
    if (itemData && itemData.category && itemData.category.title) {
      return itemData.category.title;
    }
    if (rec.category && rec.category.title) {
      return rec.category.title;
    }
    const text = getCurrentCategoryName();
    return text ? text : '';
  };

  let getProductTitleText = () => {
    const text = getCurrentProductName();
    return text ? text : '';
  };

  let getProductBrandText = () => {
    // if we have a dynamic brand then we use it first
    // TODO: perhaps we should be explicit here or fallback to this if there is an explicit brand provided that might act
    // as an override
    if (itemData && itemData.brand && itemData.brand.name) {
      return itemData.brand.name;
    }
    const text = getProductBrand();
    return text ? text : '';
  };

  return $parser(initialField);
}

export function setDefaultTitleNode(widgetNode) {
  let titleNode = widgetNode.querySelector('title');
  let titleContents = titleNode.innerHTML;
  let pszTitleNode = htmlToElement(
    `<div class="hc-psz-title">${titleContents}</div>`
  );

  titleNode.parentNode.replaceChild(pszTitleNode, titleNode);
}

export function setMoreItemsLogic(idPsz, show) {

  let itemsSelector = "#" + idPsz + ' .hc-psz-items .hc-psz-item';
  let items = document.querySelectorAll(itemsSelector);

  let count = {
    orig: show.count,
    step: show.step,
    max: items.length,
    live: show.count,
  };

  let showMore = document.querySelector("#" + idPsz + ' .hc-psz-show-more');
  if (showMore !== null) {
    showMore.onclick = function () {
      let ls = count.live + count.step;
      let limit = (ls > count.max) ? count.max : ls;
      for (count.live; count.live < limit; count.live += 1) {
        let item = items[count.live];
        setItemShow(item, true);
        if (count.live === count.max - 1) {
          showMore.setAttribute('data-hc-psz-enabled', 'false');

          // Commenting this out for now as it is throwing errors in the console
          // b/c there is no showLess
          // showLess.setAttribute('data-hc-psz-enabled', 'true');
        }
      }
    };
  }

  let showLess = document.querySelector("#" + idPsz + ' .hc-psz-show-less');
  if (showLess !== null) {
    showLess.onclick = function () {
      for (count.live; count.live > count.orig; count.live -= 1) {
        let item = items[count.live - 1];
        setItemShow(item, false);
        if (count.live === count.orig + 1) {
          showMore.setAttribute('data-hc-psz-enabled', 'true');
          showLess.setAttribute('data-hc-psz-enabled', 'false');
        }
      }
    };
  }
}

export function setMoreItemsLogicV2(pszNode, show) {

  let items = pszNode.querySelectorAll('.hc-psz-items .hc-psz-item');

  let count = {
    orig: show.count,
    step: show.step,
    max: items.length,
    live: show.count,
  };

  let showMore = pszNode.querySelector('.hc-psz-show-more');
  if (showMore !== null) {
    showMore.onclick = function () {
      let ls = count.live + count.step;
      let limit = (ls > count.max) ? count.max : ls;
      for (count.live; count.live < limit; count.live += 1) {
        let item = items[count.live];
        setItemShow(item, true);
        if (count.live === count.max - 1) {
          showMore.setAttribute('data-hc-psz-enabled', 'false');
        }
      }
    };
  }
}

export async function $getTitleNode(rec, itemData) {
  let titleNode = '';
  if (rec.showTitle) {
    titleNode = await $applyWidgetResolver('{title}', { rec, itemData });
  }
  return titleNode;
}

export function renderElemShowNav() {
  let elemShowMore = createElement({
    className: ['hc-psz-show', 'hc-psz-show-more'],
    attrs: { 'data-hc-psz-enabled': 'true' },
    append: ["Show More"]
  });

  let elemShowNav = createElement({
    className: 'hc-psz-show-nav',
    append: [elemShowMore]
  });
  return elemShowNav;
}

export function createShowNavNode() {
  let showMoreNode = htmlToElement(
    `<div class="${['hc-psz-show', 'hc-psz-show-more'].join(' ')}"
        data-hc-psz-enabled="true">Show More</div>
        `
  );

  let showNav = htmlToElement(
    `<div class="hc-psz-show-nav"></div>
    `
  );

  showNav.appendChild(showMoreNode);

  return showNav;
}

export async function $renderItem(args) {
  let item = await $createItem(args);
  let itemNode = await item.$getItemNode();
  return itemNode;

}

export async function $renderItems(items, args) {
  return await Promise.all(items.map(async (item, index) => {
    var addParams = { item: item, index: index };
    var newParams = Object.assign(addParams, args);
    return await $renderItem(newParams);
  }));
}

export async function $parseItems(items, itemNode, params) {
  return await Promise.all(items.map(async (item, index) => {
    return await $parseItem(item, itemNode, index, params);
  }));
}


// reorder recs based upon insertBefore and order fields
// ordering is simple asc
// note this doesn't mutate existing recs object but instead creates a shallow copy
// precondition for this fn is that applyRecommendationFormFactorProperties has already been called on the incoming recs
export function reorderRecs(recs) {
  let recsCopy = recs.slice();

  return recsCopy.sort((rec1, rec2) => {
    let rec1Order = rec1.order || 0;
    let rec2Order = rec2.order || 0;

    return rec1Order - rec2Order;
  });
}

export function getOrderedSiblingRecs(currentRec, recs) {

  function insertionIsEqual(currentRec, testRec) {
    if (currentRec.insertKind) {
      return currentRec.insertKind === testRec.insertKind;
    } else {
      return currentRec.insertBefore === testRec.insertBefore;
    }
  }

  let siblingRecs = recs.filter(rec => {
    return currentRec.location === rec.location && insertionIsEqual(currentRec, rec);
  });
  if (siblingRecs.length === 1) {
    return siblingRecs;
  }
  return reorderRecs(siblingRecs);

}

// precondition for siblingNode functions is that orderedSiblings must already have been ordered according
// to rec ordering formula
// note: this will return the actual node existing on the page at this point in time
// or undefined if there isn't one
// note we need to additionally consider if the targeted sibling is wrapped with a wrapper div and return that wrapper div
// instead of the recommendation node itself
// precondition for this fn is that applyRecommendationFormFactorProperties has already been called on the incoming orderedSiblings
function getSiblingNode(orderedSiblings, index) {
  let siblingRec = orderedSiblings[index];
  let ids = getPszIds(siblingRec);
  let siblingNode = siblingRec.wrapperDivClasses ? document.querySelector(`#${ids.pszWrap}`) : document.querySelector(`#${ids.psz}`);
  return siblingNode;
}

// precondition for this fn is that applyRecommendationFormFactorProperties has already been called on the incoming orderedSiblings
export function getPrevSiblingNode(currentIndex, orderedSiblings) {
  let index = currentIndex - 1;
  while (index > -1) {
    let siblingNode = getSiblingNode(orderedSiblings, index);
    if (siblingNode) {
      return siblingNode;
    }
    index--;
  }
}

// precondition for this fn is that applyRecommendationFormFactorProperties has already been called on the incoming orderedSiblings
export function getNextSiblingNode(currentIndex, orderedSiblings) {
  let index = currentIndex + 1;
  while (orderedSiblings.length > index) {
    let siblingNode = getSiblingNode(orderedSiblings, index);
    if (siblingNode) {
      return siblingNode;
    }
    index++;
  }
}

export async function $getOrderedTargetNode(rec, targetNode) {
  // note we currently only allow ordering on before or after
  function getSiblingFn(rec) {
    // before case: get the next closest sibling after current target and make new targetNode
    // after case: get prev closest sibling before current target and make new targetNode
    // in either case if there isn't another sibling already on the page just perform default targetNode behavior
    if (rec.insertKind) {

      if (rec.insertKind === 'before') {
        return getNextSiblingNode;
      } else if (rec.insertKind === 'after') {
        return getPrevSiblingNode;
      }
    } else {
      return rec.insertBefore ? getNextSiblingNode : getPrevSiblingNode;
    }
  }

  let orderedSiblings = getOrderedSiblingRecs(rec, await $getRecsWithContext());
  if (orderedSiblings.length > 1) {
    let currentIndex = orderedSiblings.findIndex(sibling => sibling.external === rec.external);

    let siblingFn = getSiblingFn(rec);
    if (siblingFn) {
      let siblingNode = siblingFn(currentIndex, orderedSiblings);
      if (siblingNode) {
        rec.logger.debug(`${rec.title} reassigning targetNode due to ordering: ${rec.order}`);
        targetNode = siblingNode;
      }
    }
  }

  return targetNode;
}


export async function $insertPsz(rec, targetNode, elem) {

  targetNode = await $getOrderedTargetNode(rec, targetNode);

  if (rec.wrapperDivClasses) {

    let ids = getPszIds(rec);
    let pszWrap = createElement({ id: ids.pszWrap, className: rec.wrapperDivClasses });
    pszWrap.append(elem.psz);

    // deal with in-place replacement, typically for editor, maybe for SPA?
    let oldPszWrap = document.querySelector(`#${ids.pszWrap}`) || document.querySelector(`#${ids.psz}`);
    if (oldPszWrap) {
      const parentNode = oldPszWrap.parentNode;
      parentNode.replaceChild(pszWrap.getElement(), oldPszWrap);
    }
    else {
      pszWrap.insert(targetNode, rec.insertBefore, rec.insertKind);
    }

  } else {
    elem.psz.insert(targetNode, rec.insertBefore, rec.insertKind);
  }

  rec.trigger('load');
  // be careful on awaiting this one since it could be the case that a concrete activateRatings function
  // never finishes waiting (i.e. it waits for something that is never available)
  if (rec.showProductRating) $activateRatings(rec);

}

export const widgets = {
  grid: $renderGrid,
  list: $renderList,
  slider: $renderSlider,
  hyper: $renderHyper
};