import Logger from './logger';
import {
  getDomObserver, $waitForWindowObject, getBaseUrl, getLocation, getPostmessageApiUrl,
  loadCss, loadScript, inEditor, inProxyFrame, $waitFor
} from './utils';
import { $getCommonParams, $reportEvents, $sendError } from './reporting';
import {
  $applyGlobalCss, applyRecommendCss, $getTargetNode,
  $initRender, $renderRecommendations, $renderRecommendation, renderRecommendation,
  applyRecommendationFormFactorProperties, clearRecsWithContext
} from './recommendation';
import constants from './constants';
import { $addInteractionEvent, $getMostRecentCategories, $query } from './history';
import { $getSiteId, $getCurrentPage } from './data';
import { $getLocations } from './customJs';

const CORE_VERSION = 20230322;

const logger = new Logger();

function loadAssets(baseUrl) {
  loadScript(baseUrl + '/swiper-bundle.min.js');
  if (window.coreObvCss === true) {
    loadCss(baseUrl + '/core-obv.css');
  } else if (window.basicThemeCss === true) {
    loadCss(baseUrl + '/basic-theme.css');
  } else {
    loadCss(baseUrl + '/core.css');
  }
}

async function loadNewAnalytics() {
  let siteId = await $getSiteId();
  // kEJla6tC = baristalab
  if (siteId === 'AH0NJrkU' || siteId === 'kEJla6tC') {
    loadScript(`//cdn.obviyo.net/lib/obv-api.js?pid=${siteId}-2`, true);
    loadScript(`//cdn.obviyo.net/lib/obv-analytics.js?pid=${siteId}-2`, true);
  }
}

async function $setupEditorServices() {
  let loaded = false;
  window.addEventListener('load', () => {
    loaded = true;
  });
  const funcName = 'HicPostMessageApi';
  loadScript(getPostmessageApiUrl());
  await $waitForWindowObject(funcName);
  const api = window[funcName]();
  const loc = getLocation();
  const pageClass = await $getCurrentPage();
  api.call('onReady', { location: loc, pageClass });
  await $waitFor(() => {
    return loaded;
  });
  api.call('onLoad');
}

function isHyperRunning() {
  return window['_obv_hyper'] === true;
}

function run() {
  if (!window.fetch) {
    return Promise.reject(new Error('browser does not support fetch'));
  }

  if (!window.navigator || !window.navigator.sendBeacon) {
    return Promise.reject(new Error('browser does not support sendBeacon'));
  }

  if (typeof window.URLSearchParams !== 'function') {
    return Promise.reject(new Error('browser does not support URLSearchParams'));
  }

  if (!window.Object && !window.Object.assign) {
    return Promise.reject(new Error('browser does not support Object.assign'));
  }

  const baseUrl = getBaseUrl();
  if (!baseUrl) {
    return Promise.reject(new Error('could not determine a base url'));
  }

  if (window[constants.TAG_RUNNING_KEY]) {
    logger.debug('already running');
    return Promise.resolve();
  }

  window[constants.TAG_RUNNING_KEY] = true;

  loadAssets(baseUrl);

  // loadNewAnalytics();

  let domObserver = getDomObserver();

  if (inEditor() || logger.getDebugMode()) {
    window.hc_psz = {
      applyRecommendationFormFactorProperties,
      $renderRecommendation,
      // TODO: deprecated, needs to be removed
      renderRecommendation,
      applyRecommendCss,
      $getCommonParams,
      $getTargetNode,
      $getMostRecentCategories,
      $addInteractionEvent,
      $query,
      $applyGlobalCss,
      $renderRecommendations,
      clearRecsWithContext,
      domObserver,
      $getLocations,
      __version: CORE_VERSION,
    };
    logger.debug('injecting personalize services');
    if (inProxyFrame() && !isHyperRunning()) {
      return Promise.all([$setupEditorServices()]);
    } else if (inEditor()) {
      return Promise.resolve();
    }
  }
  // individual functions here must have separate try/catches so that they don't prevent one another from completing
  return Promise.all([$initRender(), $reportEvents()]);
}

run().catch(err => {
  logger.error(err);
  $sendError('ce', err);
});