import { getPszIds, $getPszClassNames, getDevice, getValue, htmlToElement } from '../utils';
import Logger from '../logger';
import { PSZ_ITEMS_CLASS, createShowNavNode, setDefaultTitleNode, $parseItems, setMoreItemsLogicV2 } from './widget';

const logger = new Logger();
const DEFAULT_ROWS = { desktop: 1, mobile: 2 };
const DEFAULT_COLS = { desktop: 4, mobile: 2 };



export async function $parse(inParams, widgetNode, itemData) {

  let device = getDevice(inParams);
  let items = itemData.items;
  let ids = getPszIds(inParams);
  let classNames = await $getPszClassNames(inParams, ['hc-psz-grid']);
  let rid = getValue(itemData.rid, null);
  let theme = getValue(inParams.themeColor, 'light');
  let cols = getValue(inParams.gridCols, DEFAULT_COLS[device]);
  let rows = getValue(inParams.gridRows, DEFAULT_ROWS[device]);
  let showTotal = cols * rows;
  let show = { count: showTotal, step: showTotal };
  let appendNav = (showTotal <= items.length && inParams.navShow === 'more') ? true : false;

  // let params = {
  //   pszItemsHtml: {
  //     rec: rec,
  //     widget: 'grid',
  //     className: 'hc-psz-item-grid',
  //     show: show.count,
  //   }
  // };


  setDefaultTitleNode(widgetNode);
  // still need to do some kind of contextual substitution for current category or other data types
  // this would probably be a separate step though
  

  let itemNode = widgetNode.querySelector('item');

  let pszNode = htmlToElement(
    `<div id="${ids.psz}" class="${classNames.join(' ')}" data-rid="${rid}" data-hc-psz-theme-color="${theme}"/>`
  );

  let pszItemsNode = htmlToElement(
    `<div id="${ids.pszItems}" class="${PSZ_ITEMS_CLASS}" data-hc-psz-cols="${cols}" data-hc-psz-rows="${rows}"/>`
  );
  
  inParams.show = show.count;

  let itemNodes = await $parseItems(items, itemNode, inParams);
  
  for (let itemNode of itemNodes) {
    pszItemsNode.appendChild(itemNode);
  }

 
  itemNode.parentNode.replaceChild(pszItemsNode, itemNode);

  pszNode.replaceChildren(...widgetNode.children);


  if (appendNav) {
    let pszShowNavNode = createShowNavNode();
    pszNode.appendChild(pszShowNavNode);
    setMoreItemsLogicV2(pszNode, show);
  }

  return pszNode;
}
