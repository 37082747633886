import { getMoneyFormat } from '../data';
import { formatMoney } from '../utils';
import { shopifyCart } from './cart';
import { $applyCustomFn } from '../customJs';

// TODO: convert to vanilla js, convert to async fetch

let getNode = (selector) => {
    let node = document.querySelector(selector);
    node = (node) ? node : document.createElement('div');
    return {
        click: () => node.click(),
        addClass: (klass) => node.classList.add(klass),
        removeClass: (klass) => node.remove(klass),
        show: () => node.style.display = 'block',
        hide: () => node.style.display = 'hide',
    };
};


// TODO: We shouldn't have this layer of indirection, we already have the minicart types below
// we should just have the open there. Only reason to have separate function is if multiple of the same types
// have the same way to open and so should share a simple function. Will help us to avoid deep if/else
// same with closeMinicart function below
function openMinicart(mcType, rec) {

    let open = () => { };

    if (mcType === 'getMinicartHandleBars') {
        open = () => {
            let openSelector = "a[aria-controls=CartDrawer]";
            getNode(openSelector).click();
        };
    } else if (mcType === 'getMinicartAjaxFrag') {
        open = () => {
            let openSelector = "#js-cart-dropdown-link.js-cart-dropdown-link";
            getNode(openSelector).click();
        };
    } else if (mcType === 'getMinicartAjax') {
        open = () => {
            if (window.innerWidth > 1024) {

                getNode('#CartContainer').addClass('opened');
                setTimeout(function () {
                    getNode('#CartContainer').removeClass('opened');
                }, 3000);

            } else {
                getNode('selector').show();
                setTimeout(function () {
                    getNode('selector').hide();
                }, 5000);
            }
        };
    }

    $applyCustomFn({ $fn: open, rec, refName: 'openMinicart' });

}

function closeMinicart(mcType, rec) {

    let close = () => { };

    if (mcType === 'getMinicartHandleBars') {
        close = () => {
            let closeSelector = "#CartDrawer .js-drawer-close";
            getNode(closeSelector).click();
        };
    } else if (mcType === 'getMinicartAjaxFrag') {
        close = () => {
            let closeSelector = ".js-cart-dropdown-close";
            getNode(closeSelector).click();
        };
    }

    $applyCustomFn({ $fn: close, rec, refName: 'closeMinicart' });

}


function getMinicartHandleBars(rec) {

    var $ = window.jQuery;

    let open = () => openMinicart('getMinicartHandleBars', rec);
    let close = () => closeMinicart('getMinicartHandleBars', rec);

    let cartTemplateSelector = "script#cartTemplate, script#CartTemplate";

    let canUpdate = () => {
        return (window.Handlebars && document.querySelector(cartTemplateSelector)) ? true : false;
    };

    let formatCartData = (cart) => {

        let moneyFormat = getMoneyFormat();
        let items = [];
        cart.items.forEach(function (cartItem, index) {

            let prodImg = (cartItem.image !== null) ? cartItem.image.replace(/(\.[^.]*)$/, '_small$1').replace('http:', '') : '';
            let item = {
                key: cartItem.key,
                line: index + 1,
                url: cartItem.url,
                img: prodImg,
                name: cartItem.product_title,
                variation: cartItem.variant_title,
                properties: cartItem.properties,
                itemAdd: cartItem.quantity + 1,
                itemMinus: cartItem.quantity - 1,
                itemQty: cartItem.quantity,
                price: formatMoney(cartItem.original_line_price, moneyFormat),
                discountedPrice: formatMoney(cartItem.final_line_price, moneyFormat),
                discounts: cartItem.line_level_discount_allocations,
                discountsApplied: cartItem.line_level_discount_allocations.length === 0 ? false : true,
                vendor: cartItem.vendor
            };
            items.push(item);

        });

        var data = {
            items: items,
            note: cart.note,
            subTotalPrice: formatMoney(cart.items_subtotal_price, moneyFormat),
            totalPrice: formatMoney(cart.total_price, moneyFormat),
            cartTotalDiscounts: formatMoney(cart.total_discount, moneyFormat),
            cartDiscounts: cart.cart_level_discount_applications,
            cartDiscountsApplied: cart.cart_level_discount_applications.length === 0 ? false : true,
            cartTotalSavings: cart.cart_level_discount_applications.length === 0 && cart.total_discount > 0
        };

        return data;

    };

    let cart = shopifyCart();

    let $updateItemQuantity = async (line, quantity) => {
        let formData = { line: line, quantity: quantity };
        let responseJson = await cart.$change(formData);
        $updateMinicartHtml(responseJson, '$updateItemQuantity');
    };

    let $syncMinicartLinks = async () => {

        let itemRowsSelector = '.ajaxcart__product, .ajaxifyCart--product';
        let itemRows = document.querySelectorAll(itemRowsSelector);
        if (itemRows.length !== 0) {
            itemRows.forEach(function (itemRow, index) {

                let line = index + 1;

                let setListener = (selector, listener, cb) => {
                    let node = itemRow.querySelector(selector);
                    if (node) {
                        node.addEventListener(listener, function (event) { cb(event) });
                    }
                };

                let qtyInputSelector = 'ajaxifyCart--num, .js-qty__num';
                let qtyInput = itemRow.querySelector(qtyInputSelector);
                let qty = (qtyInput) ? parseInt(qtyInput.value) : 0;
                setListener(qtyInputSelector, 'change', function () {
                    $updateItemQuantity(line, qty);
                });

                let qtyAddSelector = '.ajaxifyCart--qty-adjuster.ajaxifyCart--add, .js-qty__adjust.js-qty__adjust--plus';
                setListener(qtyAddSelector, 'click', function () {
                    $updateItemQuantity(line, qty + 1);
                });

                let qtySubSelector = '.ajaxifyCart--qty-adjuster.ajaxifyCart--minus, .js-qty__adjust.js-qty__adjust--minus';
                setListener(qtySubSelector, 'click', function () {
                    $updateItemQuantity(line, qty - 1);
                });

                // let removeButtonSelector = '.ajaxifyCart--remove';
                // setListener(removeButtonSelector, 'click', function (event) {
                //     event.preventDefault();
                //     $updateItemQuantity(line, 0);
                // });

            });
        }
    };

    let $getMinicartHtml = async (cart) => {

        let cartData = await $applyCustomFn({
            $fn: formatCartData,
            fnParams: [cart],
            refName: 'formatMinicartData',
            rec,
            additionalCtx: { cart, getMoneyFormat, formatMoney }
        });

        let templateHtml = document.querySelector(cartTemplateSelector).innerHTML;
        let template = window.Handlebars.compile(templateHtml);
        let minicartHtml = template(cartData);
        return minicartHtml;

    };

    let $updateMinicartHtml = async (cart, triggerFn) => {

        let minicartHtml = await $getMinicartHtml(cart);
        let minicartDom = document.querySelector("#CartContainer");

        if (minicartDom && minicartHtml) {
            minicartDom.innerHTML = minicartHtml;
            $applyCustomFn({
                $fn: $syncMinicartLinks,
                refName: 'syncMinicartLinks',
                rec,
                additionalCtx: { $updateItemQuantity }
            });
            if (triggerFn === '$update') open();
        }

    };

    let $update = async (cartResponse) => {
        if (canUpdate()) {
            let responseJson = await cart.$get();
            $updateMinicartHtml(responseJson, '$update');
        }
    };

    return {
        canUpdate,
        $update,
        open,
        close,
    };

}

function getMinicartAjaxFrag(rec) {

    var $ = window.jQuery;

    let openSelector = "#js-cart-dropdown-link.js-cart-dropdown-link";
    let closeSelector = ".js-cart-dropdown-close";
    let open = () => openMinicart('getMinicartAjaxFrag', rec);
    let close = () => closeMinicart('getMinicartAjaxFrag', rec);

    var fragmentHtml;
    function renderAjaxHtml(selector, html) {

        if ($(selector).length > 0) {
            $(selector).each(function (index) {
                fragmentHtml = html.find(selector).eq(index).find('.js-ajax-content');
                $(this).find('.js-ajax-loader').html(fragmentHtml);
            });
        }

    }
    let canUpdate = () => {
        if (document.querySelectorAll("[data-ajax-fragment=cart]").length !== 0 && $(openSelector).length && $(closeSelector).length) {
            return true;
        }
        return false;
    };

    let $update = async () => {
        // TODO: update minicart quantity when minicart closed
        return $.get('/pages/ajax?view=cart-dropdown', function (mcHtml) {

            var cartDropdownAjaxHtml = $(mcHtml);
            var fragment_names = ['cart', 'elite-subscribe-buttons', 'cart-items-table', 'cart-summary', 'free-shipping-upsell'];

            fragment_names.forEach(function (fragment_name) {
                var selector = '[data-ajax-fragment="' + fragment_name + '"]';
                renderAjaxHtml(selector, cartDropdownAjaxHtml);
            });

            open();
            getNode('.bundle-modal-overlay').hide();
            $(window).trigger('ajaxLoaded');

        });
    };

    return {
        canUpdate,
        $update,
        open,
        close,
    };

}


function getMinicartAjax(rec) {

    let open = () => openMinicart('getMinicartAjax', rec);
    let close = () => closeMinicart('getMinicartAjax', rec);

    let canUpdate = () => {
        return (window.ajaxCart && window.ajaxCart.load) ? true : false;
    };

    let $update = async () => {
        window.ajaxCart.load();
        if (rec.setMinicartType === 'ajax') {
            setTimeout(open, 500);
        }
    };

    return {
        canUpdate,
        $update,
        open,
        close,
    };
}

function getMinicartWeTheme(rec) {

    let cart = shopifyCart();

    let open = () => { };
    let close = () => { };

    let canUpdate = () => {
        return (window.wetheme && window.wetheme.toggleRightDrawer) ? true : false;
    };

    let $update = async () => {
        let json = await cart.$get();
        window.wetheme.toggleRightDrawer('cart', 1, { cart: json });
    };

    return {
        canUpdate,
        $update,
        open,
        close,
    };
}


export function getMinicart(rec) {

    let minicart = false;
    let minicartTypes = {
        ajax: getMinicartAjax(rec),
        ajaxFrag: getMinicartAjaxFrag(rec),
        handleBars: getMinicartHandleBars(rec),
        weTheme: getMinicartWeTheme(rec)
    };

    let getMinicartType = () => {

        if (rec.setMinicartType === 'ajax') {
            let mc = minicartTypes['ajax'];
            if (mc.canUpdate()) {
                minicart = mc;
            }
        } else {
            for (var x = 0; x < Object.keys(minicartTypes).length; x++) {
                let mcKey = Object.keys(minicartTypes)[x];
                let mc = minicartTypes[mcKey];
                if (mc.canUpdate()) {
                    minicart = mc;
                    break;
                }
            }
            return minicart;
        }

    };

    let canUpdate = () => {
        return (getMinicartType() !== false) ? true : false;
    };

    let $update = async () => {
        if (minicart === false) getMinicartType();
        if (minicart !== false) minicart.$update();
    };

    return {
        canUpdate,
        $update,
        open,
        close,
    };

}
