import { isEmptyObject, objectsAreEqual } from './utils';
import { $getCartItems, $getObviyoApi } from './data';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';
import constants from './constants';
const HISTORY_KEY = '_hc_psz_d';

function getHistory() {
  let historyObj = {};
  let historyString = localStorage.getItem(HISTORY_KEY);
  if (historyString) {
    try {
      historyObj = JSON.parse(historyString);
    } catch (ex) {
      historyObj = JSON.parse(decompressFromUTF16(historyString));
    }
  }

  return historyObj;
}

function saveHistory(historyObj) {
  if (!isEmptyObject(historyObj)) {
    let historyString = JSON.stringify(historyObj);
    localStorage.setItem(HISTORY_KEY, compressToUTF16(historyString));
  }
}

function cleanEvent(params) {
  delete params.siteId;
  delete params.vid;
  delete params.sid;
  delete params.segs;
  delete params.qa;

  return params;
}

export async function $addInteractionEvent(params) {
  const obvApi = await $getObviyoApi();
  if (obvApi) return obvApi.$storeEvent(params);

  let historyObj = getHistory();
  if (!historyObj.e) {
    historyObj.e = [];
  }
  let paramsCopy = Object.assign({}, params);
  paramsCopy = cleanEvent(paramsCopy);
  historyObj.e.unshift(paramsCopy);
  saveHistory(historyObj);
}

export async function $query(params) {
  const obvApi = await $getObviyoApi();
  if (obvApi) return await obvApi.$queryStore(params);

  let historyObj = getHistory();
  let results = [];

  if (historyObj.e) {
    results = historyObj.e.slice();
  }
  if (params.where) {
    let where = params.where;

    for (const clause of where) {
      results = results.filter(e => {
        const val = e[clause.key];
        if (clause.operator === '=') {
          return val === clause.value;
        } else if (clause.operator === '!=') {
          return val !== clause.value;
        } else if (clause.operator === '>') {
          return val > clause.value;
        } else if (clause.operator === '<') {
          return val < clause.value;
        } else if (clause.operator === 'in') {
          return clause.value.indexOf(val) > -1;
        }
      });
    }

  }

  if (params.select) {
    let select = params.select;
    let projectedResults = [];
    for (const result of results) {
      let projectedResult = {};
      for (const projection of select) {
        if (typeof result[projection] != 'undefined') {
          projectedResult[projection] = result[projection];
        }
      }
      if (!isEmptyObject(projectedResult)) {
        projectedResults.push(projectedResult);
      }
    }
    results = projectedResults;
  }

  if (params.orderBy === 'asc') {
    results = results.reverse();
  }

  if (params.distinct === true) {
    let distinctResults = [];
    for (const result of results) {
      let found = false;
      for (const distinctResult of distinctResults) {
        if (objectsAreEqual(distinctResult, result)) {
          found = true;
          break;
        }
      }
      if (!found) {
        distinctResults.push(result);
      }
    }
    results = distinctResults;
  }

  if (params.limit) {
    results = results.slice(0, params.limit);
  }

  return results;
}

async function $getMostRecentItems(event, num, matchingList) {
  let queryObj = {
    where: [
      { key: 'type', operator: '=', value: event }
    ],
    select: ['item'],
    distinct: true
  };
  if (num) {
    queryObj.limit = num;
  }
  if (matchingList) {
    queryObj.where.push({ key: 'item', operator: 'in', value: matchingList });
  }
  const result = await $query(queryObj);
  return result.map(item => {
    return item.item;
  });
}

export async function $getMostRecentCartItems(num) {
  let items = await $getCartItems();
  let itemIds = items && items.map(item => item.id);
  if (!itemIds || !itemIds.length) return [];
  return await $getMostRecentItems(constants.ADDED_TO_CART_EVENT, num, itemIds);
}

export async function $getMostRecentCategories(num) {
  return await $getMostRecentItems(constants.CATEGORY_VIEWED_EVENT, num);
}

export async function $getMostRecentPurchasedItems(num) {
  return await $getMostRecentItems(constants.PURCHASED_EVENT, num);
}

export async function $getMostRecentViewedItems(num) {
  return await $getMostRecentItems(constants.VIEWED_EVENT, num);
}

async function $getSessionStartTime() {
  let queryObj = {
    where: [
      { key: 'type', operator: '=', value: 'new-ses' }
    ],
    select: ['ts'],
    distinct: true,
    limit: 1
  };
  const result = await $query(queryObj);
  return result && result.length && result[0].ts;
}

export async function $getSessionPlayCount(id) {
  let startTime = await $getSessionStartTime();
  let queryObj = {
    where: [
      { key: 'type', operator: '=', value: 'widget-viewed' },
      { key: 'item', operator: '=', value: id },
      { key: 'ts', operator: '>', value: startTime }
    ],
    select: ['item']
  };
  const result = await $query(queryObj);
  return (result && result.length) || 0;
}