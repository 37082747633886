import Logger from '../logger';
import { $waitFor, loadCss, loadScript } from '../utils';
const logger = new Logger();
export async function $renderHyper(rec, itemData, targetNode) {

  loadScript('http://localhost:8000/lib/obv-components.pre.js');

  loadCss('http://localhost:8000/lib/obv-components.css');

  await $waitFor(()=> !!window.obviyo);
  const obv = window.obviyo.components.default;

  obv.render(obv.hson(rec.tpl), targetNode);

}
