const LOCAL_API_URL = 'https://h40-local.hiconversion.net:3000';
const DEMO_API_URL = 'https://go-demo.hiconversion.net';
const PROD_API_URL = 'https://go.hiconversion.net';

const POSTMESSAGE_API_PATH = '/static/postmessage.js';

export default {
  ADDED_TO_CART_EVENT: 'addedtocart',
  OBV_LOADER_SCRIPT_SELECTOR: 'script[src*="//cdn.obviyo.net/dynamic/loader/"]',
  ARRIVAL_PAGE_CLASS_KEY: '_hc_psz_apc',
  API_URL_KEY: '_hc_psz_api_url',
  BASE_REC_URL_V1_PREVIEW:'https://hic-personalize.hiconversion.net',
  BASE_REC_URL_V2:'https://api.obviyo.net/recommend/qn',
  BASE_STG_REC_URL: 'https://hic-personalize.hiconversion.net/staging',
  BASE_PSZ_URL: 'https://h30-deploy.obviyo.net/origin/personalize',
  BASE_S3_URL: 'https://h30-deploy.s3.amazonaws.com/origin/personalize',
  CATEGORY_VIEWED_EVENT: 'cat-viewed',
  CLICKED_EVENT: 'clicked',
  DEMO_API_URL,
  DEMO_POSTMESSAGE_API_URL: DEMO_API_URL + POSTMESSAGE_API_PATH,
  DEMO_PSZ_PATH: '/demo',
  EVENT_URL: 'https://hic-putevents.hiconversion.net',
  H30_SCRIPT_SELECTOR: 'script[src*="//h30-deploy.hiconversion.com/origin/tag/"]',
  LOCAL_API_URL,
  LOCAL_POSTMESSAGE_API_URL: LOCAL_API_URL + POSTMESSAGE_API_PATH,
  LOCAL_PROXY_HOSTNAME: 'hic-proxy-local.hiconversion.net',
  LOCALHOST_COOKIE_NAME: '_hc_local',
  MINICART_PLACE: 'MCRT',
  MINIMUM_ITEM_THRESHOLD: 1,
  OBV_ANALYTICS_TEST_FLAG: '_obv_analytics_test',
  POSTMESSAGE_API_PATH,
  PROD_API_URL,
  PROD_POSTMESSAGE_API_URL: PROD_API_URL + POSTMESSAGE_API_PATH,
  PROD_PSZ_PATH: '/prod',
  // PRODUCT_DATA_URL_V1: 'https://hic-simplerec.hiconversion.net/product',
  PROXY_HOSTNAME: 'hic-proxy.hiconversion.net',
  PSZ_DEBUG_KEY: '_hc_psz_debug',
  PSZ_REC_PATH_KEY: '_hc_psz_rec_path',
  PSZ_GET_ITEM_STG: '_hc_psz_get_item_stg',
  PSZ_TAG_ALT_KEY: '_hc_psz_tag_alt',
  PURCHASED_EVENT: 'purchased',
  RATED_EVENT: 'rated',
  STATIC_PATH: '/static',
  STORED_ITEMS_KEY: '_hc_psz_ci',
  TAG_RUNNING_KEY: '_hc_psz_tag_r',
  TEST_PSZ_PATH: '/test',
  VIEWED_EVENT: 'viewed',
  ITEM_SHOW_CLASS: 'hc-psz-item-show',
  ITEM_HIDE_CLASS: 'hc-psz-item-hide'
};
