import { enableScroll, getDevice, createElement} from '../utils';
import { renderIcon } from '../icons';
import { $renderHtml } from "../recommendation";
import Logger from '../logger';

const logger = new Logger();

export async function $renderPopup(rec, itemData, targetNode) {

    let device = getDevice(rec);

    let params = {
        popupBackground: {
            className: 'hc-psz-popup-cover',
        },
        popupExit: {
            className: 'hc-psz-popup-exit'
        },
        popupPszWrap: {
            className: 'hc-psz-popup-psz-wrap'
        },
        popupWrap: {
            className: ['hc-psz-popup-wrap', 'hc-psz-' + device]
        },
    };

    function exitPopup(){
        enableScroll(true);
        elem.popupCover.remove();
        elem.popupWrap.remove();
    }

    let elem = {
        popupCover: createElement(params.popupBackground),        
        popupExit: createElement(params.popupExit),
        popupPszWrap: createElement(params.popupPszWrap),
        popupWrap: createElement(params.popupWrap),
    };

    let exitIcon = renderIcon('times');
    elem.popupExit.append(exitIcon);
    elem.popupWrap.append([elem.popupExit, elem.popupPszWrap]);
    elem.popupWrap.appendTo("body");
    elem.popupCover.appendTo("body");

    elem.popupExit.onClick(exitPopup);    
    elem.popupCover.onClick(exitPopup);
    
    rec.insertBefore = false;
    targetNode = elem.popupPszWrap.getElement();
    let res = await $renderHtml(rec, itemData, targetNode);
    if (res === false) {
        return res;
    }

    enableScroll(false);

}
