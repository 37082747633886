
import {
  $waitForWindowObject, getClosest, getDevice, $getPszClassNames, getPszIds,
  getRootSelector, getValue, htmlToElement
} from '../utils';
import { $sendClickEvent, triggerWidgetUpdatedEvent } from '../reporting';
import Logger from '../logger';
import { PSZ_ITEMS_CLASS, $parseItems, setDefaultTitleNode } from './widget';

const logger = new Logger();

const DEFAULT_ITEM_WIDTH = { desktop: '200px', mobile: '100px' };
const DEFAULT_ITEMS_PER_VIEW = { desktop: 4, mobile: 2 };

const DEFAULT_SPACE_BETWEEN = 25;

const NAV_PREV_CLASS = 'swiper-button-prev';
const NAV_NEXT_CLASS = 'swiper-button-next';

function isCorrectFormat(value) {
  var format = /(\d+re|\d+em|\d+%|\d+px|\d+cm|\d+mm|\d+in|\d+pt|\d+pc|\d+ch|\d+rem|\d+vh|\d+vw|\d+vmin|\d+vmax)/g;
  var check = value.match(format);
  return (check !== null) ? true : false;
}

function getElementSize(sel, type) {

  if (!sel && !type) return false;

  let elem = document.querySelector(sel);
  if (!elem) return false;

  let style = window.getComputedStyle(elem);
  let sumSize = (a, b) => parseInt(style[a]) + parseInt(style[b]);

  if (type === 'paddingWidth' || type === 'innerWidth()') return elem.clientWidth;
  if (type === 'paddingHeight' || type === 'innerHeight()') return elem.clientHeight;
  if (type === 'borderWidth' || type === 'outerWidth()') return elem.offsetWidth;
  if (type === 'borderHeight' || type === 'outerHeight()') return elem.offsetHeight;
  if (type === 'contentWidth' || type === 'width()') return elem.clientWidth - sumSize('paddingLeft', 'paddingRight');
  if (type === 'contentHeight' || type === 'height()') return elem.clientHeight - sumSize('paddingTop', 'paddingBottom');
  if (type === 'marginWidth' || type === 'outerWidth(true)') return elem.offsetWidth + sumSize('marginLeft', 'marginRight');
  if (type === 'marginHeight' || type === 'outerHeight(true)') return elem.offsetHeight + sumSize('marginTop', 'marginBottom');

}

export async function $parse(inParams, widgetNode, itemData) {

  let device = getDevice(inParams);
  let items = itemData.items;
  let ids = getPszIds(inParams);

  let classNames = await $getPszClassNames(inParams, ['hc-psz-slider']);

  let rid = getValue(itemData.rid, null);
  let theme = getValue(inParams.themeColor, 'light');
  let navStyle = getValue(inParams.navStyle, 'rectArrows');
  let navLoc = "hc-psz-nav-inside";
  if (inParams.navLocation && inParams.navLocation === 'outside') {
    navLoc = "hc-psz-nav-outside";
  }
  let itemsPerView;
  let itemsPerSlide;
  let spaceBetween = typeof inParams.sliderSpaceBetween !== 'undefined' ? inParams.sliderSpaceBetween : DEFAULT_SPACE_BETWEEN;
  let centerInsufficientSlides = typeof inParams.centerInsufficientSlides !== 'undefined' ? inParams.centerInsufficientSlides : false;
  let style = '';
  // TODO: Right now we have rec.productsPerView, and rec.productWidth, should probably be converted to items
  let mode = (inParams.productsPerView === 0 && typeof inParams.productWidth === 'string') ? 'fixedWidth' : 'fixedSlides';

  if (mode === 'fixedWidth') {
    let defaultItemWidth = DEFAULT_ITEM_WIDTH[device];
    let itemWidth = (isCorrectFormat(inParams.productWidth)) ? inParams.productWidth : defaultItemWidth;
    style += 'width: ' + itemWidth + ' ';
    itemsPerView = 'auto';
    itemsPerSlide = 1;
  }

  if (mode === 'fixedSlides') {
    let defaultItemsPerView = DEFAULT_ITEMS_PER_VIEW[device];
    itemsPerView = getValue(inParams.productsPerView, defaultItemsPerView);
    itemsPerSlide = Math.floor(itemsPerView);
  }

  setDefaultTitleNode(widgetNode);
 
  let pszNavPrevNode = htmlToElement(
    `<div class="${['hc-psz-nav', 'hc-psz-nav-prev', NAV_PREV_CLASS].join(' ')}"/>`
  );

  let pszNavNextNode = htmlToElement(
    `<div class="${['hc-psz-nav', 'hc-psz-nav-next', NAV_NEXT_CLASS].join(' ')}"/>`
  );

  let itemNode = widgetNode.querySelector('item');

  let pszNode = htmlToElement(
    `<div id="${ids.psz}" class="${classNames.join(' ')}" data-rid="${rid}" data-hc-psz-theme-color="${theme}"/>`
  );

  let pszItemsNode = htmlToElement(
    `<div id="${ids.pszItems}" class="${[PSZ_ITEMS_CLASS, 'swiper-wrapper'].join(' ')}"/>`
  );

  inParams.show = items.length;
  inParams.style = style;
  inParams.className = 'swiper-slide';

  let itemNodes = await $parseItems(items, itemNode, inParams);
  
  for (let itemNode of itemNodes) {
    pszItemsNode.appendChild(itemNode);
  }

  let pszSliderViewportNode = htmlToElement(
    `<div class="${['hc-psz-slider-viewport', 'swiper-container'].join(' ')}"/>`
  );

  let pszSliderWrapNode = htmlToElement(
    `<div class="${['hc-psz-slider-wrap', navLoc].join(' ')}" data-hc-psz-nav-style="${navStyle}"/>`
  );

  pszSliderViewportNode.append(pszItemsNode);

  pszSliderWrapNode.append(pszNavPrevNode, pszSliderViewportNode, pszNavNextNode);
  
  itemNode.parentNode.replaceChild(pszSliderWrapNode, itemNode);

  pszNode.replaceChildren(...widgetNode.children);

  inParams.on('load', async () => {

    const swiper = await $waitForWindowObject('hcPszSwiper');
  
    // fix undefined new swiper()
    const swiperEl = pszSliderViewportNode;
    const existingSwiper = swiperEl && swiperEl.swiper;
    if (existingSwiper) {
      existingSwiper.destroy(true, true);
    }
  
    const mySwiper = new swiper(pszSliderViewportNode, {
      slidesPerView: itemsPerView,
      slidesPerGroup: itemsPerSlide,
      init: false,
      longSwipesRatio: .1,
      spaceBetween: spaceBetween,
      centerInsufficientSlides: centerInsufficientSlides,
      updateOnWindowResize: true,
      // observe and observeParents watch dom to set the correct widths for products, otherwise it shows 1 product, and style has no width set
      observer: true,
      observeParents: true,
      navigation: {
        prevEl: pszNavPrevNode,
        nextEl: pszNavNextNode
      },
      on: {
        click: async () => {
  
          const clickedSlide = mySwiper.clickedSlide;
          if (!clickedSlide) return;
  
          const pid = clickedSlide.getAttribute('data-pid');
          const rootDiv = getClosest(clickedSlide, '.hc-psz');
          if (!rootDiv) return;
          const rid = rootDiv.getAttribute('data-rid');
  
          await $sendClickEvent(pid, rid);
  
        },
        slideChangeTransitionEnd: () => { triggerWidgetUpdatedEvent() },
      },
    });
  
    let setSlidesPerGroup = (swiper, id) => {
  
      let sliderWrapSelector = "#" + id + " .hc-psz-items";
      let sliderWraperInnerWidth = getElementSize(sliderWrapSelector, 'contentWidth');
      let itemSelector = "#" + id + " .hc-psz-items .hc-psz-item";
      let itemOuterWidth = getElementSize(itemSelector, 'marginWidth');
      let totalFullSlides = Math.floor(sliderWraperInnerWidth / itemOuterWidth);
  
      swiper.params.slidesPerGroup = totalFullSlides;
      swiper.update();
  
    };
  
    if (mode === 'fixedWidth') {
      mySwiper.on('init', () => {
        setSlidesPerGroup(mySwiper, ids.psz);
      });
      mySwiper.on('resize', () => {
        setSlidesPerGroup(mySwiper, ids.psz);
      });
    }
    mySwiper.init();
  });

  return pszNode;

}