
import constants from './constants';
import Logger from './logger';
import { ACTIVE_VIS_STATE, getPreviewInfo, PROD_VIS_STATE } from './preview';
import { $getObviyoApi, $getSiteId, $getCartItems, $getCurrentPage, $isNewVisitor, getCurrentProductId } from './data';
import {
  $waitFor, applyCss, getIdFromGid, getHandle, convertObjectToUrlParams, shuffle,
  getEventListener, getUrlParam, inEditor, inShopifyEditor, getDevice, getDomObserver, getPath, getPathWithoutShopifyRoot, $setPszFlagsWithClassNames,
  arrayToMap
} from './utils';
import { getAppProxyUrl } from './utils-app-proxy';
import { setupImpressionsForRecommendation, $sendError } from './reporting';
import { DEFAULT_RECOMMENDATION_CASE, getItemDataMap, $getCommonParams, getNeedsPreviewData } from './template-handlers';
import { widgets } from './widgets/widget';
import { $renderPopup } from './widgets/popup';
import { $applyCustomFn, $getBaseCtx, $getCtxWith, $getLocation } from './customJs';
import { $parseTemplate } from './templateParser';
import { $waitForIntersection } from './intersectionObserver';
import { $renderHyper } from './hyper.js';
import { $addShopifyEditorEnhancements } from './shopifyEditor';
import { $getSessionPlayCount } from './history.js';

const logger = new Logger();

let dynamicRecData = null;
let domObserver = getDomObserver();

export function applyRecommendationFormFactorProperties(rec) {

  let device = getDevice(rec);
  rec.widget = (device === 'mobile') ? 'grid' : 'slider';

  if (rec.widgetOpts && rec.widgetOpts[device]) {
    rec = Object.assign({}, rec, rec.widgetOpts[device]);
  }

  return rec;
}

export async function $renderFn(rec, itemData, targetNode) {
  const $renderPsz = widgets[rec.widget];
  if (typeof $renderPsz === 'function') {

    if (!targetNode) {
      rec.logger.debug(`waiting for targetNode after psz removed`);
      targetNode = await $applyCustomFn({
        $fn: $getTargetNode,
        fnParams: [rec.location, null, rec],
        refName: 'getTargetNode',
        rec
      });
      if (targetNode === false) {
        rec.logger.debug('getTargetNode returned false after psz removed, exiting early');
        return targetNode;
      }

      rec.logger.debug(`found targetNode after psz removed`);
    }

    try {
      return await $renderPsz(rec, itemData, targetNode);
    }
    catch (e) {
      rec.logger.error(e);
      throw e;
    }

  } else {
    rec.logger.debug('unsupported widget type:', rec.widget);
  }
}

export async function $renderHtml(rec, itemData, targetNode) {

  let res = await $renderFn(rec, itemData, targetNode);

  if (res === false) {
    return res;
  }

  if (rec.showInPopup !== true) {

    let pszExists = () => {
      let pg = document.querySelector('body');
      let psz = document.querySelector(`#hc-psz-${rec.external}`);
      return pg.contains(psz);
    };
    let isRendering = false;

    domObserver.setCallback(rec.external, async function () {
      if (!isRendering && !pszExists()) {
        rec.logger.debug('rec not found, rerendering');
        isRendering = true;
        let res = await $renderFn(rec, itemData);
        isRendering = false;

        if (res === false) {
          domObserver.removeCallback(rec.external);
          return res;
        }

        await $applyCustomFn({
          $fn: async () => { },
          refName: 'postRender',
          rec,
          additionalCtx: {
            itemData
          }
        });
      }
    });

  }
}

function setUrlParamBoolean(rec, recParam, urlParam) {
  let urlPramValue = getUrlParam(urlParam);
  if (urlPramValue === 'true') {
    rec[recParam] = true;
  } else if (urlPramValue === 'false') {
    rec[recParam] = false;
  }
}

export async function $renderWidget(rec, itemData, targetNode) {

  await $setPszFlagsWithClassNames(rec);

  const widgetParam = getUrlParam('_hc_psz_widget');
  if (widgetParam === 'grid' || widgetParam === 'list' || widgetParam === 'slider') {
    rec.widget = widgetParam;
  }

  setUrlParamBoolean(rec, 'showProductAddToCart', '_hc_psz_show_product_add_to_cart');
  setUrlParamBoolean(rec, 'showProductSaleBadge', '_hc_psz_show_product_sale_badge');
  setUrlParamBoolean(rec, 'showProductVariantTitle', '_hc_psz_show_product_variant_title');


  const sliderModeParam = getUrlParam('_hc_psz_slider_mode');
  if (sliderModeParam === 'fixedWidth') {
    rec.productsPerView = 0;
    rec.productWidth = '100px';
  } else if (sliderModeParam === 'fixedSlides') {
    rec.productsPerView = 4;
    delete rec.productWidth;
  }

  const showProductPriceInAtc = getUrlParam('_hc_psz_show_product_price_in_atc');
  if (showProductPriceInAtc) {
    rec.showProductPriceInAtc = showProductPriceInAtc;
  }

  const imgRatio = getUrlParam('_hc_psz_img_ratio');
  if (imgRatio) {
    rec.imgRatio = imgRatio;
  }

  const imgFit = getUrlParam('_hc_psz_img_fit');
  if (imgFit) {
    rec.imgFit = imgFit;
  }

  applyRecommendCss(rec);

  const popupTestParam = getUrlParam('_hc_psz_show_popup');

  if (popupTestParam !== null && rec.external === popupTestParam || rec.showInPopup) {
    return await $renderPopup(rec, itemData, targetNode);
  } else {
    return await $renderHtml(rec, itemData, targetNode);
  }

}

/**
 * @param {Record<string, any>} recParams
 * @returns {string}
 */
export function getRecommendationItemDataUrlV2(recParams) {

  const val = localStorage.getItem(constants.PSZ_GET_ITEM_STG);
  if (val) {
    return constants.BASE_STG_REC_URL;
  }

  return constants.BASE_REC_URL_V2;

}

const SHUFFLE_QNAMES = [
  'psz-mpop',
  'psz-psel',
  'psz-mite',
  'psz-shbt',
  'psz-shbb',
  'psz-narr',
  'psz-4pls'
];

async function $legacyGetItemData(recommendation) {
  let $caseFn = getItemDataMap[recommendation.qualifiedName];

  if (!$caseFn) {
    $caseFn = getItemDataMap[DEFAULT_RECOMMENDATION_CASE];
  }

  let recParams = await $caseFn(recommendation);
  if (recParams === false) {
    return { items: [] };
  }

  let paramString = convertObjectToUrlParams(recParams);

  let response = await fetch(`${getRecommendationItemDataUrlV2(recParams)}?${paramString}`);
  let json = await response.json();
  // shuffle around the results for mpop rec
  // TODO: at some point we should probably have a flag to allow shuffling results for other recs as well
  // or move this to a different location
  if (SHUFFLE_QNAMES.indexOf(recommendation.qualifiedName) > -1 && json && json.items && json.items.length) {
    json.items = shuffle(json.items);
  }

  return json;
}

async function $getItemData(rec) {
  // return await $legacyGetItemData(rec);
  const obvApi = await $getObviyoApi();

  if (obvApi && obvApi.$getItemData) {
    return await obvApi.$getItemData(rec);
  } 

  return { items: [] };
}

async function $getRecommendationItemData(recommendation) {
  let itemData;
  if (recommendation.enrichmentQualifiedName) {
    let recCopy = Object.assign({}, recommendation);
    recCopy.qualifiedName = recommendation.enrichmentQualifiedName;
    let responses = await Promise.all([$getItemData(recommendation), $getItemData(recCopy)]);
    if (responses && responses.length === 2) {
      if (!responses[0].items) {
        responses[0].items = [];
      }
      if (!responses[1].items) {
        responses[1].items = [];
      }

      recommendation.logger && recommendation.logger.debug('enriching with qualifiedName', recommendation.enrichmentQualifiedName);
      responses[0].items.push.apply(responses[0].items, responses[1].items);
      if (responses[0].items.length > 25) {
        responses[0].items.length = 25;
      }
      return responses[0];

    }
    // shouldn't hit this point in normal circumstances but in either case ensure that we don't
    // double call for the default more than once
    if (responses.length) {
      itemData = responses[0];
    }
  }

  if (!itemData) {
    itemData = await $getItemData(recommendation);
  }

  if (itemData && itemData.items && itemData.items.length) {
    return itemData;
  }

  if (recommendation.fallbackQualifiedName) {
    recommendation.logger && recommendation.logger.debug('falling back to qualifiedName', recommendation.fallbackQualifiedName);

    recommendation.qualifiedName = recommendation.fallbackQualifiedName;
    if (recommendation.fallbackTitle) {
      recommendation.title = recommendation.fallbackTitle;
    }
    return await $getItemData(recommendation);
  }

  return itemData;

}

function getDynamicRecUrl() {
  let val = localStorage.getItem(constants.PSZ_REC_PATH_KEY);
  let url;
  const preview = getPreviewInfo();

  // if we're in preview mode we want to bypass akamai and go straight to s3
  if (preview.isPreviewMode) {
    url = constants.BASE_S3_URL + constants.PROD_PSZ_PATH;
  } else {
    url = constants.BASE_PSZ_URL + constants.PROD_PSZ_PATH;
  }
  if (val) {
    // if we're passing in a specific alt path, we should go straight to s3, bypassing akamai
    url = constants.BASE_S3_URL;
    if (val === 'demo') {
      url += constants.DEMO_PSZ_PATH;
      logger.debug('retrieving recommendations from demo path');
    } else if (val === 'test') {
      url += constants.TEST_PSZ_PATH;
      logger.debug('retrieving recommendations from test path');
    } else {
      url += '/' + val;
      logger.debug('retrieving recommendations from custom path');
    }
  }
  return url;
}

/** tests are timing out, also enable testing dynamic data */
export function testSetDynamicRecData(data) {
  dynamicRecData = data;
}

export function clearDynamicRecData() {
  dynamicRecData = null;
}

async function $legacyGetDynamicData() {
  if (dynamicRecData) {
    return dynamicRecData;
  }
  const siteId = await $getSiteId();
  logger.debug('retrieving dynamic recommendation data');
  const startGetDynamicRecData = Date.now();
  let opts = {};
  const preview = getPreviewInfo();
  // we want to bust the cache in preview mode so that previewer gets the latest dynamic recommendation changes
  if (preview.isPreviewMode) {
    opts.cache = 'no-cache';
  }
  const response = await fetch(getDynamicRecUrl() + '/' + siteId, opts);
  const endGetDynamicRecData = Date.now();
  logger.debug(`retrieved dynamic recommendation data took: ${endGetDynamicRecData - startGetDynamicRecData} ms`);

  const data = await response.json();
  dynamicRecData = data;
  return data;
}

export async function $getDynamicRecData() {
  const obvApi = await $getObviyoApi();

  if (obvApi && obvApi.$getDynamicData) {
    return await obvApi.$getDynamicData();
  } else {
    return await $legacyGetDynamicData();
  }
}

async function $legacyGetDynamicRecs() {
  const preview = getPreviewInfo();
  const data = await $getDynamicRecData();
  let recs = [];
  if (preview.isPreviewMode) {
    if (preview.visState === PROD_VIS_STATE) {
      logger.debug('preview vis mode: Live');
      recs = data.production || [];
    } else if (preview.visState === ACTIVE_VIS_STATE) {
      logger.debug('preview vis mode: Active');
      recs = recs.concat(data.staging, data.production);
    } else {
      logger.debug('preview vis mode: Current');
    }

    if (preview.previewExternal) {
      let previewRec = data.staging && data.staging.find(rec => rec.external === preview.previewExternal);
      if (!previewRec) {
        logger.debug('preview recommendation not found in staging, trying production');
        previewRec = data.production && data.production.find(rec => rec.external === preview.previewExternal);
      }
      if (previewRec) {
        logger.debug('previewing', previewRec.qualifiedName);
        let found = recs.find(rec => rec.external === preview.previewExternal);
        if (!found) {
          recs.push(previewRec);
        }
      } else {
        logger.debug('preview recommendation not found', preview.previewExternal);
      }
    }

  } else {
    recs = data.production || [];
  }

  // exclude email
  // TODO: eventually this dataset shouldn't have email in it
  return recs.filter(rec => rec.kind !== 'email' && rec.kind !== 'hyper-online');
}

export async function $getDynamicRecs() {
  const obvApi = await $getObviyoApi();

  if (obvApi && obvApi.$getRecommendations) {
    return await obvApi.$getRecommendations('online');
  } else {
    return await $legacyGetDynamicRecs();
  }
}

export async function $getRecsForPage(recs) {
  const currentPage = await $getCurrentPage();
  const raw_path = getPath();
  const path = getPathWithoutShopifyRoot(raw_path);
  return recs.filter(rec => {
    if (rec.pageMatcher && rec.pageMatcher.path) {
      return rec.pageMatcher.path === path;
    }

    return !rec.pageClass || rec.pageClass === currentPage;
  });
}

export async function $renderRecommendation(rec, itemData, targetNode) {
  if (inEditor()) {
    let decoratedRec = await $setupRec(rec);
    return await $renderWidget(decoratedRec, itemData, targetNode);
  }

  let res = await $renderWidget(rec, itemData, targetNode);
  if (res === false) {
    return res;
  }
  setupImpressionsForRecommendation(rec, itemData);
}



export async function $renderRecommendationV2(rec, targetNode) {
  if (inEditor()) {
    let decoratedRec = await $setupRec(rec);
    return await $parseTemplate(decoratedRec, targetNode);
  }

  let res = await $parseTemplate(rec, targetNode);
  if (res === false) {
    return res;
  }

  // may need to do some reporting here, as to the rendering, for now I'll push farther in
}

// TODO: Deprecated function needs to be removed once other sides stop using.
export function renderRecommendation(rec, itemData, targetNode) {
  return $renderRecommendation(rec, itemData, targetNode);
}

async function $legacyApplyGlobalCss() {
  // parameter to ignore (and remove) global_css
  const globalCssUrlParam = getUrlParam("_hc_psz_apply_global_css");
  if (globalCssUrlParam === 'false') {
    applyCss('', 'hc-psz-global-css');
    return;
  }

  const data = await $getDynamicRecData();
  const globalCss = data && data.global && data.global.css;
  if (globalCss) {
    logger.debug('found global css: applying');
    applyCss(globalCss, 'hc-psz-global-css');
  }
}

export async function $applyGlobalCss() {
  const obvApi = await $getObviyoApi();

  if (obvApi && obvApi.$applyGlobalCss) {
    return await obvApi.$applyGlobalCss();
  } else {
    return await $legacyApplyGlobalCss();
  }
}

async function $legacyApplyRecommendCss(rec) {
  if (!rec) return;

  if (rec.generatedCss) rec.logger && rec.logger.debug('applying generated css');
  applyCss(rec.generatedCss, 'hc-psz-generated-css-' + rec.external);

  if (rec.css) rec.logger && rec.logger.debug('applying custom css');
  applyCss(rec.css, 'hc-psz-custom-css-' + rec.external);
}

async function $applyRecommendCss(rec) {
  const obvApi = await $getObviyoApi();

  if (obvApi && obvApi.applyRecommendationCss) {
    return obvApi.applyRecommendationCss(rec);
  } else {
    return await $legacyApplyRecommendCss(rec);
  }
}

export function applyRecommendCss(rec) {
  $applyRecommendCss(rec);
}

let baseFilters = {
  propFilter: (item, prop) => {
    return !!item[prop];
  }
};

// different exclusions based upon type of recommendation items returned
// expectations of filters is that they will return true if item should be included and false if excluded
let filteringPipelines = {
  product: [
    // if a product doesn't have a url then it is probably not active or published so ignore
    (rec, item) => baseFilters.propFilter(item, 'onlineStoreUrl'),
    // if a product doesn't have a title then ignore for now
    (rec, item) => baseFilters.propFilter(item, 'title'),
    // if a product doesn't have any images then ignore for now
    (rec, item) => baseFilters.propFilter(item, 'featuredImage'),
    // if a product doesn't have non-zero price information then lets ignore for now
    (rec, item) => {
      if (item.priceRange) {
        if ((item.priceRange.minVariantPrice && item.priceRange.minVariantPrice.amount > 0) ||
          (item.priceRange.maxVariantPrice && item.priceRange.maxVariantPrice.amount > 0)) {
          return true;
        }
      }

      return false;
    },
    // product inventory filter
    (rec, item) => {
      // if a product tracks inventory but has an inventory total less than the set amount (default to 1) ignore it
      const minInventory = rec.minInventory || 1;
      // in some cases, item variants have a negative inventory causing total inventory to be incorrect,
      // fix by giving a value of 0 to variants with negative inventory
      // lambda might be the right place for this fix down the road
      if (item.tracksInventory && typeof item.totalInventory === 'number') {

        if (item.variants === undefined && item.totalInventory < minInventory) {
          return false;
        } else if (item.variants) {

          // if variant is marked as continue for inventory policy then it can still be sold
          // even if inventory is less than min
          if (item.variants.length && item.variants[0].inventoryPolicy === 'CONTINUE') {
            return true;
          }

          let totalInventory = 0;
          item.variants.forEach(function (variant, i) {
            if (variant.inventoryQuantity === undefined && typeof item.totalInventory !== 'number') {
              totalInventory += 0;
            } else if (variant.inventoryQuantity < 1) {
              totalInventory += 0;
            } else {
              totalInventory += variant.inventoryQuantity;
            }
          });

          if (totalInventory < minInventory) {
            return false;
          }
        }
      }
      return true;
    },
    // don't recommend current pdp item
    (rec, item) => {
      const currentProdId = getCurrentProductId();
      if (currentProdId) {
        return item.productId !== currentProdId.toString();
      }
      return true;
    },
    // don't recommend items already in cart
    async (rec, item) => {
      if (!rec.skipCartItemFilter) {
        let items = await $getCartItems();
        let itemIds = items && items.map(item => item.id && item.id.toString());
        if (itemIds) {
          return itemIds.indexOf(item.productId) < 0;
        }
      }

      return true;
    }
  ],
  category: []
};

export async function $applyGeneralExclusions(rec, itemData) {
  let filteredItems = [];
  for (let item of itemData.items) {
    let filteringPipeline = [];

    // for now, use productId for now as indicator that item is of a product type
    if (item.productId) {
      filteringPipeline = filteringPipelines.product;
    }

    let filtered = false;
    for (let filter of filteringPipeline) {
      let result = await filter(rec, item);
      if (!result) {
        filtered = true;
        break;
      }
    }

    if (filtered) continue;

    filteredItems.push(item);
  }

  // filter out duplicates if not in non-live preview mode
  const previewInfo = getPreviewInfo();
  if (!previewInfo.isPreviewMode || previewInfo.isPreviewLiveMode) {
    filteredItems = filteredItems.filter((item, index, self) => {
      let id = getIdFromGid(item.id);
      let foundIndex = self.findIndex(it => getIdFromGid(it.id) === id);
      return index === foundIndex;
    });
  }

  itemData.items = filteredItems;

  return itemData;
}

async function $fetchItems(recParams) {
  let paramString = convertObjectToUrlParams(recParams);
  const response = await fetch(`${getRecommendationItemDataUrlV2(recParams)}?${paramString}`);
  return response.json();
}

// TODO: need to make this item type agnostic, i.e. that it will check the type, currently it only supports product item types
async function $decorateWithMetafields(rec, itemData) {

  let metafields = rec.metafields;
  if (!metafields || !metafields.length) return;

  let items = itemData.items;
  if (!items || !items.length) return;

  // add handle to items, and map
  // add master id and handle to items
  items.forEach(i => {
    i.mid = getIdFromGid(i.id);
    i.handle = i.handle || getHandle(i.onlineStoreUrl);
  });

  // liquid only understands handles
  const handles = items.map(i => i.handle).filter(h => !!h);

  let url = getAppProxyUrl('product-info', [
    { name: 'handle', value: handles.join(',') },
    { name: 'meta', value: metafields },
  ]);

  const response = await fetch(url);
  if (response.status === 204) return;
  if (response.status >= 400) return;

  const result = await response.json();
  const data = result && result.items;
  if (!data || !data.length) return;

  const itemMap = arrayToMap(items, 'mid');

  // append to each item all properties that start with 'meta.'
  data.forEach(m => {
    const item = itemMap[m.id];
    if (!item) return;

    for (const [k, v] of Object.entries(m)) {
      if (k.startsWith('meta.')) {
        item[k] = v;
      }
    }
  });
}

/*
async function $decorateWithMetafieldsV1(rec, itemData) {
  let metafields = rec.metafields;
  if (!metafields || !metafields.length) return;

  let items = itemData.items;
  if (!items || !items.length) return;

  // add master id to items, and map
  items.forEach(i => i.mid = getIdFromGid(i.id));
  const ids = items.map(i => i.mid);

  let url = new URL(constants.PRODUCT_DATA_URL_V1);
  url.searchParams.append('q', 'p');
  url.searchParams.append('xid', await $getSiteId());
  url.searchParams.append('mid', ids.join(','));
  url.searchParams.append('inc', 'METAFIELDS');
  url.searchParams.append('mf', metafields);

  const response = await fetch(url);
  if (response.status === 204) return;
  if (response.status >= 400) return;

  const data = await response.json();
  if (!data || !data.length) return;

  const itemMap = arrayToMap(items, 'mid');

  data.forEach(m => {
    const { MASTER_ID, METAFIELDS } = m;
    const item = itemMap[MASTER_ID];
    if (!item) return;
    if (!METAFIELDS || !METAFIELDS.length) return;

    METAFIELDS.forEach(m => {
      const { fullName, value } = m;
      const propName = 'meta.' + fullName;
      item[propName] = value;
    });
  });
}
*/

function addEventListeners(rec) {
  let eventListener = getEventListener();

  rec.on = (event, cb) => {
    eventListener.on(rec.external, event, cb);
  };

  rec.trigger = (event) => {
    eventListener.trigger(rec.external, event);
  };
}

// returns a rec copy with additional properties and functionality added
// if false is returned then config failed
export async function $setupRec(rec0) {
  // create a copy with form factor meta pulled to top of rec
  let rec = applyRecommendationFormFactorProperties(rec0);
  rec.logger = new Logger(`${rec.external}:`);

  addEventListeners(rec);

  /** global config */
  let globalConfigRet = await $applyCustomFn({
    $fn: async () => { },
    refName: 'globalConfig',
    rec
  });
  if (globalConfigRet === false) {
    return false;
  }

  /** config */
  let configRet = await $applyCustomFn({
    $fn: async () => { },
    refName: 'config',
    rec
  });
  if (configRet === false) {
    return false;
  }

  return rec;

}

async function $legacyGetTargetNode(selector, maxWait, rec) {
  let targetNode;

  let location = await $getLocation(selector);

  let logger2 = logger;
  if (rec && rec.logger) {
    logger2 = rec.logger;
  }

  if (location) {
    logger2.debug('using custom location:', location.label || location.name);
    if (location.fn) {
      logger2.debug('looking for location with custom function');
      return await location.fn();
    } else if (location.selector) {
      logger2.debug('looking for location with selector:', location.selector);
      selector = location.selector;
    } else {
      logger2.debug('invalid format for custom location');
      return false;
    }
  } else {
    logger2.debug('looking for targetNode with selector:', selector);
  }

  let isSectionTemplateSelector = /^#shopify-section-template--\S+__\S+$/.test(selector);

  if (isSectionTemplateSelector) {
    let selectorParts = selector.split('__');
    if (selectorParts.length > 1) {
      selector = `[id^='shopify-section-template--'][id$='__${selectorParts[1]}']`;
      logger2.debug(`shopify-section-template id found, using selector : ${selector}`);
    }
  }

  await $waitFor(() => {
    // check first for obv-block-external node injected by shopify editor
    targetNode = document.querySelector(`#obv-block-${rec.external}`);
    if (!targetNode) {
      targetNode = document.querySelector(selector);
    }
    return !!targetNode;
  }, maxWait);

  return targetNode;
}

export async function $getTargetNode(selector, maxWait, rec) {
  const obvApi = await $getObviyoApi();

  if (obvApi && obvApi.$getTargetNode) {
    return await obvApi.$getTargetNode(await $getCtxWith({rec}), selector, maxWait, rec && rec.logger);
  } else {
    return await $legacyGetTargetNode(selector, maxWait, rec);
  }
}

export async function $processItemData(rec, itemData) {
  if (!itemData || !itemData.items || itemData.items.length <= 0) {
    rec.logger.debug('no items returned to display');
    return false;
  }

  const returnedItems = itemData.items.length;

  rec.logger.debug(`recommended ${returnedItems} items`);

  if (itemData.items.length < constants.MINIMUM_ITEM_THRESHOLD) {
    rec.logger.debug(`items returned: ${itemData.items.length} less than minimum threshold: ${constants.MINIMUM_ITEM_THRESHOLD}`);
    return false;
  }

  // fallback to onlineStorePreviewUrl when onlineStoreUrl doesn't exist, i.e. dev sites/password protected sites
  // use onlineStorePreviewUrl only if we are in a non-live preview mode
  const previewInfo = getPreviewInfo();
  if (inEditor() || (previewInfo.isPreviewMode && !previewInfo.isPreviewLiveMode)) {
    itemData.items.forEach(item => {
      if (!item.onlineStoreUrl) {
        item.onlineStoreUrl = item.onlineStorePreviewUrl;
      }
    });
  }

  /** filterItemData */
  rec.logger.debug('applying general filtering rules');
  itemData = await $applyCustomFn({
    $fn: $applyGeneralExclusions,
    fnParams: [rec, itemData],
    refName: 'filterItemData',
    rec,
    additionalCtx: {
      $applyGeneralExclusions, itemData
    }
  });
  if (itemData === false) {
    rec.logger.debug('filterItemData returned false, exiting early');
    return false;
  }

  if (!itemData.items || itemData.items.length <= 0) {
    rec.logger.debug('no items remaining after filtering');
    $sendError('pfe', { message: '0' }, rec.external);
    return false;
  }

  const filteredItems = itemData.items.length;
  rec.logger.debug(`${returnedItems - filteredItems} items filtered`);

  if (itemData.items.length < constants.MINIMUM_ITEM_THRESHOLD) {
    rec.logger.debug(`filtered items: ${itemData.items.length} less than minimum threshold: ${constants.MINIMUM_ITEM_THRESHOLD}`);
    $sendError('pfe', { message: itemData.items.length.toString() }, rec.external);
    return false;
  }

  /** decorateWithMetafields */
  // TODO: Still need to make the metafields function support different item types, ignoring for now since it is not critical
  // for first iteration.
  if (rec.metafields) {
    let metaFieldsRet = await $applyCustomFn({
      $fn: $decorateWithMetafields,
      fnParams: [rec, itemData],
      refName: '$decorateWithMetafields',
      rec,
      additionalCtx: {
        itemData
      }
    });

    if (metaFieldsRet === false) {
      rec.logger.debug('$decorateWithMetafields returned false, exiting early');
      return false;
    }
  }

  return itemData;
}

export async function $initRender() {
  await $applyGlobalCss();
  await $getAndRenderRecommendations();
}

export async function $getAndRenderRecommendations(clearContext) {
  if (clearContext) {
    clearRecsWithContext();
  }
  let recsWithContext = await $getRecsWithContext();
  await $renderRecommendations(recsWithContext);
}

let recsWithContext;

export function clearRecsWithContext() {
  recsWithContext = null;
}


export async function $getRecsWithContext() {

  // basic level of caching so we don't redo everything
  if (recsWithContext) {
    return recsWithContext;
  }

  const recs = await $getDynamicRecs();

  // TODO: Add Preconfig

  // initially run any recommendations that apply to this page
  const pageRecs = await $getRecsForPage(recs);

  recsWithContext = [];

  for (let rec0 of pageRecs) {
    try {
      let rec = await $setupRec(rec0);
      if (rec === false) {
        logger.debug(`${rec0.external}: config returned false, exiting early`);
        continue;
      }
      recsWithContext.push(rec);
    } catch (err) {
      // print out simply just in case our context logger didn't get created before erroring
      logger.error(`${rec0.external}: problem processing recommendation: ${rec0.qualifiedName}`, err);
      $sendError('cre', err, rec0.external);
    }
  }

  return recsWithContext;
}

function updateRecPropsFromDataset(rec, targetNode) {
  const dataToRecPropMap = {
    obvInsertMode: 'insertKind'
  };

  if (!targetNode) return;

  let dataset = targetNode.dataset;
  if (!dataset) return;

  for (let key in dataset) {
    let recProp = dataToRecPropMap[key];
    let newVal = dataset[key];
    if (recProp && newVal) {
      rec.logger.debug(`overriding: "${recProp}" with: "${newVal}" found in targetNode dataset`);
      rec[recProp] = newVal;
    }
  }
}

const $audienceFilters = {
  newVisitor: async ({ rec, params }) => {
    let isNew = await $isNewVisitor();
    if (params.value !== isNew) {
      return false;
    }

    return true;
  },
  sessionPlays: async ({ rec, params }) => {
    let totalPlays = await $getSessionPlayCount(rec.external);
    if (totalPlays >= params.value) {
      return false;
    }

    return true;
  }
};

export async function $renderRecommendations(recsWithContext) {
  try {
    if (!recsWithContext || !recsWithContext.length) {
      logger.debug('no recommendations found for current page');
    }

    await Promise.all(recsWithContext.map(async rec => {
      rec.logger.debug(`processing recommendation: ${rec.qualifiedName}`);

      try {

        if (rec.disabled) {
          rec.logger.debug('recommendation disabled for current form factor');
          return;
        }

        if (rec.audience) {
          for (let audienceField of rec.audience) {
            let $filter = $audienceFilters[audienceField.kind];
            if ($filter) {
              let result = await $filter({ rec, params: audienceField });
              if (result === false) {
                rec.logger.debug(`recommendation audience filter returned false`, audienceField);
                rec.logger.debug(`recommendation doesn't match current audience`);
                return;
              }
            }
          }
        }

        const MAX_FETCH_ITEMS = 50;
        const $fetchItemsFromIds = async (ids) => {
          if (!ids || !ids.length) return;
          if (ids.length > MAX_FETCH_ITEMS) ids = ids.slice(0, MAX_FETCH_ITEMS);
          let recParams = await $getCommonParams(rec);
          recParams.items = JSON.stringify(ids);
          recParams.qualified_name = 'psz-manual';
          return $fetchItems(recParams);
        };

        /** getTargetNode */
        let targetNode;

        if (!rec.showInPopup) {
          targetNode = await $applyCustomFn({
            $fn: $getTargetNode,
            fnParams: [rec.location, null, rec],
            refName: 'getTargetNode',
            rec
          });

          if (targetNode === false) {
            rec.logger.debug('getTargetNode returned false, exiting early');
            return;
          }

          rec.logger.debug(`found targetNode`);
        }

        // get custom props from dataset attributes of node if appropriate
        updateRecPropsFromDataset(rec, targetNode);

        // new v2 model
        if (rec.tpl) {
          /** render */
          let renderRet = await $applyCustomFn({
            $fn: $renderHyper,
            fnParams: [rec, null, targetNode],
            refName: 'render',
            rec,
            additionalCtx: {
              widgets, targetNode
            }
          });

          if (renderRet === false) {
            rec.logger.debug('render returned false, exiting early');
            return;
          }
        } else {
          if (rec.lazy !== false && !inShopifyEditor()) {
            rec.logger.debug('waiting for targetNode visibility');
            await $waitForIntersection(targetNode);

            rec.logger.debug('targetNode visible, retrieving item recommendations');
          }

          /** getItemData */

          const startGetItems = Date.now();
          // itemData is expected to be an object with an items property and an id property
          // id property represents the amazon recommendation that was given as a whole
          let itemData = await $applyCustomFn({
            $fn: $getRecommendationItemData,
            fnParams: [rec],
            refName: 'getItemData',
            rec,
            additionalCtx: {
              $getCommonParams, $fetchItemsFromIds, $getItemData,
              $getRecommendationItemData, getItemDataMap, convertObjectToUrlParams,
              getRecommendationItemDataUrl: getRecommendationItemDataUrlV2
            }
          });

          if (itemData === false) {
            rec.logger.debug('getItemData returned false, exiting early');
            return;
          }

          const endGetItems = Date.now();
          rec.logger.debug(`retrieved item recommendations, took ${endGetItems - startGetItems} ms`);

          itemData = await $processItemData(rec, itemData);

          if (itemData === false) {
            return;
          }

          /** render */
          let renderRet = await $applyCustomFn({
            $fn: $renderRecommendation,
            fnParams: [rec, itemData, targetNode],
            refName: 'render',
            rec,
            additionalCtx: {
              widgets, itemData, targetNode
            }
          });

          if (renderRet === false) {
            rec.logger.debug('render returned false, exiting early');
            return;
          }

        }

        rec.logger.debug(`successfully processed recommendation: ${rec.qualifiedName}`);

        /** postRender */
        let postRenderRet = await $applyCustomFn({
          $fn: async () => { },
          refName: 'postRender',
          rec,
          additionalCtx: {

          }
        });

        if (postRenderRet === false) {
          rec.logger.debug('postRender function returned false, exiting early');
          return;
        }

        if (inShopifyEditor()) {
          $addShopifyEditorEnhancements(rec);
        }

      } catch (err) {
        // print out simply just in case our context logger didn't get created before erroring
        logger.error(`${rec.external}: problem processing recommendation: ${rec.qualifiedName}`, err);
        $sendError('pre', err, rec.external);
      }
    }));
  } catch (err) {
    logger.error(`problem rendering recommendations`, err);
    $sendError('rre', err);
  }
}
