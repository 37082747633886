import { getPszIds, $getPszClassNames, getDevice, getValue, createElement } from '../utils';
import Logger from '../logger';
import { $getTitleNode, renderElemShowNav, $renderItems, setMoreItemsLogic, $insertPsz } from './widget';

const logger = new Logger();
const DEFAULT_ROWS = { desktop: 1, mobile: 2 };
const DEFAULT_COLS = { desktop: 4, mobile: 2 };

export async function $renderGrid(rec, itemData, targetNode) {

  let device = getDevice(rec);
  let items = itemData.items;
  let ids = getPszIds(rec);
  let classNames = await $getPszClassNames(rec, ['hc-psz-grid']);
  let rid = getValue(itemData.rid, null);
  let theme = getValue(rec.themeColor, 'light');
  let cols = getValue(rec.gridCols, DEFAULT_COLS[device]);
  let rows = getValue(rec.gridRows, DEFAULT_ROWS[device]);
  let showTotal = cols * rows;
  let show = { count: showTotal, step: showTotal };
  let appendNav = (showTotal < items.length && rec.navShow === 'more') ? true : false;

  let params = {
    pszItemsHtml: {
      rec: rec,
      widget: 'grid',
      className: 'hc-psz-item-grid',
      show: show.count,
    },
    pszItems: {
      id: ids.pszItems,
      className: 'hc-psz-items',
      attrs: {
        'data-hc-psz-cols': cols,
        'data-hc-psz-rows': rows,
      },
    },
    psz: {
      id: ids.psz,
      className: classNames,
      attrs: {
        'data-rid': rid,
        'data-hc-psz-theme-color': theme
      }
    }
  };

  let elem = {
    pszTitle: await $getTitleNode(rec, itemData),
    pszItemsHtml: await $renderItems(items, params.pszItemsHtml),
    pszItems: createElement(params.pszItems),
    pszShowNav: renderElemShowNav(),
    psz: createElement(params.psz),
  };

  elem.pszItems.append(elem.pszItemsHtml);
  elem.psz.append([elem.pszTitle, elem.pszItems]);
  if (appendNav) elem.psz.append(elem.pszShowNav);

  await $insertPsz(rec, targetNode, elem);
  if (appendNav) setMoreItemsLogic(params.psz.id, show);

}
