/**
 * Functions to assist with app-proxy
 * Including native card assets
 */

import {
  arrayFrom, htmlToElement
} from './utils';

let loadedAssetsMap = {};

function replaceScripts(el) {
  let scripts = el.querySelectorAll('script');
  if (!scripts || !scripts.length) return;

  for (const oldScript of arrayFrom(scripts)) {
    const newScript = document.createElement('script');
    for (const name of oldScript.getAttributeNames()) {
      newScript.setAttribute(name, oldScript.getAttribute(name));
    }
    newScript.textContent = oldScript.textContent;
    oldScript.replaceWith(newScript);
  }
}

export function getAppProxyUrl(path, params) {
  let url = new URL(document.location.origin);
  url.pathname = `/apps/obviyo-block/${path}`;
  if (params) {
    for (let param of params) {
      url.searchParams.append(param.name, param.value);
    }
  }
  return url.toString();
}

export async function $loadAssets(path, params, loadScripts) {
  if (!loadedAssetsMap[path]) {
    loadedAssetsMap[path] = true;

    let url = getAppProxyUrl(path, params);
    let response = await fetch(url);
    let text = await response.text();
    let node = htmlToElement(text);
    // by default htmlToElement won't execute any javascript so if that is needed
    // we need to do that separately
    if (loadScripts) {
      replaceScripts(node);
    }
    document.head.appendChild(node);
  }
}
