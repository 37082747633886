import {
  $waitForWindowObject, getClosest, getPszIds, $getPszClassNames, getDevice,
  getRootSelector, getValue, createElement
} from '../utils';
import { $sendClickEvent, triggerWidgetUpdatedEvent } from '../reporting';
import Logger from '../logger';
import { $getTitleNode, $renderItems, $insertPsz } from './widget';

const logger = new Logger();

const DEFAULT_ITEM_WIDTH = { desktop: '200px', mobile: '100px' };
const DEFAULT_ITEMS_PER_VIEW = { desktop: 4, mobile: 2 };

const DEFAULT_SPACE_BETWEEN = 25;

function isCorrectFormat(value) {
  var format = /(\d+re|\d+em|\d+%|\d+px|\d+cm|\d+mm|\d+in|\d+pt|\d+pc|\d+ch|\d+rem|\d+vh|\d+vw|\d+vmin|\d+vmax)/g;
  var check = value.match(format);
  return (check !== null) ? true : false;
}

function getElementSize(sel, type) {

  if (!sel && !type) return false;

  let elem = document.querySelector(sel);
  if (!elem) return false;

  let style = window.getComputedStyle(elem);
  let sumSize = (a, b) => parseInt(style[a]) + parseInt(style[b]);

  if (type === 'paddingWidth' || type === 'innerWidth()') return elem.clientWidth;
  if (type === 'paddingHeight' || type === 'innerHeight()') return elem.clientHeight;
  if (type === 'borderWidth' || type === 'outerWidth()') return elem.offsetWidth;
  if (type === 'borderHeight' || type === 'outerHeight()') return elem.offsetHeight;
  if (type === 'contentWidth' || type === 'width()') return elem.clientWidth - sumSize('paddingLeft', 'paddingRight');
  if (type === 'contentHeight' || type === 'height()') return elem.clientHeight - sumSize('paddingTop', 'paddingBottom');
  if (type === 'marginWidth' || type === 'outerWidth(true)') return elem.offsetWidth + sumSize('marginLeft', 'marginRight');
  if (type === 'marginHeight' || type === 'outerHeight(true)') return elem.offsetHeight + sumSize('marginTop', 'marginBottom');

}

export async function $renderSlider(rec, itemData, targetNode) {

  let device = getDevice(rec);
  let items = itemData.items;
  let ids = getPszIds(rec);

  let classNames = await $getPszClassNames(rec, ['hc-psz-slider']);

  let rid = getValue(itemData.rid, null);
  let theme = getValue(rec.themeColor, 'light');
  let navStyle = getValue(rec.navStyle, 'rectArrows');
  let navLoc = "hc-psz-nav-inside";
  if (rec.navLocation && rec.navLocation === 'outside') {
    navLoc = "hc-psz-nav-outside";
  }
  let itemsPerView;
  let itemsPerSlide;
  let spaceBetween = typeof rec.sliderSpaceBetween !== 'undefined' ? rec.sliderSpaceBetween : DEFAULT_SPACE_BETWEEN;
  let centerInsufficientSlides = typeof rec.centerInsufficientSlides !== 'undefined' ? rec.centerInsufficientSlides : false;
  let style = '';
  // TODO: Right now we have rec.productsPerView, and rec.productWidth, should probably be converted to items
  let mode = (rec.productsPerView === 0 && typeof rec.productWidth === 'string') ? 'fixedWidth' : 'fixedSlides';

  if (mode === 'fixedWidth') {
    let defaultItemWidth = DEFAULT_ITEM_WIDTH[device];
    let itemWidth = (isCorrectFormat(rec.productWidth)) ? rec.productWidth : defaultItemWidth;
    style += 'width: ' + itemWidth + ' ';
    itemsPerView = 'auto';
    itemsPerSlide = 1;
  }

  if (mode === 'fixedSlides') {
    let defaultItemsPerView = DEFAULT_ITEMS_PER_VIEW[device];
    itemsPerView = getValue(rec.productsPerView, defaultItemsPerView);
    itemsPerSlide = Math.floor(itemsPerView);
  }

  let navPrevClass = 'swiper-button-prev';
  let navNextClass = 'swiper-button-next';
  let elemParams = {
    pszNavPrev: { className: ['hc-psz-nav', 'hc-psz-nav-prev', navPrevClass] },
    pszNavNext: { className: ['hc-psz-nav', 'hc-psz-nav-next', navNextClass] },
    pszItemsHtml: {
      rec: rec,
      widget: 'slider',
      className: 'swiper-slide',
      show: items.length,
      style: style
    },
    pszItems: {
      id: ids.pszItems,
      className: ['hc-psz-items', 'swiper-wrapper'],
    },
    pszSliderViewport: {
      className: ['hc-psz-slider-viewport', 'hc-psz-viewport-root', 'swiper-container'],
    },
    pszSliderWrap: {
      className: ['hc-psz-slider-wrap', navLoc],
      attrs: { 'data-hc-psz-nav-style': navStyle },
    },
    psz: {
      id: ids.psz,
      className: classNames,
      attrs: {
        'data-rid': rid,
        'data-hc-psz-theme-color': theme
      },
    }
  };

  const swiper = window['hcPszSwiper'] || await $waitForWindowObject('hcPszSwiper');

  let elem = {
    pszTitle: await $getTitleNode(rec, itemData),
    pszNavPrev: createElement(elemParams.pszNavPrev),
    pszNavNext: createElement(elemParams.pszNavNext),
    pszItemsHtml: await $renderItems(items, elemParams.pszItemsHtml),
    pszItems: createElement(elemParams.pszItems),
    pszSliderViewport: createElement(elemParams.pszSliderViewport),
    pszSliderWrap: createElement(elemParams.pszSliderWrap),
    psz: createElement(elemParams.psz),
  };

  elem.pszItems.append(elem.pszItemsHtml);
  elem.pszSliderViewport.append(elem.pszItems);
  elem.pszSliderWrap.append([elem.pszNavPrev, elem.pszSliderViewport, elem.pszNavNext]);
  elem.psz.append([elem.pszTitle, elem.pszSliderWrap]);

  // await new Promise(resolve => setTimeout(resolve, 2000));
  // console.log('>> timeout promise resolved');

  await $insertPsz(rec, targetNode, elem);

  const swiperSelector = getRootSelector(rec) + ' .swiper-container';

  // fix undefined new swiper()
  const swiperEl = document.querySelector(swiperSelector);
  const existingSwiper = swiperEl && swiperEl.swiper;
  if (existingSwiper) {
    existingSwiper.destroy(true, true);
  }

  const mySwiper = new swiper(swiperSelector, {
    slidesPerView: itemsPerView,
    slidesPerGroup: itemsPerSlide,
    init: false,
    longSwipesRatio: .1,
    threshold: 3,
    spaceBetween: spaceBetween,
    centerInsufficientSlides: centerInsufficientSlides,
    updateOnWindowResize: true,
    noSwiping: true,
    noSwipingClass: 'hc-psz-item-add-to-cart-btn',
    // observe and observeParents watch dom to set the correct widths for products, otherwise it shows 1 product, and style has no width set
    observer: true,
    observeParents: true,
    navigation: {
      prevEl: getRootSelector(rec) + ' .' + navPrevClass,
      nextEl: getRootSelector(rec) + ' .' + navNextClass
    },
    on: {
      click: async () => {

        const clickedSlide = mySwiper.clickedSlide;
        if (!clickedSlide) return;

        const pid = clickedSlide.getAttribute('data-pid');
        const rootDiv = getClosest(clickedSlide, '.hc-psz');
        if (!rootDiv) return;
        const rid = rootDiv.getAttribute('data-rid');

        await $sendClickEvent(pid, rid);

      },
      slideChangeTransitionEnd: () => { triggerWidgetUpdatedEvent() },
    },
  });

  let setSlidesPerGroup = (swiper, id) => {

    let sliderWrapSelector = "#" + id + " .hc-psz-items";
    let sliderWraperInnerWidth = getElementSize(sliderWrapSelector, 'contentWidth');
    let itemSelector = "#" + id + " .hc-psz-items .hc-psz-item";
    let itemOuterWidth = getElementSize(itemSelector, 'marginWidth');
    let totalFullSlides = Math.floor(sliderWraperInnerWidth / itemOuterWidth);

    swiper.params.slidesPerGroup = totalFullSlides;
    swiper.update();

  };

  if (mode === 'fixedWidth') {
    mySwiper.on('init', () => {
      setSlidesPerGroup(mySwiper, ids.psz);
    });
    mySwiper.on('resize', () => {
      setSlidesPerGroup(mySwiper, ids.psz);
    });
  }
  mySwiper.init();

}
