import constants from './constants';
import Logger from './logger';
import { getPreviewInfo } from './preview';
import { $getCurrentPage } from './data';
const logger = new Logger();

export function $wait(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

function exceededTimeout(startTime, maxWait) {
  if (typeof maxWait !== 'number') {
    return false;
  }
  return (Date.now() - startTime) > maxWait;
}

export async function $waitFor(conditionalFn, maxWait) {
  const start = Date.now();
  while (!conditionalFn() && !exceededTimeout(start, maxWait)) {
    await $wait(100);
  }
}

export async function $waitForWindowObject(name) {
  let obj;
  obj = window[name];
  while (!obj) {
    await $wait(100);
    obj = window[name];
  }
  return obj;
}

export function getValue(param, defaultVal) {
  return (param === undefined) ? defaultVal : param;
}

export function getLocation() {
  return document.location || window.location;
}

export function getPath() {
  const loc = getLocation();
  return loc.pathname && loc.pathname.toLowerCase();
}

export function getShopifyRootPath() {
  return window.Shopify && window.Shopify.routes && window.Shopify.routes.root;
}

/** @param {string} path  */
export function getPathWithoutShopifyRoot(path) {
  const rootPath = getShopifyRootPath();
  if (path && rootPath && path.startsWith(rootPath)) {
    return '/' + path.substring(rootPath.length);
  }
  return path;
}

export function getReferrer() {
  return document.referrer;
}

export function getBaseUrl() {
  let tagAlt = localStorage.getItem(constants.PSZ_TAG_ALT_KEY);
  if (!tagAlt) {
    return constants.BASE_PSZ_URL + constants.STATIC_PATH;
  }

  return `${constants.BASE_PSZ_URL + constants.STATIC_PATH + constants.TEST_PSZ_PATH}/${tagAlt}`;
}

export function getPostmessageApiUrl() {
  let referrer = getReferrer();
  let apiUrl = constants.PROD_API_URL;
  if (referrer) {
    let referrerUrl = new URL(referrer);
    if (referrerUrl.hostname !== constants.PROXY_HOSTNAME && referrerUrl.hostname !== constants.LOCAL_PROXY_HOSTNAME) {
      if (referrerUrl.origin === constants.LOCAL_API_URL) {
        apiUrl = constants.LOCAL_API_URL;
        sessionStorage.setItem(constants.API_URL_KEY, constants.LOCAL_API_URL);
      } else if (referrerUrl.origin === constants.DEMO_API_URL) {
        apiUrl = constants.DEMO_API_URL;
        sessionStorage.setItem(constants.API_URL_KEY, constants.DEMO_API_URL);
      }
    } else {
      let storedUrl = sessionStorage.getItem(constants.API_URL_KEY);
      if (storedUrl) {
        if (storedUrl === constants.LOCAL_API_URL) {
          apiUrl = constants.LOCAL_API_URL;
        } else if (storedUrl === constants.DEMO_API_URL) {
          apiUrl = constants.DEMO_API_URL;
        }
      }
    }
  }

  return apiUrl + constants.POSTMESSAGE_API_PATH;
}

export function getUrlParam(paramKey) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramKey);
}

export function convertObjectToUrlParams(object) {
  const urlParams = new URLSearchParams();
  for (const [key, val] of Object.entries(object)) {
    urlParams.set(key, val);
  }
  return urlParams.toString();
}

export function isMobileLegacy(rec) {
  if (rec && rec.formFactor === 'mobile') return true;
  return window.screen &&
    Math.min(window.screen.width, window.screen.height) < 600;
}

export function isMobile(rec) {
  if (rec && rec.formFactor === 'mobile') return true;
  const clientHeight = document && document.documentElement && document.documentElement.clientHeight;
  const clientWidth = document && document.documentElement && document.documentElement.clientWidth;
  return Math.min(clientWidth, clientHeight) < 600;
}

export function getDevice(rec) {
  return (isMobile(rec)) ? 'mobile' : 'desktop';
}

// for now just look at domain to determine if we are in our apps editor
export function inEditor() {
  const loc = getLocation();
  const hostname = loc.hostname;
  return hostname.endsWith('hiconversion.net') ||
    hostname.endsWith('obviyo.net') ||
    hostname.endsWith('gallery.obviyo.com') || hostname.endsWith('gallery-local.obviyo.com');
}

export function inProxyFrame() {
  const loc = getLocation();
  return loc.hostname === constants.PROXY_HOSTNAME || loc.hostname === constants.LOCAL_PROXY_HOSTNAME;
}

export function applyCss(text, id) {

  const existing = document.getElementById(id);
  if (existing) {
    existing.remove();
  }

  if (!text) return;

  let styleSheet = document.createElement('style');
  if (id) {
    styleSheet.id = id;
  }
  document.getElementsByTagName('head')[0].appendChild(styleSheet);
  styleSheet.appendChild(document.createTextNode(text));
  return styleSheet;
}

// note we don't bother to wrap with try/catch here
// caller should handle the possibility of exceptions
// expectation is that the text contains a function called hic_script
export function applyJs(text, context) {
  const f = Function('"use strict";return (' + text + ')')();
  if (typeof f === 'function') {
    return f(context);
  } else {
    logger.debug('script is not a function');
  }
}

export function loadCss(href) {
  let css = document.createElement('link');
  css.rel = 'stylesheet';
  css.type = 'text/css';
  css.href = href;
  document.getElementsByTagName('head')[0].appendChild(css);
}

export function loadScript(src, defer) {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  if (defer) {
    script.async = false;
  }
  document.getElementsByTagName('head')[0].appendChild(script);
}

export function insertNode(node, target, insertBefore, kind) {

  if (inEditor()) {
    let existEl = document.getElementById(node.id);
    if (existEl) {
      let parentNode = existEl.parentNode;
      // deal with replacing a wrapper
      if (parentNode === target) {
        existEl = parentNode;
        parentNode = target.parentNode;
      }
      parentNode.replaceChild(node, existEl);
      return;
    }
  }

  if (kind) {
    if (kind === 'before') {
      target.parentNode.insertBefore(node, target);
    } else if (kind === 'prepend') {
      target.prepend(node);
    } else if (kind === 'append') {
      target.append(node);
    } else {
      // default is after
      target.parentNode.insertBefore(node, target.nextSibling);
    }
    // allow for legacy insertBefore functionality
  } else {
    if (insertBefore) {
      target.parentNode.insertBefore(node, target);
    } else {
      target.parentNode.insertBefore(node, target.nextSibling);
    }
  }
}

export function enableScroll(scroll) {
  let body = document.querySelector('body');
  let disableClass = "hc-psz-disable-scroll";
  if (body !== undefined) {
    if (scroll === null || scroll === true) {
      body.classList.remove(disableClass);
    } else if (scroll === false) {
      body.classList.add(disableClass);
    }
  }
}

export function isEmptyObject(obj) {
  return !!(obj && obj.constructor === Object && Object.entries(obj).length === 0);
}

// note this is a simple shallow equals check
// see: https://www.joshbritz.co/posts/why-its-so-hard-to-check-object-equality/
export function objectsAreEqual(obj1, obj2) {
  const al = Object.getOwnPropertyNames(obj1);
  const bl = Object.getOwnPropertyNames(obj2);

  // Check if the two list of keys are the same
  // length. If they are not, we know the objects
  // are not equal.
  if (al.length !== bl.length) return false;

  // Check that all keys from both objects match
  // are present on both objects.
  const hasAllKeys = al.every(value => !!bl.find(v => v === value));

  // If not all the keys match, we know the
  // objects are not equal.
  if (!hasAllKeys) return false;

  // We can now check that the value of each
  // key matches its corresponding key in the
  // other object.
  for (const key of al) if (obj1[key] !== obj2[key]) return false;

  // If the object hasn't return yet, at this
  // point we know that the objects are the
  // same
  return true;
}

export function getClosest(elem, selector) {

  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
}

export function getIdFromGid(gid) {
  if (typeof gid === 'string') {
    const parts = gid.split('/');
    return parts[parts.length - 1];
  } else {
    return gid;
  }
}

// deprecated
// TODO: Eventually remove, need to check how much this is being used outside of tag, i.e. customjs
export function getProductIdFromGid(gid) {
  return getIdFromGid(gid);
}

export function getRecommendationNodeId(rec) {
  return `hc-psz-${rec.external}`;
}

// explicitly get node without async or waitfor
// this needs to be a point of time function so
// we can use determine current state of the dom
export function getRecommendationNode(rec) {
  let recId = getRecommendationNodeId(rec);
  return document.querySelector(`#${recId}`);
}

export function getPszIds(rec) {
  return {
    pszWrap: `hc-psz-wrap-${rec.external}`,
    psz: `hc-psz-${rec.external}`,
    pszItems: `hc-psz-items-${rec.external}`,
  };
}

export async function $getPszClassNames(rec, classNames) {

  classNames = typeof classNames === 'object' ? classNames : [];

  classNames.push('hc-psz', `hc-psz-${rec.qualifiedName}`, rec.styleClassName || 'hc-psz-default-style');

  const pageClass = `hc-psz-${await $getCurrentPage()}`;
  const deviceClass = (isMobile(rec)) ? 'hc-psz-mobile' : 'hc-psz-desktop';
  classNames.push(pageClass, deviceClass);

  if (rec.place && rec.place === constants.MINICART_PLACE) {
    classNames.push('hc-psz-minicart');
  }

  const preview = getPreviewInfo();
  if (rec.testMode && !inEditor() && (!preview.isPreviewMode || preview.isPreviewTestMode)) {
    classNames.push('hc-psz-test');
  }

  if (typeof rec.customClasses === 'string' && rec.customClasses.length !== 0) {
    let customClasses = rec.customClasses.split(' ');
    classNames = classNames.concat(customClasses);
  }

  return classNames;

}

export async function $setPszFlagsWithClassNames(rec) {

  let classNames = await $getPszClassNames(rec);

  if (classNames.includes('hc-psz-show-product-price-in-atc')) {
    rec.showProductPriceInAtc = true;
    logger.debug('Setting product price in add to cart button based on class name: hc-psz-show-product-price-atc');
  }

  return rec;

}

export function getSelector(name, args) {

  let getPszSelector = () => {
    if (args.rec) {
      return `.hc-psz#${getPszIds(args.rec).psz}`;
    }
    return null;
  };

  let getPszItemSelector = () => {
    if (args.rec && args.product && args.product.id && typeof args.index === 'number') {
      return `${getPszSelector()} .hc-psz-item[data-pid="${getIdFromGid(args.product.id)}"][data-index="${args.index}"]`;
    }
    return null;
  };

  let getPszItemImgWrapSelector = () => {
    let itemSel = getPszItemSelector();
    if (itemSel !== null) {
      return `${itemSel} .hc-psz-item-img-wrap`;
    }
    return null;
  };

  let getPszItemImgSelector = () => {
    let itemSel = getPszItemSelector();
    if (itemSel !== null) {
      return `${itemSel} .hc-psz-item-img`;
    }
    return null;
  };

  let getPszItemSaleBadgeSelector = () => {
    let itemSel = getPszItemSelector();
    if (itemSel !== null) {
      return `${itemSel} .hc-psz-item-sale-badge`;
    }
    return null;
  };

  let getPszItemImgIconSpinner = () => {
    let itemSel = getPszItemSelector();
    if (itemSel !== null) {
      return `${itemSel} .hc-psz-icon-spinner`;
    }
    return null;
  };

  let getPszItemPricesSelector = () => {
    let itemSel = getPszItemSelector();
    if (itemSel !== null) {
      return `${itemSel} .hc-psz-item-prices`;
    }
    return null;
  };

  let getPszItemAddToCartSelect = () => {
    let itemSel = getPszItemSelector();
    if (itemSel !== null) {
      return `${itemSel} .hc-psz-item-add-to-cart-select, ${itemSel} .hc-psz-list-item-add-to-cart-select`;
    }
    return null;
  };

  let getPszItemAddToCartButton = () => {
    let itemSel = getPszItemSelector();
    if (itemSel !== null) {
      return `${itemSel} .hc-psz-item-add-to-cart-btn, ${itemSel} .hc-psz-list-item-add-to-cart-btn`;
    }
    return null;
  };

  if (typeof name !== 'string') {
    logger.debug(`getSelector(): no selector name passed`);
    return null;
  }

  if (name === 'hc-psz') {
    return getPszSelector();
  }

  if (name === 'hc-psz-item') {
    return getPszItemSelector();
  }

  if (name === 'hc-psz-item-sale-badge') {
    return getPszItemSaleBadgeSelector();
  }

  if (name === 'hc-psz-item-img-wrap') {
    return getPszItemImgWrapSelector();
  }

  if (name === 'hc-psz-item-img') {
    return getPszItemImgSelector();
  }

  if (name === 'hc-psz-icon-spinner') {
    return getPszItemImgIconSpinner();
  }

  if (name === 'hc-psz-item-prices') {
    return getPszItemPricesSelector();
  }

  if (name === 'hc-psz-item-add-to-cart-select') {
    return getPszItemAddToCartSelect();
  }

  if (name === 'hc-psz-item-add-to-cart-btn') {
    return getPszItemAddToCartButton();
  }

  if (name) {
    logger.debug(`getSelector(): unknown name for selector: '${name}'`);
  }

  return null;

}

export function getRootSelector(rec) {
  let rootSelector = `#${getRecommendationNodeId(rec)}`;

  // here we are forcing our form factor so lets check to make sure we are in the right context
  if (rec.formFactor && !inProxyFrame()) {
    rootSelector = `.device-pane.${rec.formFactor} ${rootSelector}`;
  }

  return rootSelector;
}

export function setItemShow(item, state) {
  if (item && item.classList && typeof state === "boolean") {
    if (state === true) {
      item.classList.add(constants.ITEM_SHOW_CLASS);
      item.classList.remove(constants.ITEM_HIDE_CLASS);
    } else if (state === false) {
      item.classList.add(constants.ITEM_HIDE_CLASS);
      item.classList.remove(constants.ITEM_SHOW_CLASS);
    }
  }
}

export function getTimeOfDay() {
  const date = new Date();
  const hour = date.getHours();
  let day_part = 'night';
  if (hour >= 6 && hour <= 11) {
    day_part = 'morning';
  } else if (hour >= 12 && hour <= 16) {
    day_part = 'afternoon';
  } else if (hour >= 17 && hour <= 23) {
    day_part = 'evening';
  }

  return day_part;
}

export function getDayOfWeek() {
  const date = new Date();
  return date.getDay();
}

export function formatMoney(cents, format) {
  let defaultFormat = '${{amount}}';
  if (window.Shopify && typeof window.Shopify.money_format === 'string') {
    defaultFormat = window.Shopify.money_format;
  }

  if (typeof cents === 'string') {
    // chop of additional decimal precision beyond 2
    let centsParts = cents.split('.');
    if (centsParts.length === 2 && centsParts[1].length > 2) {
      cents = `${centsParts[0]}.${centsParts[1].slice(0, 2)}`;
    }
    cents = cents.replace('.', '');
  }
  let value = '';
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  const formatString = format || defaultFormat;

  function formatWithDelimiters(
    number,
    precision = 2,
    thousands = ',',
    decimal = '.'
  ) {
    if (isNaN(number) || number == null) {
      return 0;
    }

    number = (number / 100.0).toFixed(precision);

    const parts = number.split('.');
    const dollarsAmount = parts[0].replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      `$1${thousands}`
    );
    const centsAmount = parts[1] ? decimal + parts[1] : '';

    return dollarsAmount + centsAmount;
  }

  switch (formatString.match(placeholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, '.', ',');
      break;
  }

  return formatString.replace(placeholderRegex, value);
}

function watchDom() {

  let callbacks = {};
  let observeDom = false;

  let throttleFn = (cb) => {
    let limit = 100;
    let lastFunc;
    let lastRan;
    return function () {
      try {
        let context = this;
        let args = arguments;
        if (!lastRan) {
          cb.apply(context, args);
          lastRan = Date.now();
        } else {
          clearTimeout(lastFunc);
          lastFunc = setTimeout(function () {
            try {
              if ((Date.now() - lastRan) >= limit) {
                cb.apply(context, args);
                lastRan = Date.now();
              }
            } catch (error) {
              logger.debug('mutation observer: ', error);
            }
          }, limit - (Date.now() - lastRan));
        }
      } catch (ex) {
        logger.debug('mutation observer: ', ex);
      }
    };
  };

  let setObserver = () => {
    observeDom = new MutationObserver(throttleFn((mutations) => {
      Object.keys(callbacks).forEach((id) => {
        let state = callbacks[id];
        if (state.observe) {
          state.callback(mutations);
        }
      });
    }));
    return observeDom;
  };

  let start = () => {
    if (observeDom) observeDom.observe(document.querySelector('body'), { childList: true, subtree: true });
  };

  let stop = () => {
    if (observeDom) observeDom.disconnect();
  };

  let setCallbackObserve = (id, observe) => {
    if (id && typeof callbacks[id] === 'object' && typeof observe === 'boolean') {
      callbacks[id].observe = observe;
    }
  };

  let removeCallback = (id) => {
    if (id) {
      delete callbacks[id];
    }
    if (id === null) {
      for (let key in callbacks) {
        delete callbacks[key];
      }
    }
  };

  let setCallback = (id, cb) => {
    if (id && typeof cb === 'function') {
      if (!observeDom) {
        setObserver();
        start();
      }
      callbacks[id] = { observe: true, callback: cb };
    }
  };

  return {
    start,
    stop,
    setCallbackObserve,
    setCallback,
    removeCallback,
  };

}

let domObserver = new watchDom();
export function getDomObserver() {
  return domObserver;
}

function eventListener() {

  let callbacks = {};

  let on = (id, event, cb) => {
    if (id && event && typeof cb === 'function') {
      if (callbacks[id + event] instanceof Array === false) {
        callbacks[id + event] = [];
      }
      callbacks[id + event].push(cb);
    }
  };

  let trigger = (id, event) => {
    if (id && event && callbacks[id + event] instanceof Array) {
      callbacks[id + event].forEach(function (cb) {
        cb();
      });
      delete callbacks[id + event];
    }
  };

  return {
    on,
    trigger
  };
}

let listener = new eventListener();
export function getEventListener() {
  return listener;
}


export function getTypeOf(x) {
  try {
    let t = typeof x;
    if (t !== 'object') {
      return t;
    } else if (x === undefined) {
      return 'undefined';
    } else if (x === null) {
      return 'null';
    } else if (x instanceof Array) {
      return 'array';
    } else if (x instanceof Element) {
      return 'element';
    } else if (x instanceof DocumentFragment) {
      return 'documentFragment';
    } else if (x instanceof createElement) {
      return 'makeElement';
    } else if (x.constructor === createElement) {
      return 'makeElement';
    } else {
      return t;
    }
  } catch (err) {
    logger.debug('unknown type of element');
    logger.debug(err);
  }
}

export function createElement(args) {

  if (args === undefined) {
    return false;
  }

  let tag = (args.tag === undefined) ? 'div' : args.tag;
  let attrs = (args.attrs === undefined) ? {} : args.attrs;
  let appendElems = (args.append === undefined) ? [] : args.append;
  let selector = (args.selector === undefined || args.selector === null) ? null : args.selector;
  let elem = document.createElement(tag);

  elem.innerHTML = '';

  let exists = () => {
    if (selector !== null) {
      let matches = document.querySelectorAll(selector);
      if (matches.length === 1) {
        return true;
      }
    }
    return false;
  };

  let getElement = () => {
    if (selector !== null) {
      let matches = document.querySelectorAll(selector);
      if (matches.length === 1) {
        return matches[0];
      }
    }
    return elem;
  };

  let getSelector = () => {
    return selector;
  };

  let setSelector = (newSelector) => {
    return selector = newSelector;
  };

  let setAttribute = (key, value) => {

    let valueType = getTypeOf(value);

    if (valueType === 'array') {
      value = value.map((v, i) => {
        if (typeof v === 'string') return (i === 0) ? v : ' ' + v;
      }).join('');
      getElement().setAttribute(key, value);
    } else if (valueType === 'string') {
      getElement().setAttribute(key, value);
    } else if (valueType === 'number') {
      getElement().setAttribute(key, value);
    }

  };

  let setAttributes = (attrs) => {
    Object.keys(attrs).forEach(function (key) {
      var value = attrs[key];
      setAttribute(key, value);
    });
  };

  if (args.className !== undefined) setAttribute('class', args.className);
  if (args.id !== undefined) setAttribute('id', args.id);
  setAttributes(attrs);

  let appendEntity = (entity) => {
    let type = getTypeOf(entity);
    let curElem = getElement();
    if (type === 'string') {
      curElem.innerHTML += entity;
    } else if (type === 'element' || type === 'documentFragment') {
      curElem.append(entity);
    } else if (type === 'makeElement') {
      curElem.append(entity.getElement());
    }
  };

  let append = (args) => {
    if (getTypeOf(args) === 'array') {
      args.forEach(function (child) {
        appendEntity(child);
      });
    } else {
      appendEntity(args);
    }
  };

  let appendTo = (target) => {
    const targetNode = document.querySelector(target);
    if (targetNode !== null) {
      targetNode.append(getElement());
    }
  };

  let insert = (target, insertBefore, kind) => {
    let element = getElement();
    insertNode(element, target, insertBefore, kind);
  };

  let addClass = (klass) => {
    getElement().classList.add(klass);
  };

  let setStyle = (cssProp, cssValue) => {
    getElement().style[cssProp] = cssValue;
  };

  let onClick = (cb) => {
    getElement().onclick = () => { cb() };
  };

  let remove = () => {
    getElement().remove();
  };

  if (args.append !== undefined) {
    append(appendElems);
  }

  let props = {
    exists: exists,
    getElement: getElement,
    getSelector: getSelector,
    setSelector: setSelector,
    setAttribute: setAttribute,
    setAttributes: setAttributes,
    append: append,
    appendTo: appendTo,
    insert: insert,
    addClass: addClass,
    setStyle: setStyle,
    onClick: onClick,
    remove: remove,
  };
  props.constructor = createElement;
  return props;

}

export function replace(str, key, val) {
  if (str && str.indexOf(key) > -1 && typeof val === 'string') {
    let match = new RegExp(key, "g");
    str = str.replace(match, val);
  }
  return str;
}

export function replaceAll(s, search, replace) {
  if (typeof s === 'string' && typeof search === 'string' && typeof replace === 'string') {
    return s.split(search).join(replace);
  }
}

export function arrayFrom(iterator) {
  let arr = [];
  if (iterator) {
    for (let item of iterator) {
      arr.push(item);
    }
  }
  return arr;
}

export function arrayToMap(arr, field) {
  let map = {};
  if (arr && arr.length) {
    arr.forEach(a => map[a[field]] = a);
  }
  return map;
}

/**
 * detects whether an element is currently visible within the browsers viewport window
 * note: the element must be completely within the viewport
 * @param el - dom element
 * @returns {boolean}
 */
export function isVisibleInWindow(el) {
  if (!el) {
    return false;
  }
  if (window.getComputedStyle(el).visibility !== 'visible') {
    return false;
  }
  const boundingRect = el.getBoundingClientRect();
  if (boundingRect.top >= 0 && boundingRect.left >= 0 && boundingRect.bottom <= document.documentElement.clientHeight &&
    boundingRect.right <= document.documentElement.clientWidth && boundingRect.height > 0 && boundingRect.width > 0) {
    return true;
  }

  return false;
}

/**
 * detects whether an element was visible enough to make an impression on the user
 * currently this is just based upon the viewport going past the top of the element at least 100 pixels
 * or the total height of the element
 * @param el - dom element
 * @returns {boolean}
 */
export function isMakingImpression(el) {
  const MIN_IMPRESSION_PIXELS = 100;

  // element is at least minPixels showing from the bottom of the screen (user scrolled down into the element, probably the standard case)
  function isInViewFromTop(boundingRect, minPixels) {
    return (boundingRect.top >= 0 && boundingRect.top <= document.documentElement.clientHeight)
      && ((document.documentElement.clientHeight - boundingRect.top) >= minPixels || boundingRect.bottom <= document.documentElement.clientHeight);
  }

  // element is at least minPixels showing from the top of the screen (user scrolled up into element)
  function isInViewFromBottom(boundingRect, minPixels) {
    return (boundingRect.bottom >= 0 && boundingRect.bottom <= document.documentElement.clientHeight) && (boundingRect.bottom >= minPixels || boundingRect.top >= 0);
  }

  // element is taking up the whole screen top to bottom (user is already in the middle of the element)
  function isTakingEntireView(boundingRect) {
    return boundingRect.top < 0 && boundingRect.bottom > document.documentElement.clientHeight;
  }

  if (!el) {
    return false;
  }

  if (window.getComputedStyle(el).visibility !== 'visible') {
    return false;
  }

  // alternative of jQuery :hidden, see: https://stackoverflow.com/questions/9637943/non-jquery-equivalent-of-visible-in-javascript
  // note we don't worry about additional checks since we don't deliver to ie
  if (el.offsetHeight === 0 && el.offsetWidth === 0) {
    return false;
  }

  const boundingRect = el.getBoundingClientRect();

  if (isInViewFromTop(boundingRect, MIN_IMPRESSION_PIXELS) || isInViewFromBottom(boundingRect, MIN_IMPRESSION_PIXELS) || isTakingEntireView(boundingRect)) {
    return true;
  }

  return false;
}

export function addSizeToUrl(url, size, coefficient=1) {
  const formats = ['.jpg', '.jpeg', '.png', '.tif', '.tiff', '.bmp', '.gif', '.eps', '.webp'];
  let lastIndex = -1;
  let format;
  for (let currentFormat of formats) {
    let index = url.lastIndexOf(currentFormat);
    if (index > lastIndex) {
      lastIndex = index;
      format = currentFormat;
    }
  }

  if (format) {
    let imgUrl = url.substring(0, lastIndex) + `_${size*coefficient}x${format}` + url.substring(lastIndex + format.length);
    return imgUrl;
  }

  // return incoming if we don't find an extension
  return url;
}

export function htmlToElement(html) {
  let tmp = document.createElement('template');
  html = html.trim();
  tmp.innerHTML = html;
  return tmp.content;
}

export function getHandle(pdpUrl) {
  if (pdpUrl) {
    let parts = pdpUrl.split('/');
    return parts[parts.length - 1];
  }
}

export function hasOnlyDefaultVariant(itemVariants) {
  if (itemVariants.length > 1) {
    return false;
  }
  const selectedOptions = itemVariants[0].selectedOptions;
  if (selectedOptions.length > 1) {
    return false;
  }
  const selectedOption = selectedOptions[0];
  if (selectedOption.name !== 'Title' || selectedOption.value !== 'Default Title') {
    return false;
  }
  return true;
}

// shuffle algorithm based upon the fisher-yates shuffle
// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
export function shuffle(arr) {
  if (!arr || !arr.length) return arr;
  let arrCopy = arr.slice();
  for (let i = arrCopy.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let t = arrCopy[i];
    arrCopy[i] = arrCopy[j];
    arrCopy[j] = t;
  }

  return arrCopy;
}

// see: https://shopify.dev/themes/architecture/sections/integrate-sections-with-the-theme-editor#detecting-the-theme-editor
export function inShopifyEditor() {
  if (window && window.Shopify && window.Shopify.designMode) {
    return true;
  }

  return false;
}

export function debounce(fn, delay) {
  delay = typeof delay === 'number' ? delay : 100;
  let timerId;
  return function () {
    let context = this;
    let args = arguments;
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(function () {
      fn.apply(context, args);
      timerId = null;
    }, delay);
  };
}