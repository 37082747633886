import {
  applyCss, insertNode
} from './utils';
import { $getOrderedTargetNode } from './widgets/widget';

const OBV_LIB_PATH = 'https://cdn.obviyo.net/lib/hyper/';
const OBV_LIB_URL = `${OBV_LIB_PATH}obv-components.pre.js`;
const $fetchObvApi = Function('return import("' + OBV_LIB_URL + '");');
let __obvApi;

async function $getObvApi() {
  if (!__obvApi) {
    __obvApi = await $fetchObvApi();
    window.obviyo.hcx.loadCss(`${OBV_LIB_PATH}obv-components.css`);
  }
  return __obvApi;
}

export async function $renderHyper(rec, itemData, targetNode) {
  await $getObvApi();

  const { hson, render } = window.obviyo;

  let div = document.createElement('div');
  div.id = `hc-psz-${rec.external}`;

  applyCss(rec.css, 'obv__custom_css');
  targetNode = await $getOrderedTargetNode(rec, targetNode);

  insertNode(div, targetNode, rec.insertBefore);

  render(hson(rec.tpl), div);
}