import constants from '../constants';
import { getMyShopifyDomain } from '../data';
import { $waitFor, arrayFrom, getIdFromGid, getRootSelector } from '../utils';
import { $applyCustomFn } from '../customJs';

function getProductHandle(href) {
  let parts = href.split('/');
  return parts[parts.length - 1];
}

function hasScriptText(text) {
  let scriptFound = false;
  for (let script of document.querySelectorAll('script')) {
    if (script.text.indexOf(text) > -1) {
      scriptFound = true;
      break;
    }
  }
  return scriptFound;
}

async function $getProductHandleForRatingProvider(href) {
  return await $applyCustomFn({
    $fn: getProductHandle,
    fnParams: [href],
    refName: 'getProductHandleForRatingProvider',
    additionalCtx: {
      href
    }
  });
}

let ratingProviders = [
  {
    id: 'yotpo',
    ratedListeners: [],
    oldYotpoTrackEvent: null,
    // test fn to determine whether to use this provider
    isAvailable: () => {
      if (window.yotpo || window.Yotpo) {
        return true;
      }
      return hasScriptText('staticw2.yotpo.com');
    },
    $getRatingHtml: (product) => {
      return `<div class="hc-psz-item-ratings hc-psz-product-ratings yotpo bottomLine" data-product-id="${getIdFromGid(product.id)}"></div>`;
    },
    addOnRatedListener: function (fn) {
      let oldYotpoTrackEvent = this.oldYotpoTrackEvent;
      if (!oldYotpoTrackEvent) {
        if (window.Yotpo && window.Yotpo.currentAnalytics && window.Yotpo.currentAnalytics.trackEvent) {
          this.oldYotpoTrackEvent = oldYotpoTrackEvent = window.Yotpo.currentAnalytics.trackEvent;
          window.Yotpo.currentAnalytics.trackEvent = function () {
            if (arguments && arguments.length > 2 && arguments[0] === 'reviews' && arguments[1] === 'shown' && arguments[2] === 'review_posted') {
              for (const listener of this.ratedListeners) {
                listener();
              }
            }
            oldYotpoTrackEvent.apply(this, arguments);
          };
        }
      }
      this.ratedListeners.push(fn);
    },
    $activateRatings: async (rec) => {
      let items = document.querySelectorAll(`${getRootSelector(rec)} .hc-psz-item`);
      if (window.yotpo) {
        let postData = {
          app_key: window.yotpo.appKey,
          widget_version: window.yotpo.userSettings && window.yotpo.userSettings.version,
          methods: []
        };
        let itemIds = new Set();
        for (let item of items) {
          let pid = item.dataset && item.dataset.pid;
          if (pid) {
            itemIds.add(pid);
          }
        }
        for (let id of itemIds) {
          postData.methods.push({
            method: 'bottomline',
            params: {
              pid: id,
              index: id
            }
          });
        }
        const response = await fetch('https://staticw2.yotpo.com/batch', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(postData)
        });
        const json = await response.json();
        if (json && json.length) {
          for (let item of items) {
            const existingNode = item.querySelector('.yotpo.bottomLine');
            const pid = item.dataset && item.dataset.pid;
            if (existingNode && pid) {
              let responseData = json.find(data => {
                return data.index === pid;
              });
              if (responseData && responseData.result) {
                let newNodeText = responseData.result;
                // check for actual review data, if we have none then inject empty review stars
                if (newNodeText.indexOf('yotpo-stars') > -1) {
                  existingNode.innerHTML = newNodeText;
                } else {
                  existingNode.innerHTML = 
                  `<div class="yotpo-display-wrapper" style="visibility:hidden;">
                    <div class="standalone-bottomline">
                      <div class="yotpo-bottomline pull-left  star-clickable">  
                        <span class="yotpo-stars">
                          <span class="yotpo-icon yotpo-icon-empty-star pull-left"></span>
                          <span class="yotpo-icon yotpo-icon-empty-star pull-left"></span>
                          <span class="yotpo-icon yotpo-icon-empty-star pull-left"></span>
                          <span class="yotpo-icon yotpo-icon-empty-star pull-left"></span>
                          <span class="yotpo-icon yotpo-icon-empty-star pull-left"></span> 
                        </span>      
                        <div class="yotpo-clr"></div> 
                      </div>
                    </div>
                  </div>`;
                }
              }
            }
          }
        }
      }
    }
  },
  {
    id: 'judgeme',
    isAvailable: () => window.judgeme,
    $getRatingHtml: (product) => {
      return `<div class="hc-psz-item-ratings hc-psz-product-ratings jdgm-widget jdgm-preview-badge" data-id="${getIdFromGid(product.id)}"></div>`;
    },
    addOnRatedListener: (fn) => {

    },
    $activateRatings: async (rec) => {
      let items = document.querySelectorAll(`${getRootSelector(rec)} .hc-psz-item`);
      if (rec.judgemeToken) {
        let judgemeUrl = new URL(`https://cache.judge.me/widgets/shopify/${getMyShopifyDomain()}`);
        judgemeUrl.searchParams.set('public_token', rec.judgemeToken);

        let itemIds = new Set();
        for (let item of items) {
          let pid = item.dataset && item.dataset.pid;
          if (pid) {
            itemIds.add(pid);
          }
        }
        judgemeUrl.searchParams.set('preview_badge_product_ids', arrayFrom(itemIds.values()));
        const response = await fetch(judgemeUrl);
        const json = await response.json();
        if (json && json.preview_badges) {
          for (let item of items) {
            const existingNode = item.querySelector('.jdgm-preview-badge');
            const pid = item.dataset && item.dataset.pid;
            if (existingNode && pid) {
              const newNodeText = json.preview_badges[pid];
              if (newNodeText) {
                const placeholder = document.createElement('div');
                placeholder.innerHTML = newNodeText;
                let newNode = placeholder.firstElementChild;
                newNode.classList.add('jdgm-preview-badge', 'hc-psz-item-ratings', 'hc-psz-product-ratings');
                const parent = existingNode.parentNode;
                if (parent) {
                  parent.replaceChild(newNode, existingNode);
                }
              }
            }
          }
        }
      } else if (window.judgeme && window.judgeme.batchRenderBadges) {
        let badges = [];
        for (let item of items) {
          badges.push({
            badgePlaceholder: item.querySelector('.jdgm-preview-badge'),
            productHandle: await $getProductHandleForRatingProvider(item.href)
          });
        }
        window.judgeme.batchRenderBadges(badges);
      }
    }
  },
  {
    // for okendo ratings to work, the okendo.summaryData metafield must be added to the recommendation items
    // see $decorateWithMetafields
    // simple way to do this is to add it to rec.metafields in config function
    id: 'okendo',
    isAvailable: () => {
      if (window.okendoReviews) {
        return true;
      }
      return hasScriptText('/reviewsWidget.min.js');
    },
    $getRatingHtml: async (product) => {

      let summaryData = product['meta.okendo.summaryData'];
      if (!summaryData) return '';
      try {
        summaryData = Function(`return ${summaryData}`)();
      } catch(ex) {
        return '';
      }

      if (!summaryData || summaryData.reviewCount === 0) return '';

      return `<div class="okeReviews okeReviews--theme">
      <div class="okeReviews-reviewsSummary js-okeReviews-reviewsSummary">
       <div class="okeReviews-reviewsSummary-starRating">
       <span class="okeReviews-starRating okeReviews-starRating--small">
      <span class="okeReviews-a11yText">Rated ${summaryData.reviewAverageValue} out of 5</span>
       <span class="okeReviews-starRating-indicator" role="presentation">
       <span class="okeReviews-starRating-indicator-layer"></span>
       <span class="okeReviews-starRating-indicator-layer okeReviews-starRating-indicator-layer--foreground" style="width: ${summaryData.reviewAverageValue*20}%"></span>
       </span>
       </span>
       </div>
       <div class="okeReviews-reviewsSummary-ratingCount">
       <span aria-hidden="true">${summaryData.reviewCount} Reviews</span>
       <span class=okeReviews-a11yText>Based on ${summaryData.reviewCount} reviews</span>
       </div>
      </div>
      </div>`;
    },
    addOnRatedListener: (fn) => {
    },
    $activateRatings: async (rec) => {
    }
  },
  {
    id: 'stampedio',
    isAvailable: () => {
      if (window.StampedFn) {
        return true;
      }

      return hasScriptText('.stamped.io');
    },
    $getRatingHtml: (product) => {
      return `<div class="hc-psz-item-ratings hc-psz-product-ratings stamped-product-reviews-badge stamped-main-badge" data-id="${getIdFromGid(product.id)}"></div>`;
    },
    addOnRatedListener: (fn) => {

    },
    $activateRatings: async (rec) => {
      await $waitFor(() => window.StampedFn && window.StampedFn.loadBadges);
      window.StampedFn && window.StampedFn && window.StampedFn.loadBadges();    
    }
  },
  {
    id: 'growave',
    autoDetect: false,
    isAvailable: () => window.Ssw,
    $getRatingHtml: async (product) => {
      return `<div class="hc-psz-item-ratings hc-psz-product-ratings ssw-product-reviews-badge" data-url="/products/${await $getProductHandleForRatingProvider(product.onlineStoreUrl)}"></div>`;
    },
    addOnRatedListener: (fn) => {

    },
    $activateRatings: async (rec) => {
      await $waitFor(() => window.Ssw && window.Ssw.loadBadges);
      window.Ssw && window.Ssw.loadBadges && window.Ssw.loadBadges();
      
    }
  },
  {
    id: 'bazaarvoice',
    isAvailable: () => {
      let script = document.querySelector('script[src*="apps.bazaarvoice.com/deployments"][src*="bv.js"]');
      if (script) {
        return true;
      }
      return false;
    },
    $getRatingHtml: (product) => {
      return `<div class="hc-psz-item-ratings hc-psz-product-ratings" data-bv-show="inline_rating" data-bv-product-id="${getIdFromGid(product.id)}"></div>`;
    },
    addOnRatedListener: (fn) => {

    },
    $activateRatings: async (rec) => {
      
    }
  },
  {
    id: 'shopify',
    activationCall: null,
    isAvailable: () => {
      if (typeof window.SPR === 'function') {
        return true;
      }

      // extra check for spr script since it seems that the window object often comes in after we do
      let scriptFound = false;
      for (let script of document.querySelectorAll('script')) {
        if (script.text.indexOf('/spr.js') > -1) {
          scriptFound = true;
          break;
        }
      }
      return scriptFound;
    },
    $getRatingHtml: (product) => {
      return `<div class="hc-psz-item-ratings hc-psz-product-ratings hc-psz-shopify-ratings">
          <span class="shopify-product-reviews-badge" data-id="${getIdFromGid(product.id)}"></span>
        </div>`;
    },
    addOnRatedListener: (fn) => {

    },
    // note function uses this so it can't be an arrow function
    $activateRatings: async function (rec) {
      // for now only be concerned with the case where we are adding recs after initial setup has been done
      if (window.SPR && window.SPR.$ && !this.activating) {
        // need to make sure that we do some level of debouncing so we don't hammer the same refresh a bunch of times
        this.activating = true;
        setTimeout(() => {
          window.SPR.initDomEls();
          window.SPR.loadProducts();
          window.SPR.loadBadges();
          this.activating = false;
        }, 500);
      }
    }
  },
  {
    id: 'default',
    isAvailable: () => true,
    $getRatingHtml: (product) => {
      return '';
    },
    addOnRatedListener: (fn) => {

    },
    $activateRatings: (rec) => {

    }
  }
];

function getFirstAvailableProvider(rec) {
  let availableProvider;
  for (let provider of ratingProviders) {
    if (rec && rec.ratingProvider) {
      // TODO: should we also still check to make sure the provider is actually available when forced?
      if (rec.ratingProvider === provider.id) {
        availableProvider = provider;
        break;
      }
    } else if (provider.autoDetect !== false && provider.isAvailable()) {
      availableProvider = provider;
      break;
    }
  }
  return availableProvider;
}

function handleRatingAction(fnName, rec) {
  let provider = getFirstAvailableProvider(rec);
  if (provider) {
    return provider[fnName].apply(provider, Array.prototype.slice.call(arguments, [2]));
  }
}

export async function $getItemRatingsHtml(rec, product) {
  return handleRatingAction('$getRatingHtml', rec, product);
}

export function addOnRatedListener(rec, fn) {
  return handleRatingAction('addOnRatedListener', rec, fn);
}

export async function $activateRatings(rec) {
  return handleRatingAction('$activateRatings', rec, rec);
}