import { getPszIds, $getPszClassNames, getDevice, getValue, createElement, hasOnlyDefaultVariant } from '../utils';
import Logger from '../logger';
import { $getTitleNode, renderElemShowNav, $renderItems, setMoreItemsLogic, $insertPsz } from './widget';

const logger = new Logger();
const DEFAULT_ROWS = { desktop: 1, mobile: 4 };
const DEFAULT_COLS = { desktop: 4, mobile: 1 };

export async function $renderList(rec, itemData, targetNode) {

  let device = getDevice(rec);
  let items = itemData.items;
  let ids = getPszIds(rec);
  let classNames = await $getPszClassNames(rec, ['hc-psz-list']);
  let rid = getValue(itemData.rid, null);
  let theme = getValue(rec.themeColor, 'light');
  let cols = getValue(rec.listCols, DEFAULT_COLS[device]);
  let rows = getValue(rec.listRows, DEFAULT_ROWS[device]);
  let showTotal = cols * rows;
  let show = { count: showTotal, step: showTotal };
  let appendNav = (showTotal < items.length && rec.navShow === 'more') ? true : false;

  let params = {
    pszItemsHtml: {
      rec: rec,
      widget: 'list',
      // psz-item-list left for now for legacy support
      className: 'hc-psz-list-item hc-psz-item-list',
      show: show.count,
    },
    pszItems: {
      id: ids.pszItems,
      className: 'hc-psz-items hc-psz-list-items',
      attrs: {
        'data-hc-psz-cols': cols,
        'data-hc-psz-rows': rows,
      },
    },
    psz: {
      id: ids.psz,
      className: classNames,
      attrs: {
        'data-rid': rid,
        'data-hc-psz-theme-color': theme
      },
    }
  };

  let elem = {
    pszTitle: await $getTitleNode(rec, itemData),
    pszItemsHtml: await $renderItems(items, params.pszItemsHtml),
    pszItems: createElement(params.pszItems),
    pszShowNav: renderElemShowNav(),
    psz: createElement(params.psz),
  };

  elem.pszItems.append(elem.pszItemsHtml);
  elem.psz.append([elem.pszTitle, elem.pszItems]);
  if (appendNav) elem.psz.append(elem.pszShowNav);

  await $insertPsz(rec, targetNode, elem);
  if (appendNav) setMoreItemsLogic(params.psz.id, show);

}

// default list item for checkout/confirmation/minicart
export async function $listItemResolver(ctx) {
  let item = ctx.item;
  let field = ctx.field;
  let rec = ctx.rec;

  if (field === 'item') {
    return `
        <div style="display:flex;width:100%;align-items:center;margin-top:1em">
        {itemImage}
        {itemAttrsWrap}
        ${rec.showProductAddToCart ? '{itemAddToCartButton}' : ''}
        </div>
        ${rec.showProductAddToCart ? '{itemAddToCartSelect}': ''}
      `;
  } else if (field === 'itemAttrsWrap') {
    return `
        ${rec.showProductAddToCart ? '<div class="product__description" style="flex: 1 1 0%;">': ''}
          {itemTitle}
          ${!rec.showProductAddToCart ? '{itemPrices}': ''}
        ${rec.showProductAddToCart ? '</div>': ''}
      `;
  } else if (field === 'itemTitle') {
    return `
        <div class="product__description" style="padding-left:1em; ${!rec.showProductAddToCart ? 'flex: 1 1 0%;': ''}">
          <span class="product__description__name order-summary__emphasis">
          {itemTitleText}
          </span>
          ${rec.showProductAddToCart ? '<span class="order-summary__small-text">{itemPrices}</span>': ''}
        </div>
      `;
  } else if (field === 'itemPrice') {
    return `
        <div class="product__price" ${!rec.showProductAddToCart ? '"style="padding-left:1em;"': ''}>
          <span class="order-summary__emphasis">{itemPriceText}</span>
        </div>
      `;
  } else if (field === 'itemImage') {
    return `<div class="product-thumbnail">
        <div class="product-thumbnail__wrapper">
          <img src="${item.featuredImage.originalSrc}" class="product-thumbnail__image">
        </div>
      </div>`;
  } else if (field === 'itemAddToCartButton') {
    return `
    <button type="button" class="hc-psz-list-item-add-to-cart-btn btn">{itemAddToCartButtonText}</button>
    `;
  } else if (field === 'itemAddToCartSelect') {
    let hidden = false;
    if (rec.displayVariantOptions === 'never' || rec.displayVariantOptions === 'dynamic' || hasOnlyDefaultVariant(item.variants)) {
      hidden = true;
    }
    
    return `<div class="field__input-wrapper field__input-wrapper--select" style="width:100%; margin-top:1em;">
    <label class="field__label"></label>
      <select class="hc-psz-list-item-add-to-cart-select field__input field__input--select ${hidden ? 'hidden': ''}"> {itemAddToCartSelectOptions}
      </select>
      
    </div>
    `;
    // The following code could be added after the select above to add a caret to select similar to shopify's default
    // selects. The only challenge is figuring out how to hide and show it dynamically along with other code
    // holding off on this one now unless it is really seen as a must have
    // <div class="field__caret">
    //     <svg class="icon-svg icon-svg--color-adaptive-lighter icon-svg--size-10 field__caret-svg" role="presentation" aria-hidden="true" focusable="false"> <use xlink:href="#caret-down"></use> </svg>
    //   </div>
  }

  if (ctx.global && ctx.global.resolver) {
    return ctx.global.resolver(ctx);
  }

  if (ctx.$default) {
    return ctx.$default();
  }
}
