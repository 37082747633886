import constants from './constants';
import { $isNewVisitor, $getCountry, $getDMA, $getFormFactor, $getTrafficSource, $getRegion, $getSiteId, $getSegmentData, $getVisitorIds, getCurrentProductCategoryIds, getCurrentProductId, getMyShopifyDomain, getCurrentCategoryId } from './data';
import { $getMostRecentCartItems, $getMostRecentCategories, $getMostRecentPurchasedItems, $getMostRecentViewedItems } from './history';
import { isEmptyObject } from './utils';
import { getPreviewInfo } from './preview';

const preview = getPreviewInfo();
const needs_preview_data = preview && preview.isPreviewMode && !preview.isPreviewLiveMode;

export const DEFAULT_RECOMMENDATION_CASE = 'default';

// limit of how many items we want to allow the lambda function to have to deal with
const MAX_INPUT_SIZE = 5;
const MAX_ITEMS_SIZE = 25;

export function getNeedsPreviewData() {
  return needs_preview_data;
}

export async function $getCommonParams(recommendation) {

  const recParams = {};

  const siteId = await $getSiteId();
  const productId = getCurrentProductId();
  const visitorIds = await $getVisitorIds();

  recParams.site_id = siteId;
  if (recommendation.engine) {
    recParams.engine = recommendation.engine;
  }
  if (recommendation.recipe) {
    recParams.recipe = recommendation.recipe;
  }
  if (recommendation.scenario) {
    recParams.scenario = recommendation.scenario;
  }
  if (recommendation.qualifiedName) {
    recParams.qualified_name = recommendation.qualifiedName;
  }
  if (recommendation.numResults) {
    recParams.num_results = recommendation.numResults;
  }
  if (visitorIds.hic) {
    recParams.visitor_id = visitorIds.hic;
  }
  if (visitorIds.platform) {
    recParams.ecommerce_id = visitorIds.platform;
  }
  if (productId) {
    recParams.product_id = productId;
  }

  if (recommendation.catIds) {
    // in this first case ids are set by ux and are objects with a title and id property
    if (recommendation.catIds[0] && recommendation.catIds[0].id) {
      let catIds = [];
      for (let cat of recommendation.catIds) {
        catIds.push(cat.id);
      }
      recParams.cat_ids = JSON.stringify(catIds);
    } else {
      recParams.cat_ids = JSON.stringify(recommendation.catIds);
    }
  } else {
    let catId = getCurrentCategoryId();
    if (catId) {
      recParams.cat_ids = JSON.stringify([catId]);
    }
  }

  // manually specified category
  if (recommendation && recommendation.category && recommendation.category.id) {
    recParams.cat_ids = JSON.stringify([recommendation.category.id]);
  }

  if (recommendation.tags) {
    recParams.tags = JSON.stringify(recommendation.tags);
  }

  if (recommendation.items) {
    recParams.items = JSON.stringify(recommendation.items);
  }

  if (recommendation.match) {
    if (typeof recommendation.match === 'string') {
      recommendation.match = [recommendation.match];
    }
    recParams.match = JSON.stringify(recommendation.match);
  }

  if (recommendation.window) {
    recParams.window = recommendation.window;
  }

  if (recommendation.rating) {
    recParams.rating = recommendation.rating;
  }

  if (recommendation.brand) {
    recParams.brand = recommendation.brand;
  }

  if (recommendation.maxi) {
    recParams.maxi = recommendation.maxi;
  }

  if (recommendation.mini) {
    recParams.mini = recommendation.mini;
  }

  if (recommendation.maxp) {
    recParams.maxp = recommendation.maxp;
  }

  if (recommendation.minp) {
    recParams.minp = recommendation.minp;
  }

  if (recommendation.maxd) {
    recParams.maxd = recommendation.maxd;
  }

  if (recommendation.mind) {
    recParams.mind = recommendation.mind;
  }

  const myShopifyDomain = getMyShopifyDomain();
  if (myShopifyDomain) {
    recParams.myshopifydomain = myShopifyDomain;
  }
  if (preview.isPreviewMode) {
    recParams.preview = true;
  }
  if (preview.isPreviewLiveMode) {
    recParams.live_mode = true;
  }

  const segs = await $getSegmentData();

  if (!isEmptyObject(segs)) {
    recParams.segs = JSON.stringify(segs);
  }

  return recParams;
}

async function defaultHandler(recommendation) {
  let recParams = await $getCommonParams(recommendation);

  return recParams;
}

// see psz-ryou
async function simplePersonalizedRecHandler(recommendation) {
  let recParams = await $getCommonParams(recommendation);

  let pdpIds = await $getMostRecentViewedItems(5);

  if (pdpIds && pdpIds.length) {
    recParams.pdp_ids = JSON.stringify(pdpIds);
  }

  let catIds = await $getMostRecentCategories(5);

  if (catIds && catIds.length) {
    recParams.cat_ids2 = JSON.stringify(catIds);
  }

  let cartIds = await $getMostRecentCartItems(5);
  if (cartIds && cartIds.length) {
    recParams.cart_ids = JSON.stringify(cartIds);
  }

  return recParams;
}

function falseIfMissingItems(recParams) {
  return (needs_preview_data || (recParams && recParams.items)) ? recParams : false;
}

function falseIfMissingParam(paramName, recParams) {
  return (needs_preview_data || (recParams && recParams[paramName])) ? recParams : false;
}

export const getItemDataMap = {
  // template based on cart items
  // need to pass the cart items up
  // here we do a hack where we pass up the currently viewed product if on a product page
  // to kind of fake that being added to cart as well since we are running on page load atm
  'psz-rtci': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    let ids = await $getMostRecentCartItems(5);
    // add current product at head of list if its not already in the list somewhere
    if (ids && recParams.product_id && ids.indexOf(recParams.product_id) < 0) {
      ids.unshift(recParams.product_id);
      if (ids.length > MAX_INPUT_SIZE) {
        ids = ids.slice(0, MAX_INPUT_SIZE);
      }
    }

    if (ids && ids.length) {
      recParams.items = JSON.stringify(ids);
    }

    return falseIfMissingItems(recParams);
  },

  'psz-obtp': async recommendation => {
    let recParams = await $getCommonParams(recommendation);
    // for minicart and cart we'll use last added item to cart if any
    if (recommendation.place === constants.MINICART_PLACE || recommendation.pageClass === 'cart') {
      let recentIds = await $getMostRecentCartItems(1);
      if (recentIds && recentIds.length) {
        recParams.product_id = recentIds[0];
      }
    }

    return falseIfMissingParam('product_id', recParams);
  },

  'psz-hist': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    let ids = await $getMostRecentViewedItems();

    if (ids && ids.length > MAX_ITEMS_SIZE) {
      ids = ids.slice(0, MAX_ITEMS_SIZE);
    }

    if (ids && ids.length) {
      recParams.items = JSON.stringify(ids);
    }

    return falseIfMissingItems(recParams);
  },

  'psz-byia': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    let ids = await $getMostRecentPurchasedItems();

    if (ids && ids.length > MAX_ITEMS_SIZE) {
      ids = ids.slice(0, MAX_ITEMS_SIZE);
    }

    if (ids && ids.length) {
      recParams.items = JSON.stringify(ids);
    }

    return falseIfMissingItems(recParams);
  },

  'psz-mite': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    if (!recParams.cat_ids) {
      let ids = getCurrentProductCategoryIds();

      if (ids && ids.length > MAX_INPUT_SIZE) {
        ids = ids.slice(0, MAX_INPUT_SIZE);
      }

      if (ids && ids.length) {
        recParams.cat_ids = JSON.stringify(ids);
      }
    }

    return falseIfMissingParam('cat_ids', recParams);
  },

  'psz-ripc': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    let ids = await $getMostRecentPurchasedItems(5);

    if (ids && ids.length) {
      recParams.items = JSON.stringify(ids);
    }

    return falseIfMissingItems(recParams);
  },

  'psz-riyv': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    let ids = await $getMostRecentViewedItems(5);

    if (ids && ids.length) {
      recParams.items = JSON.stringify(ids);
    }

    delete recParams.cat_ids;
    return falseIfMissingItems(recParams);
  },

  'psz-iprv': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    let ids = await $getMostRecentCategories(5);

    if (ids && ids.length) {
      recParams.cat_ids = JSON.stringify(ids);
    }

    return falseIfMissingParam('cat_ids', recParams);
  },

  'psz-sima': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    delete recParams.cat_ids;
    return recParams;
  },

  'psz-prti': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    delete recParams.cat_ids;
    return recParams;
  },

  'psz-shbb': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    if (!recParams.match) {
      recParams.match = JSON.stringify(['brand']);
    }

    return recParams;
  },

  'psz-shbt': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    if (!recParams.match) {
      recParams.match = JSON.stringify(['type']);
    }

    return recParams;
  },

  'psz-4pls': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    if (!recParams.rating) {
      recParams.rating = 4;
    }

    return recParams;
  },

  'psz-ryou': simplePersonalizedRecHandler,
  'psz-ryic': simplePersonalizedRecHandler,
  'psz-ymhd': simplePersonalizedRecHandler,
  'psz-ym4s': simplePersonalizedRecHandler,
  'psz-hdfy': simplePersonalizedRecHandler,

  'psz-ctgr': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    let cartIds = await $getMostRecentCartItems(5);
    if (cartIds && cartIds.length) {
      recParams.cart_ids = JSON.stringify(cartIds);
    }

    return falseIfMissingParam('cart_ids', recParams);
  },

  'psz-pgeo': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    // segs object for pgeo is specific to simple rec so we need to
    // compute it differently here
    let segs = {};

    const country = await $getCountry();
    if (country) {
      segs.co = country;
    }

    const region = await $getRegion();
    if (region) {
      segs.rg = region;
    }

    const dma = await $getDMA();
    if (dma) {
      segs.dma = dma;
    }

    if (!isEmptyObject(segs)) {
      recParams.segs = JSON.stringify(segs);
    }

    return recParams;
  },

  'psz-useg': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    // segs object for useg is specific to simple rec so we need to
    // compute it differently here
    let segs = {};

    const ff = await $getFormFactor();
    if (ff) {
      segs.ff = ff;
    }

    const as = await $getTrafficSource();
    if (as) {
      segs.as = as;
    }

    const isNew = await $isNewVisitor();
    if (typeof isNew === 'boolean') {
      segs.new = isNew;
    }

    const country = await $getCountry();
    if (country) {
      segs.co = country;
    }

    const region = await $getRegion();
    if (region) {
      segs.rg = region;
    }

    if (!isEmptyObject(segs)) {
      recParams.segs = JSON.stringify(segs);
    }

    return recParams;
  },

  'psz-ipdx': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    let catIds = await $getMostRecentCategories(5);

    if (catIds && catIds.length) {
      recParams.cat_ids2 = JSON.stringify(catIds);
    }

    return falseIfMissingParam('cat_ids2', recParams);
  },

  // note: it is not necessary to write out the mappings here but we do so to be explicit about supported qnames.
  'psz-pddd': defaultHandler,
  'psz-nadx': defaultHandler,
  'psz-scdd': defaultHandler,
  'psz-iprc': defaultHandler,
  'psz-dctg': defaultHandler,
  'psz-dcdx': defaultHandler,
  'psz-bsel': defaultHandler,
  'psz-mpux': defaultHandler,
  'psz-trdx': defaultHandler,
  'psz-trnd': defaultHandler,
  'psz-trdc': defaultHandler,
  'psz-trdd': defaultHandler,
  'psz-rfyu': defaultHandler,
  'psz-mpop': defaultHandler,
  'psz-psel': defaultHandler,
  'psz-ulke': defaultHandler,
  'psz-rbyc': defaultHandler,
  'psz-rbyo': defaultHandler,
  'psz-rfic': defaultHandler,
  'psz-silp': defaultHandler,
  'psz-sihp': defaultHandler,
  'psz-4pdx': defaultHandler,
  'psz-pcat': defaultHandler,
  'psz-crpv': defaultHandler,
  'psz-uusw': defaultHandler,
  'psz-uupg': defaultHandler,
  'psz-obtp-p': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    recParams.qualified_name = 'psz-obtp';

    return recParams;
  },
  'psz-obtp-c': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    recParams.qualified_name = 'psz-obtp';

    let cartIds = await $getMostRecentCartItems(5);
    if (cartIds && cartIds.length) {
      recParams.items = JSON.stringify(cartIds);
    }

    return falseIfMissingItems(recParams);
  },
  'psz-mcat': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    recParams.qualified_name = 'psz-pcat';

    return recParams;
  },
  'psz-rhst': async recommendation => {
    let recParams = await $getCommonParams(recommendation);

    let ids = await $getMostRecentViewedItems();

    if (ids && ids.length > MAX_ITEMS_SIZE) {
      ids = ids.slice(0, MAX_ITEMS_SIZE);
    }

    if (ids && ids.length) {
      recParams.items = JSON.stringify(ids);
    }

    recParams.qualified_name = 'psz-hist';

    return falseIfMissingItems(recParams);
  },

  default: defaultHandler

};